import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import Select from 'react-select';
import HealthcareChatbotSectionTitle from './HealthcareChatbotSectionTitle';

const HealthcareChatbotFormSection = () => {

  const [loading, setLoading] = useState(false);
  const [allChat, setAllChat] = useState([
    {
      sender: "Healthcare Chatbot",
      message: "Hi, how may I help you?",
      timeStamp: new Date(),
    }
  ]);
  const [formInput, setFormInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let temp = [...allChat];
    temp.push({
      sender: "User",
      message: formInput,
      timeStamp: new Date(),
    });
    setAllChat([...temp]);
    const fetchHeaders = new Headers();
    fetchHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      "question": formInput
    });
    const requestOptions = {
      method: "POST",
      headers: fetchHeaders,
      body: raw,
      redirect: "follow"
    }
    fetch("https://llm-server2-f9330aa423a1.herokuapp.com/answer/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        let temp2 = [...temp];
        temp2.push({
          sender: "Healthcare Chatbot",
          message: result.answer.result,
          timeStamp: new Date(),
        });
        setLoading(false);
        setAllChat([...temp2]);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
      
    setFormInput("");
  }
  
  const handleQueryInputChange = (e) => {
    setFormInput(e.target.value);    
  }

  useEffect(() => {
    // console.log(allChat);
    const out = document.getElementById("ChatboxContainer");
    const isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
    if (!isScrolledToBottom) {
      out.scrollTop = out.scrollHeight - out.clientHeight;
    }
  }, [allChat])

  return (
    <>
      <Element
        name="aiform" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <HealthcareChatbotSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Healthcare Chatbot"
              />
            </div>            
            <div className="col-12 mt--80">
              {/* <div className="chatbox-container">
                <div className="bot_wrapper">
                  <p className="name-of-sender mb-0 text-end">Healthcare Chatbot</p>
                  <p className="bot">Please select an option: <br></br><span onClick={handleSubmitOption1}>Red</span><span onClick={handleSubmitOption2}>Green</span><span onClick={handleSubmitOption3}>Blue</span></p>
                </div>
                <div className="input_wrapper">
                  {selectedColor && <><p className="name-of-sender mb-0 text-start">You</p><p className="input">You selected the color <span>{selectedColor}</span>.</p></>}
                </div>
                <div className="bot_wrapper">
                  {selectedColor && <><p className="name-of-sender mb-0 text-end">Healtcare Chatbot</p><p className="bot">Wonderful choice! I love {selectedColor} color.</p></>}
                </div>
              </div> */}
              <div className="chatbox-container" id="ChatboxContainer">
                {allChat.map((chat, idx) => chat.sender === "Healthcare Chatbot" ? <div className="bot_wrapper" key={idx}>
                  <p className="name-of-sender mb-0 text-end">Healthcare Chatbot</p>
                  <p className="bot">{chat.message}</p>
                </div> : <div className="input_wrapper" key={idx}>
                  <p className="name-of-sender mb-0 text-start">You</p>
                  <p className="input">{chat.message}</p>
                </div>
                )}
                {loading && <div className="mt-4 m-auto"><img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="illustration" /></div>}
              </div>
              <div className="col-12">
                <form onSubmit={handleSubmit} className="chatbox-input">
                  <div className="input-container d-flex flex-row">
                    <input type="text" value={formInput} placeholder="Type your query here..." onChange={handleQueryInputChange} required />
                    <button className="edu-btn btn-small lh-1" type="submit" disabled={loading}>&#8594;</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default HealthcareChatbotFormSection;