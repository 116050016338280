import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "HealthGuide+",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Knowledge Mining",
    "location": "HealthGuidePlus_URL",
    "going": "140+"
  }
]

const HealthGuidePlus = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="HealthGuide+"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="HealthGuide+"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    HealthGuide+ is an innovative knowledge mining platform designed to provide personalized health recommendations for individuals visiting or relocating to a new city. Leveraging advanced Data Analytics and Natural Language Processing (NLP) techniques, HealthGuide+ aggregates and analyzes relevant health-related information from diverse data sources, including government health agencies, local healthcare providers.
                  </p>
                  <h4>Key Features</h4>
                  <ul>
                    <li>
                      <strong>Comprehensive City Health Profiles</strong>: HealthGuide+ offers comprehensive profiles of cities, providing detailed insights into local health conditions, environmental factors, disease prevalence rates, and healthcare infrastructure. Users can access up-to-date information on air quality, water safety, infectious disease outbreaks, vaccination requirements, and emergency medical services available in their chosen city.
                    </li>
                    <li>
                      <strong>Personalized Health Recommendations</strong>: By analyzing user preferences, medical history, and demographic data, HealthGuide+ generates personalized health advisories tailored to individual needs and preferences. Users can specify their age, gender, pre-existing medical conditions, and lifestyle preferences to receive targeted recommendations on preventive healthcare measures, dietary guidelines, exercise routines, and wellness activities.
                    </li>
                    <li>
                      <strong>Real-Time Health Alerts</strong>: HealthGuide+ delivers real-time health alerts and notifications to users, informing them of potential health risks, disease outbreaks, or environmental hazards in their current location. Users receive proactive recommendations on protective measures, such as wearing masks during high pollution days, avoiding crowded areas during flu season, or seeking medical attention in case of emergency situations.
                    </li>
                    <li>
                      <strong>Community Health Forums</strong>: HealthGuide+ fosters community engagement and knowledge sharing through online health forums and social media integration. Users can connect with local residents, healthcare professionals, and fellow newcomers to exchange health tips, share experiences, and seek advice on navigating the local healthcare system.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default HealthGuidePlus;