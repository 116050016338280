import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import EventsDetails from '../../components/events/EventsDetails';

const Events = () => {

  return ( 
    <>
      <SEO
        title="Events"
        description="We organise regular events for you to share information about how you can benefit from us!"
      />
      <Layout>
        {/* <Eventsbanner /> */}
        <BreadcrumbOne
          title="Events"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Events"
        />
        <EventsDetails />
      </Layout>
    </>
  )
}

export default Events;