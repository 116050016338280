import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ReturnToWorkPredictionFormSection from '../../components/return-to-work-prediction/ReturnToWorkPredictionFormSection';

const ReturnToWorkPrediction = () => {
	return (
		<>
			<SEO
				title="Return To Work Prediction"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Return To Work Prediction"
          rootUrl="/return-to-work-prediction"
          parentUrl="Home"
          currentUrl="Return To Work Prediction"
				/>
				<ReturnToWorkPredictionFormSection />
			</Layout>
		</>
    )
}
export default ReturnToWorkPrediction;