import React from 'react';
import { Element } from 'react-scroll';
import EventData from '../../EventsData.json';

const EventsDetails = () => {
  let newEvents = EventData.filter((el) => new Date(el.eventEndDate ? el.eventEndDate : el.eventDate).getTime() >= new Date().getTime()).sort(function(a,b){
    return new Date(b.eventDate) - new Date(a.eventDate);
  });
  let oldEvents = EventData.filter((el) => new Date(el.eventEndDate ? el.eventEndDate : el.eventDate).getTime() < new Date().getTime()).sort(function(a,b){
    return new Date(b.eventDate) - new Date(a.eventDate);
  });

  return (
    <>
      <Element name="eventdetails" className="edu-about-area about-style-1 edu-section-gap bg-color-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3>Current and Upcoming Events</h3>
            </div>
            <div className="col-lg-12 row g-5 mt-0">
              {newEvents.map((el) =>
                <div className="col-12 col-md-6 col-lg-6" key={el.id}>
                  <div className="event-card program-service-card service-card-1 radius-small">
                    <div className="inner">
                      <div className="thumbnail">
                        <a href={ el.buttonLink }>
                          <img src={`${process.env.PUBLIC_URL + el.eventImage }`} alt="Category Thumb" loading="lazy" />
                        </a>
                        <div className="event-card-info d-none d-lg-block pb-0">
                          <h5>{ el.eventName }</h5>
                          {/* <p className="event-card-description">{ el.eventDescription }</p> */}
                          <p className="event-card-details">{ el.eventDetails }</p>
                        </div>
                      </div>
                      <div className="content program-info p-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="course-total m-0"><i className="icon-calendar-2-line"></i> { el.eventDate }</span>
                          {el.eventCost && <span className="course-total m-0 d-none d-lg-inline-block">Cost: <strong>{el.eventCost === "Free" ? "" : "$"}</strong>{ el.eventCost }</span>}
                          <a className="edu-btn btn-small btn-secondary" href={ el.buttonLink }>Learn More<i className="icon-arrow-right-line-right d-none d-lg-inline"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12 mt--80">
              <h3>Past Events</h3>
            </div>
            <div className="col-lg-12 row g-5 mt-0">
              {oldEvents.map((el) =>
                <div className="col-12 col-md-6 col-lg-6" key={el.id}>
                  <div className="event-card program-service-card service-card-1 radius-small">
                    <div className="inner">
                      <div className="thumbnail">
                        <a href={ el.buttonLink }>
                          <img src={`${process.env.PUBLIC_URL + el.eventImage }`} alt="Category Thumb" loading="lazy" />
                        </a>
                        <div className="event-card-info d-none d-lg-block pb-0">
                          <h5>{ el.eventName }</h5>
                          {/* <p className="event-card-description">{ el.eventDescription }</p> */}
                          <p className="event-card-details">{ el.eventDetails }</p>
                        </div>
                      </div>
                      <div className="content program-info p-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="course-total m-0"><i className="icon-calendar-2-line"></i> { el.eventDate }</span>
                          {el.eventCost && <span className="course-total m-0 d-none d-lg-inline-block">Cost: { el.eventCost }</span>}
                          <a className="edu-btn btn-small btn-secondary" href={ el.buttonLink }>Learn More<i className="icon-arrow-right-line-right d-none d-lg-inline"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ Element>
    </>
  )
}

export default EventsDetails;