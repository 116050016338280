import React from 'react';
import { scrollToViewFromBottom } from '../../utils/scrollToViewFromBottom';

const AMFooter = () => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 datatalent-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 position-relative am-footer">
            <div className="col-lg-7 py-md-4 my-md-5">
              <p className="am-footer-heading mb-0">Ready to take the next step in your career and become a Data Science Expert?</p>
            </div>
            <div className="col-lg-2 d-none d-md-block"></div>
            <div className="col-lg-3 my-5 pb-4 m-md-0 p-md-0 d-flex justify-content-center align-items-center">
              <p className="am-cta cursor-pointer" onClick={() => scrollToViewFromBottom("MembershipPackages")}>Enroll Now</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AMFooter;