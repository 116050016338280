import React from 'react';

const BAPONavbar = ({
  isSticky,
  handleTalentPipelineClick,
  handleMentorshipCoachingClick,
  activeBAPOTab
}) => {

  return (
    <>
      {isSticky && <div className={`dpo-nav d-flex flex-row gap-3${isSticky ? " dpo-nav-sticky" : ""}`} id="BAPOMobileNavbar">
        <div>
          <button className={`dpo-nav-item${activeBAPOTab === "TalentPipeline" ? " active-program-overview" : ""}`} id="TalentPipelineMobileNavbar" onClick={handleTalentPipelineClick}>
            Talent Pipeline
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeBAPOTab === "MentorshipCoaching" ? " active-program-overview" : ""}`} id="MentorshipCoachingMobileNavbar" onClick={handleMentorshipCoachingClick}>
            Mentorshiip & Coaching
          </button>
        </div>
      </div>}
    </>
  )
}

export default BAPONavbar;