import React from 'react';
import SEO from '../../common/SEO';
// import RegisterProgramForm from '../../components/form/register-program-form/RegisterProgramForm';

import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import DiscoverAIBanner from '../../components/discover-ai/DiscoverAIBanner';
import DiscoverAIDescription from '../../components/discover-ai/DiscoverAIDescription';
// import DiscoverAIFAQs from '../../components/discover-ai/DiscoverAIFAQs';
import DiscoverAIWhyChooseUs from '../../components/discover-ai/DiscoverAIWhyChooseUs';
import DiscoverAILoremIpsum from '../../components/discover-ai/DiscoverAILoremIpsum';
// import DiscoverAISignUpForm from '../../components/form/discover-ai-form/DiscoverAISignUpForm';

const DiscoverAI = ({
	showDiscoverAISignUpForm,
	setShowDiscoverAISignUpForm
	}) => {
	return (
		<>
			<SEO
				title="DiscoverAI"
				description="Unlock 80 hours, fully subsidized, Digital Tech internships for Canadian employers, to build DiscoverAI pipeline using our Upskilled Talents."
			/>
			<HeaderTwo styles="header-transparent header-style-2" searchDisable />
			<DiscoverAIBanner setShowDiscoverAISignUpForm={setShowDiscoverAISignUpForm} />
			<DiscoverAIDescription />
			<DiscoverAILoremIpsum />
			<DiscoverAIWhyChooseUs setShowDiscoverAISignUpForm={setShowDiscoverAISignUpForm} />
			{/* <DiscoverAIFAQs /> */}
			<FooterOne />
			{/* {showDiscoverAISignUpForm && <DiscoverAISignUpForm setShowDiscoverAISignUpForm={setShowDiscoverAISignUpForm} />} */}
		</>
    )
}
export default DiscoverAI;