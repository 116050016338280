import React from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from './DataTalentSectionTitle';
// import { Link } from 'react-router-dom';

const DataTalentContribute = ({
  setShowEmployerRegisterForm,
  setShowUserInterestForm,
  setShowDownloadCurriculumForm,
}) => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="about-style-1 edu-section-gap service-wrapper-1 home-one-cat"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Contribute to DataTalent pool creation"
              />
            </div>
          </div>
          <div className="row g-5 align-items-center mt-60">
            <div className="col-lg-5">
              <div className="about-image-gallery">
                <img className="image-1" src={`${process.env.PUBLIC_URL}/images/data-talent/data-talent-description.jpg`} alt="decoration" loading="lazy" />
              </div>
            </div>
            <div className="col-lg-7 about-feature-list">
              <div className="inner">
                <h5 className="text-left mb-0 mt-4">Data Challenges and Hackathons</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Host your own Data challenges and hackathons and create awareness about your Company and the work you are doing. These events gives our participants an opportunity to work on time-bound projects or problem-solving competitions, where they will collaborate in teams to analyze and interpret data.</p>
                  </div>
                </div>                
                <h5 className="text-left mb-0 mt-4">Industry Partnerships, Internships, Placements</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Partner with us to provide Upskilling participants with guest lectures, workshops, industry projects including Employer Led Work Integrated Learning and internship opportunities. These collaborations enhance job placement prospects and offer valuable mentorship for participants.</p>
                  </div>
                </div>
                <h5 className="text-left mb-0 mt-4">Employer Engagement Events</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Join us in Networking events and job fairs will be organized, enabling Digital Tech employers like you to interact with participants. These events serve as platforms for participants to demonstrate their skills, learn about job opportunities, establish Industry connections.</p>
                  </div>
                </div>
                <h5 className="text-left mb-0 mt-4">Employer Feedback</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Provide us feedback regarding our program effectiveness, participant preparedness. This feedback will drive continuous improvement and ensure program relevance.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="data-talent-riipen mt--60">
                <h4 className="text-center">Don't Miss Out on Exceptional DataTalent!</h4>
                <p>
                  Get in touch with us today to explore how our exceptional Data Science talent can make a difference in your projects. We're here to help you achieve your Data-driven goals efficiently and cost-effectively.
                </p>
                <div className="data-talent-cta mt--40">
                  <a className="edu-btn" href="https://form.jotform.com/m2mtech/employer-register">Employer Login</a>
                  <a className="edu-btn btn-secondary" href="https://form.jotform.com/m2mtech/cohort-sign-up">Jobseekers Login</a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentContribute;