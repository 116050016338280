import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CareerLaunchDescription from '../../components/our-programs/career-launch/CareerLaunchDescription';
import ProgramsRecommendedCourses from '../../components/programs-section/ProgramsRecommendedCourses';
import ProgramsAchievements from '../../components/programs-section/ProgramsAchievements';
import IndustryPartnerWIL from '../../components/programs-section/IndustryPartnerWIL';

const CareerLaunchPremium = () => {
  return (
    <>
      <SEO
        title="CareerLaunch"
        description="We help jobseekers get internship for their career launch."
      />
      <Layout>
        <BreadcrumbOne
          title="CareerLaunch"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="CareerLaunch"
        />
        <CareerLaunchDescription />
        <ProgramsRecommendedCourses />            
        <IndustryPartnerWIL />
        <ProgramsAchievements />
      </Layout>
    </>
  )
}

export default CareerLaunchPremium;