import React from 'react';
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import BASectionTitle from './BASectionTitle';

const items = [
  {
    title: 'Company Name 1',
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, rem fugiat sapiente dolor aspernatur cupiditate tempora dolores mollitia harum voluptatibus iusto eveniet error explicabo repudiandae maxime ab deserunt accusantium debitis?",
    // icon: 'icon-Hand---Book'
  },
  {
    title: 'Company Name 2',
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae minima suscipit esse molestias, earum eos! Fuga quia laborum eligendi eius. Provident rerum sunt nam cum delectus rem sapiente dignissimos nostrum.",
    // icon: 'icon-Hand---Book'
  }
];

const BASuccessStory = () => {
  return (
    <>
      <Element
        name="paths-you-can-follow"
        className="edu-about-area about-style-1 edu-section-gap bg-color-white"
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="about-image-gallery me-0">
                <img className="image-1" src={`${process.env.PUBLIC_URL}/images/business-accelerator/section_2.png`} alt="illustration" loading="lazy" />
                <div className="shape-image shape-image-1">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-1 order-lg-2">
              <div className="inner mt-md-16">
                <BASectionTitle
                  classes="text-center"
                  // slogan = "Success Stories"
                  title = "Success Stories"
                />
                <ScrollAnimation 
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={ true }
                >
                </ScrollAnimation>
                { items && items.length > 0 &&
                  <div className="about-feature-list mt-4">
                    { items.map( ( data , i ) => (
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        className="our-feature"
                        animateOnce={ true }
                        key={i}
                      >
                        <div className="our-feature">
                          <div className="feature-content">
                            <h6 className="feature-title">{ data.title }</h6>
                            <blockquote className="blockquote-style-1">
                              “ {data.info} ”
                            </blockquote>
                          </div>
                        </div>
                      </ScrollAnimation>
                    ) ) }
                  </div>
                }
                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BASuccessStory;