import React, { useEffect, useRef, useState } from 'react'
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import AMBanner from '../../components/annual-membership/AMBanner';
import AMAbout from '../../components/annual-membership/AMAbout';
import AMProgramOverview from '../../components/annual-membership/AMProgramOverview';
import AMPONavbar from '../../components/annual-membership/AMPONavbar';
import AMTestimonialSection from '../../components/annual-membership/AMTestimonialSection';
import AMPackages from '../../components/annual-membership/AMPackages';
import AMFooter from '../../components/annual-membership/AMFooter';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';

const AnnualMembership = () => {

  const [isSticky, setIsSticky] = useState(false);
  const [showSkillsboost, setShowSkillsboost] = useState(true);
  const [showAIReady, setShowAIReady] = useState(false);
  const [showCareerLaunch, setShowCareerLaunch] = useState(false);
  const [outerAMEntry, setOuterAMEntry] = useState(false);
  const [innerAMEntry, setInnerAMEntry] = useState(false);
  const [activeAMPOTab, setActiveAMPOTab] = useState("Skillsboost");

  const targetAMSectionRef = useRef(null);
  const targetAMSectionInnerRef = useRef(null);
  
  const handleSkillsboostClick = () => {
    document.getElementById("Skillsboost").classList.add("active-program-overview");
    document.getElementById("AIReady").classList.remove("active-program-overview");
    document.getElementById("CareerLaunch").classList.remove("active-program-overview");
    document.getElementById("Skillsboost")?.classList.add("active-program-overview");
    document.getElementById("AIReady")?.classList.remove("active-program-overview");
    document.getElementById("CareerLaunch")?.classList.remove("active-program-overview");
    setShowSkillsboost(true);
    setShowAIReady(false);
    setShowCareerLaunch(false);
    setActiveAMPOTab("Skillsboost");
  }
  
  const handleAIReadyClick = () => {
    document.getElementById("Skillsboost").classList.remove("active-program-overview");
    document.getElementById("AIReady").classList.add("active-program-overview");
    document.getElementById("CareerLaunch").classList.remove("active-program-overview");
    document.getElementById("Skillsboost")?.classList.remove("active-program-overview");
    document.getElementById("AIReady")?.classList.add("active-program-overview");
    document.getElementById("CareerLaunch")?.classList.remove("active-program-overview");
    setShowSkillsboost(false);
    setShowAIReady(true);
    setShowCareerLaunch(false);
    setActiveAMPOTab("AIReady");
  }
  
  const handleCareerLaunchClick = () => {
    document.getElementById("Skillsboost").classList.remove("active-program-overview");
    document.getElementById("AIReady").classList.remove("active-program-overview");
    document.getElementById("CareerLaunch").classList.add("active-program-overview");
    document.getElementById("Skillsboost")?.classList.remove("active-program-overview");
    document.getElementById("AIReady")?.classList.remove("active-program-overview");
    document.getElementById("CareerLaunch")?.classList.add("active-program-overview");
    setShowSkillsboost(false);
    setShowAIReady(false);
    setShowCareerLaunch(true);
    setActiveAMPOTab("CareerLaunch");
  }

  
  useEffect(() => {
    if(outerAMEntry) {
      if(!innerAMEntry) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    } else {
      setIsSticky(false);
    }
  }, [outerAMEntry, innerAMEntry])

  
  useEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if(anchor) {
      scrollIntoViewWithOffset(anchor);
    }

    let targetInnerCurrent = targetAMSectionInnerRef.current;
    let targetCurrent = targetAMSectionRef.current;
    
    const innerObserver = new IntersectionObserver(
      ([innerEntry]) => {
        if(innerEntry.isIntersecting) {
          setInnerAMEntry(true);
        } else {
          setInnerAMEntry(false);
        }
      },
      { rootMargin: "-135px 0px 0px 0px", threshold: 0.1 }
    );
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setOuterAMEntry(true);
        } else {
          setOuterAMEntry(false);
        }
      },
      { threshold: 0.1 }
    );
    
    if (targetInnerCurrent) {
      innerObserver.observe(targetInnerCurrent);
    }

    if (targetCurrent) {
      observer.observe(targetCurrent);
    }
    
    return () => {
      if (targetCurrent) {
        observer.unobserve(targetCurrent);
      }
      if (targetInnerCurrent) {
        innerObserver.unobserve(targetInnerCurrent);
      }
    };
  }, []);

  return (
    <>
      <SEO
				title="DataTalent for Jobseekers"
				description="Unlock 80 hours, fully subsidized, Digital Tech internships for Canadian employers, to build DataTalent pipeline using our Upskilled Talents."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <AMPONavbar
        isSticky={isSticky}
        handleSkillsboostClick={handleSkillsboostClick}
        handleAIReadyClick={handleAIReadyClick}
        handleCareerLaunchClick={handleCareerLaunchClick}
        activeAMPOTab={activeAMPOTab}
      />
      <AMBanner />
      <AMAbout />
      <AMProgramOverview
        targetRefAM={targetAMSectionRef}
        innerRefAM={targetAMSectionInnerRef}
        showSkillsboost={showSkillsboost}
        showAIReady={showAIReady}
        showCareerLaunch={showCareerLaunch}
        handleSkillsboostClick={handleSkillsboostClick}
        handleAIReadyClick={handleAIReadyClick}
        handleCareerLaunchClick={handleCareerLaunchClick}
      />
      <AMTestimonialSection />
      <AMPackages
        handleSkillsboostClick={handleSkillsboostClick}
        handleAIReadyClick={handleAIReadyClick}
        handleCareerLaunchClick={handleCareerLaunchClick}
      />
      <AMFooter />
      <FooterOne />
    </>
  )
}

export default AnnualMembership;