import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HealthcareChatbotFormSection from '../../components/healthcare-chatbot/HealthcareChatbotFormSection';

const HealthcareChatbot = () => {
	return (
		<>
			<SEO
				title="Healthcare Chatbot"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Healthcare Chatbot"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Healthcare Chatbot"
				/>
				<HealthcareChatbotFormSection />
			</Layout>
		</>
    )
}
export default HealthcareChatbot;