import React from 'react';
import { Element } from 'react-scroll';
import BASectionTitle from './BASectionTitle';

const BAWhy = () => {
  return (
    <>
      <Element
        name="introduction"
        className="about-style-1 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7">
              <div className="inner">
                <BASectionTitle
                  // slogan = "Eligibility"
                  title = "Why M2M Tech"
                />
                <p className="ba-why-text mt-5 mb-0">
                  With extensive experience in AI development, industry partnerships, and upskilling professionals through our DataTalent program, M2M Tech is uniquely positioned to help businesses leverage AI for growth. We offer a holistic approach that covers technology development, business strategy, and workforce integration, ensuring your business is ready to thrive in the AI-driven future.
                </p>
                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-07.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-image-gallery m-0">
                <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/section_1.png`} alt="illustration" loading="lazy" />
                <div className="shape-image shape-image-1">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAWhy;