import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';

const JobseekersAbout = () => {
  // const skills = [
  //   {
  //     icon: "pandas_icon",
  //     skillName: "Pandas"
  //   },
  //   {
  //     icon: "numpy_icon",
  //     skillName: "NumPy"
  //   },
  //   {
  //     icon: "scikitlearn_icon",
  //     skillName: "scikit-learn"
  //   },
  //   {
  //     icon: "tensorflow_icon",
  //     skillName: "TensorFlow"
  //   },
  //   {
  //     icon: "pie_chart_icon",
  //     skillName: "Data Visualization"
  //   },
  //   {
  //     icon: "nn_icon",
  //     skillName: "Neural Networks"
  //   },
  //   {
  //     icon: "ai_chip_icon",
  //     skillName: "Artificial Intelligence"
  //   },
  //   {
  //     icon: "ml_icon",
  //     skillName: "No-Code Machine Learning"
  //   },
  //   {
  //     icon: "code_icon",
  //     skillName: "Low-Code Machine Learning"
  //   }
  // ]
  const keyPoints = [
    {
      image: "flexible.svg",
      title: "Flexible, Self-Paced Learning",
      description: "Study on your terms with 24/7 access to courses and materials, allowing you to balance learning with your busy schedule."
    },
    {
      image: "hands_on.svg",
      title: "Hands-On Experience",
      description: "Work on industry-relevant projects that mirror real-world challenges. You'll develop practical skills, build a portfolio, and gain the confidence to apply your knowledge in professional settings."
    },
    {
      image: "expert.svg",
      title: "Expert Guidance",
      description: "Benefit from personalized mentorship with one-on-one sessions tailored to your goals. Our expert mentors provide career advice, feedback on projects, and help you navigate your journey with confidence."
    }
  ]

  const coreValues = [
    {
      image: "engage.svg",
      title: "Engage",
      description: "Connect with industry experts through hands-on projects that expand your network and boost your career."
    },
    {
      image: "educate.svg",
      title: "Educate",
      description: "Master in-demand skills like AI & ML through our interactive courses, tackling real-world challenges that set you apart."
    },
    {
      image: "empower.svg",
      title: "Empower",
      description: "Lead projects, collaborate with peers, and work alongside professionals with the autonomy we provide."
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7 mt-0 order-1 order-xl-2 p-0">
              <AMSectionTitle
                classes = "text-left"
                slogan = "Introduction"
                title = "Unlock Your Full Potential in Data Science, ML, and AI"
              />
              <h5 className="mb-0 mt-4">
                At M2M Tech, we believe in providing more than just courses—we offer a complete learning ecosystem that <span className="highlight-text-primary">engages, educates, and empowers</span> you to thrive in the most sought-after tech fields. Our annual programs combine:
              </h5>
              <div className="ds-image-container d-block d-md-none my-5">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/intro.png`} width="100%" alt="illustration" />
              </div>
              <div className="am-key-point-container">
                {keyPoints.map((keyPoint, idx) => (<div className="am-key-point d-flex mt-4" key={idx}>
                  <div className="am-key-point-img col-1">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${keyPoint.image}`} alt="illustration" />
                  </div>
                  <div className="am-key-point-content col-11">
                    <h5 className="mb-0 ms-4">{keyPoint.title}</h5>
                    <p className="mb-0 ms-4">{keyPoint.description}</p>
                  </div>
                </div>))}
              </div>
            </div>
            <div className="col-lg-5 order-2 order-xl-1 d-none d-md-block">
              <div className="ds-image-container">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/intro.png`} alt="illustration" />
              </div>
            </div>
            <div className="col-12 container order-3 p-0">
              <div className="row am-core-values-container py-5 px-4">
                <h5 className="col-12 text-center">Achieve More with the Support of Our Core Values</h5>
                {coreValues.map((coreValue, idx) => (<div className="col-lg-4" key={idx}>
                  <div className="core-value-container d-flex flex-row align-items-center mt-4">
                    <div className="core-value-img col-4 col-lg-5">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/${coreValue.image}`} alt="illustration" />
                    </div>
                    <div className="core-value-content col-8 col-lg-7">
                      <h5 className="mb-0 ms-3">{coreValue.title}</h5>
                      <p className="mb-0 ms-3">{coreValue.description}</p>
                    </div>
                  </div>
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default JobseekersAbout;