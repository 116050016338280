import React from 'react';
import OurProgramsSectionTitle from './OurProgramsSectionTitle';
import { Element } from 'react-scroll';

const OurProgramsDescription = () => {
	return (
		<>
			<Element name="description" className="courses-description-content edu-section-gap style-variation3 blog-main-content">
				<div className="container eduvibe-animated-shape">
					<div className="justify-content-center row--40 row g-5">
						<div className="col-lg-12">
							<OurProgramsSectionTitle 
								classes = "text-center"
								title = "Overview"
							/>
						</div>
						<div className="col-lg-12">
							<blockquote className="blockquote-style-1">“ We are excited to offer a range of subscription packages designed to support your learning and career growth in the tech industry. Our packages provide access to a wealth of resources, from foundational courses to real-world projects and personalized mentorship. Choose the package that best suits your goals and take your career to the next level! ”
								<img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
							</blockquote>
						</div>
						<div className="col-lg-12">
							<OurProgramsSectionTitle 
								classes = "text-center"
								title = "Welcome to Our New Subscription-Based Learning Platform!"
							/>
						</div>
						<div className="col-lg-12">
							<p className="mt-4">We're thrilled to share that M2M Tech is moving to a subscription-based system that is designed specifically for tech enthusiasts eager to advance in Data Science, Machine Learning, and Artificial Intelligence. Our mission is to empower students and professionals alike by providing comprehensive learning experiences that are both accessible and transformative. Through our comprehensive, self-paced courses, work-integrated learning projects, and personalized mentorship, we aim to equip you with the skills and connections needed to thrive in the ever-evolving tech landscape. Whether you're just starting out or looking to deepen your expertise, our subscription packages are crafted to support your growth and help you achieve career aspirations.</p>
						</div>
					</div>
				</div>
			</Element>
		</>
	)
}

export default OurProgramsDescription;