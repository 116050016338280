import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "Data Analytics Skills Assignments",
    "image": "Data_Analytics_skills.webp",
    "website": "http://www.dominarlo.ca/",
    "industry": "Education",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Data Science, ML & AI",
    "location": "http://www.dominarlo.ca/",
    "going": "140+",
    "company": "Dominarlo"
  }
]

const DataAnalyticsSkillsAssignments = ({
  showRegisterForm,
  setShowRegisterForm,
  company
  }) => {
  const eventItem = EventData[0];
  return (
    <>
      <SEO
      title={ eventItem.title }
      description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="Data Analytics Skills Assignments"
          rootUrl="/programs/work-integrated-learning"
          parentUrl="Industry Partner Work Integrated Learning"
          currentUrl="Data Analytics Skills Assignments"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{ eventItem.title }</h3>
                  <p>
                    <strong>What is the main goal for this project?</strong><br></br>
                    We are launching an exciting initiative aimed at developing the next generation of data analytics talent in higher education. As part of this project, you will explore and identify the essential data analytics skills needed by students today. Your findings will guide the creation of specialized datasets and queries using our innovative Online Data Query Tool. This tool not only generates synthetic data but also crafts queries that challenge and enhance analytical skills.
                  </p>
                  <p>
                    <strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
                    <ul>
                      <li>
                        Conduct a research to identify the data analytics skills needed for undergraduate students.
                      </li>
                      <li>
                        Learn how to use our innovative data query tool.
                      </li>
                      <li>
                        Generating datasets and creating queries to support the development of those skills in undergraduate students.
                      </li>
                      <li>
                        Create a set of assignments and added it to the assignment bank.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>How will you support learners in completing the project?</strong><br></br>
                    We will be meeting with students on a weekly basis to provide input and support. We have a team of developers who could support the students' request.
                  </p>
                  <p>
                    <strong>What skills or technologies will help learners to complete the project?</strong><br></br>
                    Students should be excellent in:
                    <ul>
                      <li>
                        Data analytics skills
                      </li>
                      <li>
                        Data sets creation
                      </li>
                      <li>
                        Research
                      </li>
                      <li>
                        Ability to create queries
                      </li>                      
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT INDUSTRY PARTNER</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
                        <li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        {/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
                        <li>
                          Dominarlo specialize in developing educational tools that are affordable and innovative.
                        </li>
                      </ul>
                      {!company && <div className="read-more-btn mt--45">
                        <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                      </div>}

                      {/* <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="/"><i className="icon-linkedin"></i></a>
                          <a className="facebook" href="/"><i className="icon-Fb"></i></a>
                          <a className="twitter" href="/"><i className="icon-Twitter"></i></a>
                          <a className="youtube" href="/"><i className="icon-youtube"></i></a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {!company && <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Layout>
      {showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
    </>
  )
}
export default DataAnalyticsSkillsAssignments;