import React from 'react';

const DPONavbar = ({
  isSticky,
  handleTimelineClick,
  handleBenefitsClick,
  handleCurriculumClick,
  activeDPOTab
}) => {

  return (
    <>
      {isSticky && <div className={`dpo-nav d-flex flex-row gap-3${isSticky ? " dpo-nav-sticky" : ""}`} id="DPOMobileNavbar">
        <div>
          <button className={`dpo-nav-item${activeDPOTab === "Timeline" ? " active-program-overview" : ""}`} id="TimelineMobileNavbar" onClick={handleTimelineClick}>
            Timeline
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeDPOTab === "Benefits" ? " active-program-overview" : ""}`} id="BenefitsMobileNavbar" onClick={handleBenefitsClick}>
            Benefits
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeDPOTab === "Curriculum" ? " active-program-overview" : ""}`} id="CurriculumMobileNavbar" onClick={handleCurriculumClick}>
            Curriculum
          </button>
        </div>
      </div>}
    </>
  )
}

export default DPONavbar;