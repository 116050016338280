import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HealthCareInsightsFormSection from '../../components/healthcare-insights-ui/HealthCareInsightsFormSection';

const HealthCareInsights = () => {
	return (
		<>
			<SEO
				title="Health Care Insights"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Health Care Insights"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Health Care Insights"
				/>
				<HealthCareInsightsFormSection />
			</Layout>
		</>
    )
}
export default HealthCareInsights;