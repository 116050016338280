import React from 'react';
import { Link } from 'react-router-dom';
import CareerLaunchSectionTitle from './CareerLaunchSectionTitle';

const CareerLaunchDescription = () => {
  return (
    <>
      <div className="courses-description-content style-variation3 blog-main-content edu-section-gap row g-5">
        {/* <div className="col-lg-12 mt-0">
          <CareerLaunchSectionTitle
            classes = "text-center"
            title = "Overview"
          />
        </div> */}
          
        <blockquote className="blockquote-style-1">“ Our program offers guaranteed internship placement, personalized career coaching, and exclusive networking opportunities to help you gain real-world experience and advance your career. With lifetime access to updates and ongoing support, you'll have the tools and connections needed for long-term success. ”
          <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
        </blockquote>
    
        <div className="col-lg-12 mt-60">
          <CareerLaunchSectionTitle 
            classes = "text-center"
            title = "Unlock Your Career Potential with Our Guaranteed Internship Program"
          />
        </div>
        <div className="our-feature">
          <div className="icon">
            <i className="icon-checkbox-circle-fill-solid"></i>
          </div>
          <div className="feature-content">
            <h6 className="feature-title"><em>Guaranteed Internship Placement</em></h6>
            <p className="feature-description">Gain real-world experience with our guaranteed internship placement program. We provide placement in a relevant internship to help you build hands-on experience and a strong professional network. Throughout your internship, you'll receive continuous support, ensuring a valuable and productive learning experience.</p>
            <ul className="mb-0">
              <li><strong>Guaranteed placement in a relevant internship</strong> to develop critical skills and apply your knowledge in a real-world setting.</li>
              <li><strong>Ongoing support</strong> from our team to help you navigate challenges, maximize your learning, and build your confidence.</li>
            </ul>
          </div>
        </div>
        <div className="our-feature">
          <div className="icon">
            <i className="icon-checkbox-circle-fill-solid"></i>
          </div>
          <div className="feature-content">
            <h6 className="feature-title"><em>Personalized Career Coaching</em></h6>
            <p className="feature-description">Our dedicated career coaches are here to guide you every step of the way. Whether you're just starting out or looking to advance your career, we offer customized coaching sessions to help you achieve your goals.</p>
            <ul className="mb-0">
              <li><strong>One-on-one coaching with a dedicated career coach</strong> to help with career planning, skill assessment, and setting realistic goals.</li>
              <li><strong>Bi-weekly coaching sessions</strong> tailored to your individual needs, providing you with actionable insights and strategies for success.</li>
            </ul>
          </div>
        </div>
        <div className="our-feature">
          <div className="icon">
            <i className="icon-checkbox-circle-fill-solid"></i>
          </div>
          <div className="feature-content">
            <h6 className="feature-title"><em>Premium Networking Opportunities</em></h6>
            <p className="feature-description">Connect with the people who matter in your industry! Our program offers exclusive invitations to networking events where you can engage with professionals, industry leaders, and potential employers.</p>
            <ul className="mb-0">
              <li><strong>Access to exclusive networking events, meetups, and conferences</strong> to expand your professional circle and open doors to future opportunities.</li>
              <li><strong>Direct connections with industry leaders</strong> to help you stay ahead of the curve and make lasting impressions with potential employers.</li>
            </ul>
          </div>
        </div>
        <div className="our-feature">
          <div className="icon">
            <i className="icon-checkbox-circle-fill-solid"></i>
          </div>
          <div className="feature-content">
            <h6 className="feature-title"><em>Extended Access and Continuous Updates</em></h6>
            <p className="feature-description">We believe in your lifelong success, which is why we offer lifetime access to all course updates and materials. You'll continue to receive support, resources, and guidance long after you’ve completed the program.</p>
            <ul className="mb-0">
              <li><strong>Lifetime access to course updates</strong> so you stay informed on the latest industry trends and best practices.</li>
              <li><strong>Ongoing support even after program completion</strong>, ensuring you have the tools and resources you need for continued career growth.</li>
            </ul>
          </div>
        </div>

        <h5 className="mb-0">Take the First Step Towards Your Dream Career</h5>
        <p className="mt-3">
          With guaranteed internships, personalized coaching, premium networking, and extended access, you'll be fully equipped to thrive in today's competitive job market. Start building your future today!
        </p>

        <div className="cta-btn">
          <a className="edu-btn" href="https://courses.m2mtechconnect.com/enroll/3086016">Buy this plan<i className="icon-arrow-right-line-right"></i></a>
        </div>
        
      </div>
    </>
  )
}

export default CareerLaunchDescription;