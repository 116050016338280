import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import useForm from '../../customhooks/UseForm';
import { Element } from 'react-scroll';

const AIForm = () => {
	const [outputData, setOutputData] = useState("Output will be shown here");
	const [FORM_ENDPOINT ,setFORM_ENDPOINT] = useState();
  const { handleSubmit, status, message } = useForm();
	const [valid, setValid] = useState(false);


	
  const handleAPI = (e) => {
    setFORM_ENDPOINT(e.target.value);
  }

	useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search);
		setValid(queryParameters.get("valid"));
	}, [])

  useEffect(() => {
    if (status === "success") {
      setOutputData(message);
    }
  }, [status, message, setOutputData])
  
	useEffect(() => {
    var ifr = document.getElementById("JotFormIFrame-240376411623248");
    if (ifr) {
      var src = ifr.src;
      var iframeParams = [];
      if (window.location.href && window.location.href.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
      }
      if (src && src.indexOf("?") > -1) {
        iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
        src = src.substr(0, src.indexOf("?"))
      }
      iframeParams.push("isIframeEmbed=1");
      ifr.src = src + "?" + iframeParams.join('&');
    }
    window.handleIFrameMessage = function(e) {
      if (typeof e.data === 'object') { return; }
			let iframe;
      var args = e.data.split(":");
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
            iframe.style.minHeight = args[1] + "px";
          }
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
          var src = args[1];
          if (args.length > 3) {
              src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
				default:
					break;
      }
      var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
      if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
        var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
        iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
      }
    };
    window.isPermitted = function(originUrl, whitelisted_domains) {
      var url = document.createElement('a');
      url.href = originUrl;
      var hostname = url.hostname;
      var result = false;
      if( typeof hostname !== 'undefined' ) {
        whitelisted_domains.forEach(function(element) {
            if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                result = true;
            }
        });
        return result;
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", window.handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", window.handleIFrameMessage);
    }
	}, [])

  useEffect(() => {
    if (status === "error") {
      setOutputData("Something bad happened \n" + message);
    }
  }, [status, message, setOutputData])

	return (
		<>
			<SEO
				title="Industry Engagement"
				description=""
			/>
			<Layout>
				<BreadcrumbOne 
          title="Industry Engagement"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Industry Engagement"
        />
				<Element
					name="aiform"
					className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white"
				>
					<div className="container">
						{valid ? <div className="row">

							<div className="col-12">
								<div className="pt-0 mb-4">
									<label className="w-100">API
										<input
											type="text"
											name="api"
											placeholder="API link"
											required
											onChange={handleAPI}
										/>
									</label>
								</div>
							</div>

							<div className="col-12">
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									method="POST"
								>
									<div className="pt-0 mb-3">
										<label className="w-100">Input 1
											<textarea
												placeholder="Text input 1"
												name="service"
												rows="5"
												required
											/>
										</label>
									</div>
									<div className="pt-0 mb-3">
										<label className="w-100">Input 2
											<textarea
												placeholder="Text input 2"
												name="profile"
												rows="5"
												required
											/>
										</label>
									</div>      
									<div className="pt-0 mb-3">
										<button className="edu-btn btn-secondary" type="submit">
											Submit
										</button>
									</div>
								</form>
							</div>

							<div className="col-12">
								<div className="form-output mt--40">
									<div className="pt-0 mb-3">
										<label className="w-100">Output
											<textarea width="100%"
												name="output"
												rows="5"
												readOnly
												value={outputData}
											/>
										</label>
									</div>
								</div>
							</div>

						</div> : <div className="row">
							<div className="col-12">
								<h3>Industry Engagement</h3>
							</div>
							<div className="col-12" id="AIForm">
								<iframe
									id="JotFormIFrame-240376411623248"
									title="Industry Engagement"
									onLoad={window.parent.scrollTo(0,0)}
									allowtransparency="true"
									allowFullScreen={true}
									allow="geolocation; microphone; camera"
									src="https://form.jotform.com/240376411623248"
									frameBorder="0"
									style={{"minWidth": "100%", "maxWidth": "100%", "height": "539px", "border": "none"}}
									scrolling="no"
								>
								</iframe>
							</div>
						</div>}
					</div>
				</Element>
			</Layout>
		</>
	)
}
export default AIForm;
