import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "SDGNavigator",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Recommendation Systems",
    "location": "SDGNavigator_URL",
    "going": "140+"
  }
]

const SDGNavigator = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="SDG Navigator"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="SDG Navigator"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    SDG Navigator is a sophisticated recommendation system designed to assist businesses in identifying and prioritizing Sustainable Development Goals (SDGs) that align with their business values, operations, and objectives. By analyzing the content of companies' websites and leveraging natural language processing (NLP) techniques, SDG Navigator offers tailored recommendations on SDGs that companies can feasibly integrate into their sustainability strategies.
                  </p>
                  <h4>Key Features</h4>
                  <ul>
                    <li>
                      <strong>Website Content Analysis</strong>: SDG Navigator utilizes advanced NLP algorithms to analyze the textual content of businesses' websites, including mission statements, corporate social responsibility (CSR) reports, and sustainability initiatives.
                    </li>
                    <li>
                      <strong>SDG Relevance Assessment</strong>: Based on the analysis of website content, SDG Navigator assesses the relevance of each of the 17 SDGs to the company's core values, industry sector, and business activities.
                    </li>
                    <li>
                      <strong>Impact Assessment</strong>: Estimates the potential social, environmental, and economic impact of aligning with recommended SDGs, providing insights into the benefits and opportunities associated with each goal.
                    </li>
                    <li>
                      <strong>Actionable Insights</strong>: Provides actionable insights and guidance on how companies can incorporate recommended SDGs into their sustainability frameworks, including suggested initiatives, partnerships, and metrics for monitoring progress.
                    </li>
                    <li>
                      <strong>Benchmarking and Comparison</strong>: Enables companies to benchmark their SDG alignment against industry peers and competitors, facilitating performance comparisons and identifying areas for improvement.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default SDGNavigator;