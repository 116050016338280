
import React from "react";

const BAVerticalTabList = (props) => {
  const Clicked = () => {
    props.onClick(props.index);
  };

  return (
    <li key={props.index} style={{ listStyle: "none", textAlign: "left" }}>
      <button
        className={`ba-vertical-list-btn edu-btn w-100${props.activeTabId === props.index ? "" : " btn-bg-alt"}`}
        onClick={Clicked}
      >
        {props.data.title}
      </button>
    </li>
  );
}

export default BAVerticalTabList;