import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import ReturnToWorkPredictionSectionTitle from './ReturnToWorkPredictionSectionTitle';
import ReturnToWorkPredictionForm from '../form/return-to-work-prediction-form/ReturnToWorkPredictionForm';
import ReturnToWorkPredictionDownloadButton from './ReturnToWorkPredictionDownloadButton';
import Select from 'react-select';
import Pie from '../../utils/pie';

const clients = [
  {
    clientId: 1,
    clientUUID: "100001",
    clientName: "John",
    clientAge: "31",
    clientNationality: "Japanese",
    clientYearsInCanada: 1,
    // clientStream: "Stream A",
  },
  {
    clientId: 2,
    clientUUID: "100002",
    clientName: "Frank",
    clientAge: "32",
    clientNationality: "Brazillian",
    clientYearsInCanada: 2,
    // clientStream: "Stream B",
  },
  {
    cliendId: 3,
    clientUUID: "100003",
    clientName: "Tony",
    clientAge: "33",
    clientNationality: "Australian",
    clientYearsInCanada: 3,
    // clientStream: "Stream C",
  },
  {
    cliendId: 4,
    clientUUID: "100004",
    clientName: "Stuart",
    clientAge: "34",
    clientNationality: "Nigerian",
    clientYearsInCanada: 4,
    // clientStream: "Stream A",
  },
  {
    clientId: 5,
    clientUUID: "100005",
    clientName: "Frank",
    clientAge: "35",
    clientNationality: "Iranian",
    clientYearsInCanada: 1,
    // clientStream: "Stream A",
  },
]

const clients2 = [
  {
    Client_ID: 100001,
    BirthDate: "1998-01-23",
    Age: 26,
    City: "Toronto",
    Province: "ON",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 26,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Married",
    Number_of_Dependents: 4,
    Gender_Identity: "Man",
    LGBTQ: "no",
    Francophone: "yes",
    Indigenous: "No",
    Race: "South Asian",
    Education_Level: "Grade 9",
    Education_Completion_Year: "2007",
    Education_Location: "Outside of Canada",
    Language_Skills_Help: 1,
    Writing_Skills: 0,
    Verbal_Skills: 0,
    Numerical_Skills: 1,
    Computer_Skills: 0,
    Support_Connections: 1,
    Internet_Access: 1,
    Transportation_Access: 1,
    Caregiver: 0,
    Housing_Situation: "Living with family/friend",
    Housing_help: 0,
    Food_Insecurity: 0,
    Immediate_Assistance_Need: 0,
    Safety_Concerns: 0,
    Mental_Health_Support: 0,
    Substance_Use_Support: 0,
    Source_of_Income: "Ontario Works (OW) applied or receiving",
    Visual_or_hearing_Disability: 0,
    Physical_disability: 0,
    Learning_Disability: 0,
    Intellectual_Disability: 0,
    Mental_Health_Condition: 0,
    Long_Term_Health_Condition: 0,
    Cronic_pain: 0,
    Crime_Conviction: 0,
    Current_Student: 0,
    Currently_Working: 1,
    months_since_last_job: 0,
    Work_duration: 12,
    Hours_worked_last_job: 40,
    Hourly_wage_last_job: 26,
    Canadian_work_experience: 0,
    Ability_to_work: 1,
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 30,
    clientName: "Tony Stark",
  },
  {
    Client_ID: 100002,
    BirthDate: "1960-09-16",
    Age: 63,
    City: "Halifax",
    Province: "NS",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 63,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Common law",
    Number_of_Dependents: 1,
    Gender_Identity: "Another gender identity",
    LGBTQ: "yes",
    Francophone: "yes",
    Indigenous: "No",
    Race: "Middle Eastern",
    Education_Level: "apprenticeship",
    Education_Completion_Year: "1976",
    Education_Location: "Canada",
    Language_Skills_Help: 1,
    Writing_Skills: 0,
    Verbal_Skills: 1,
    Numerical_Skills: 1,
    Computer_Skills: 0,
    Support_Connections: 0,
    Internet_Access: 0,
    Transportation_Access: 0,
    Caregiver: 0,
    Housing_Situation: "Homeowner",
    Housing_help: "0",
    Food_Insecurity: 1,
    Immediate_Assistance_Need: 0,
    Safety_Concerns: 0,
    Mental_Health_Support: 0,
    Substance_Use_Support: 0,
    Source_of_Income: "Dependent of someone receiving OW or ODSP",
    Visual_or_hearing_Disability: 1,
    Physical_disability: 0,
    Learning_Disability: 0,
    Intellectual_Disability: 0,
    Mental_Health_Condition: 0,
    Long_Term_Health_Condition: 0,
    Cronic_pain: 0,
    Crime_Conviction: 1,
    Current_Student: 0,
    Currently_Working: 1,
    months_since_last_job: 0,
    Work_duration: 60,
    Hours_worked_last_job: 27,
    Hourly_wage_last_job: 30,
    Canadian_work_experience: 1,
    Ability_to_work: 3,
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 30,
    clientName: "Frank Hays",
  }
]

const items = {
  "100001": {
    "stream": "Stream B: (Employability and Employment)",
    "plan_items": [
      {
        "item": "Community Supports - Newcomer services",
        "reason": "The client has been living in Canada for a while but could still benefit from services targeted to newcomers such as understanding Canadian workplace culture and expectations.",
        "weight": 40,
      },
      {
        "item": "Resource and information services (R & I) - digital services",
        "reason": "The client lacks computer skills, which are essential for most jobs. This service will provide resources and tools for the client to improve their digital literacy.",
        "weight": 25,
      },
      {
        "item": "Employability skills training",
        "reason": "The client needs to improve their employability skills such as confidence, motivation, and accessing resources.",
        "weight": 10,
      },
      {
        "item": "Job search",
        "reason": "The client is currently working but may be looking for better job opportunities. This service will provide support for planning and conducting successful job searches.",
        "weight": 5,
      }
    ]
  },
  "100002": {
    "stream": "Stream C: (Employability Focused)",
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "reason": "The client is experiencing food insecurity. This service will provide access to affordable, healthy food and resources about food systems.",
        "weight": 30,
      },
      {
        "item": "Employment services for people with disabilities",
        "reason": "The client has a visual or hearing disability. This service will provide targeted employment services for people with disabilities.",
        "weight": 15,
      },
      {
        "item": "Community Supports - Justice and legal support",
        "reason": "The client has a crime conviction. This service will provide resources and access to diversion options, legal, restorative justice options, and postincarceration supports.",
        "weight": 10,
      },
      {
        "item": "Job seeker - Accommodation needs - assistive devices and adaptive technology",
        "reason": "The client has a visual or hearing disability. This service will provide assistive devices used to replace, compensate for, or improve the functional abilities of people with disabilities.",
        "weight": 5,
      }
    ]
  },
  "100003": {
    "stream": "Stream B: (Employability and Employment)",
    "plan_items": [
      {
        "item": "Community Supports - Newcomer services",
        "reason": "The client has been living in Canada for a while but could still benefit from services targeted to newcomers such as understanding Canadian workplace culture and expectations.",
        "weight": 40,
      },
      {
        "item": "Resource and information services (R & I) - digital services",
        "reason": "The client lacks computer skills, which are essential for most jobs. This service will provide resources and tools for the client to improve their digital literacy.",
        "weight": 25,
      },
      {
        "item": "Employability skills training",
        "reason": "The client needs to improve their employability skills such as confidence, motivation, and accessing resources.",
        "weight": 10,
      },
      {
        "item": "Job search",
        "reason": "The client is currently working but may be looking for better job opportunities. This service will provide support for planning and conducting successful job searches.",
        "weight": 5,
      }
    ]
  },
  "100004": {
    "stream": "Stream C: (Employability Focused)",
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "reason": "The client is experiencing food insecurity. This service will provide access to affordable, healthy food and resources about food systems.",
        "weight": 30,
      },
      {
        "item": "Employment services for people with disabilities",
        "reason": "The client has a visual or hearing disability. This service will provide targeted employment services for people with disabilities.",
        "weight": 15,
      },
      {
        "item": "Community Supports - Justice and legal support",
        "reason": "The client has a crime conviction. This service will provide resources and access to diversion options, legal, restorative justice options, and postincarceration supports.",
        "weight": 10,
      },
      {
        "item": "Job seeker - Accommodation needs - assistive devices and adaptive technology",
        "reason": "The client has a visual or hearing disability. This service will provide assistive devices used to replace, compensate for, or improve the functional abilities of people with disabilities.",
        "weight": 5,
      }
    ]
  },
  "100005": {
    "stream": "Stream B: (Employability and Employment)",
    "plan_items": [
      {
        "item": "Community Supports - Newcomer services",
        "reason": "The client has been living in Canada for a while but could still benefit from services targeted to newcomers such as understanding Canadian workplace culture and expectations.",
        "weight": 40,
      },
      {
        "item": "Resource and information services (R & I) - digital services",
        "reason": "The client lacks computer skills, which are essential for most jobs. This service will provide resources and tools for the client to improve their digital literacy.",
        "weight": 25,
      },
      {
        "item": "Employability skills training",
        "reason": "The client needs to improve their employability skills such as confidence, motivation, and accessing resources.",
        "weight": 10,
      },
      {
        "item": "Job search",
        "reason": "The client is currently working but may be looking for better job opportunities. This service will provide support for planning and conducting successful job searches.",
        "weight": 5,
      }
    ]
  },
  "100006": {
    "stream": "Stream C: (Employability Focused)",
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "reason": "The client is experiencing food insecurity. This service will provide access to affordable, healthy food and resources about food systems.",
        "weight": 30,
      },
      {
        "item": "Employment services for people with disabilities",
        "reason": "The client has a visual or hearing disability. This service will provide targeted employment services for people with disabilities.",
        "weight": 15,
      },
      {
        "item": "Community Supports - Justice and legal support",
        "reason": "The client has a crime conviction. This service will provide resources and access to diversion options, legal, restorative justice options, and postincarceration supports.",
        "weight": 10,
      },
      {
        "item": "Job seeker - Accommodation needs - assistive devices and adaptive technology",
        "reason": "The client has a visual or hearing disability. This service will provide assistive devices used to replace, compensate for, or improve the functional abilities of people with disabilities.",
        "weight": 5,
      }
    ]
  }
}

const ReturnToWorkPredictionFormSection = () => {
  const [outputData, setOutputData] = useState();
  const [clientsName, setClientsName] = useState();
  const [clientID, setSelectedClientID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState();
  // const [clientAge, setClientAge] = useState();
  // const [clientNationality, setClientNationality] = useState();
  // const [clientYearsInCanada, setClientYearsInCanada] = useState();
  const [clientStream, setClientStream] = useState("Client's Stream");

  const [percentValue, setPercentValue] = useState(0);

  const [selectedClientItems, setSelectedClientItems] = useState([]);

  const reset = () => {
    // setClientAge("Client's Age");
    // setClientNationality("Client's Nationality");
    // setClientYearsInCanada("Client's Years in Canada");
    setClientStream("Client's Stream");
    setPercentValue(0);
    setSelectedClientItems([]);
    setResult();
    if(selectedClientItems) {
      for(let i = 0; i < selectedClientItems.length; i++) {
        document.getElementById(i)?.classList.add("btn-bg-alt");
      }
    }
  }
  
  const handleBtnClick = (percent, id) => {
    const selectedButton = document.getElementById(id);
    if(selectedButton.classList.contains("btn-bg-alt")) {
      selectedButton.classList.remove("btn-bg-alt");
      setPercentValue(percentValue => percentValue + percent);
    } else {
      selectedButton.classList.add("btn-bg-alt");
      setPercentValue(percentValue => percentValue - percent);
    }
  }

  const downloadAndReadFile = async (fileURL) => {
    try {
      const response = await fetch(fileURL,
        {
          method: "GET",
          redirect: "follow"
        });
      const resultJSON = await response.json();
      setResult(resultJSON);
    } catch (error) {
      console.error('Error downloading JSON:', error);
    }
  }

  const downloadFile = async ({ data, fileName, fileType }) => {
    setIsLoading(true);
    const blob = new Blob([data], { type: fileType });


    const formData = new FormData();
    formData.append("inputFile", blob, fileName);
    formData.append("resultType", "json");
    try {
      const res = await fetch(process.env.REACT_APP_FORM_ENDPOINT, {
        method: "POST",
        body: formData
      });
      const responseData = await res.json();
      const fileURL = responseData.json.results;
      await downloadAndReadFile(fileURL);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setIsLoading(false);
  }

  const updateClientDetails = (selected) => {
    setSelectedClientID(selected.value);
    // Create CSV
    let headers = ['Client_ID,Birth Date,Age,City,Province,Preferred_Language,Birth_Country,Year_of_Arrival_to_Canada,Years_in_Canada,Status_In_Canada,Immigration_Category,Marital_Status,Number_of_Dependents,Gender_Identity,LGBTQ,Francophone,Indigenous,Race,Education_Level,Education_Completion_Year,Education_Location,Language_Skills_Help,Writing_Skills,Verbal_Skills,Numerical_Skills,Computer_Skills,Support_Connections,Internet_Access,Transportation_Access,Caregiver,Housing_Situation,Housing_help,Food_Insecurity,Immediate_Assistance_Need,Safety_Concerns,Mental_Health_Support,Substance_Use_Support,Source_of_Income,Visual_or_hearing_Disability,Physical_disability,Learning_Disability,Intellectual_Disability,Mental_Health_Condition,Long_Term_Health_Condition,Cronic_pain,Crime_Conviction,Current_Student,Currently_Working,months_since_last_job,Work_duration,Hours_worked_last_job,Hourly_wage_last_job,Canadian_work_experience,Ability_to_work,Hours_available_to_work,Desired_Hourly_Wage'];
    // Convert users data to a csv
    let usersCsv = clients2.reduce((acc, user) => {
      const { Client_ID, BirthDate, Age, City, Province, Preferred_Language, Birth_Country, Year_of_Arrival_to_Canada, Years_in_Canada, Status_In_Canada, Immigration_Category, Marital_Status, Number_of_Dependents, Gender_Identity, LGBTQ, Francophone, Indigenous, Race, Education_Level, Education_Completion_Year, Education_Location, Language_Skills_Help, Writing_Skills, Verbal_Skills, Numerical_Skills, Computer_Skills, Support_Connections, Internet_Access, Transportation_Access, Caregiver, Housing_Situation, Housing_help, Food_Insecurity, Immediate_Assistance_Need, Safety_Concerns, Mental_Health_Support, Substance_Use_Support, Source_of_Income, Visual_or_hearing_Disability, Physical_disability, Learning_Disability, Intellectual_Disability, Mental_Health_Condition, Long_Term_Health_Condition, Cronic_pain, Crime_Conviction, Current_Student, Currently_Working, months_since_last_job, Work_duration, Hours_worked_last_job, Hourly_wage_last_job, Canadian_work_experience, Ability_to_work, Hours_available_to_work, Desired_Hourly_Wage } = user;
      if(Client_ID === selected.value) acc.push([Client_ID, BirthDate, Age, City, Province, Preferred_Language, Birth_Country, Year_of_Arrival_to_Canada, Years_in_Canada, Status_In_Canada, Immigration_Category, Marital_Status, Number_of_Dependents, Gender_Identity, LGBTQ, Francophone, Indigenous, Race, Education_Level, Education_Completion_Year, Education_Location, Language_Skills_Help, Writing_Skills, Verbal_Skills, Numerical_Skills, Computer_Skills, Support_Connections, Internet_Access, Transportation_Access, Caregiver, Housing_Situation, Housing_help, Food_Insecurity, Immediate_Assistance_Need, Safety_Concerns, Mental_Health_Support, Substance_Use_Support, Source_of_Income, Visual_or_hearing_Disability, Physical_disability, Learning_Disability, Intellectual_Disability, Mental_Health_Condition, Long_Term_Health_Condition, Cronic_pain, Crime_Conviction, Current_Student, Currently_Working, months_since_last_job, Work_duration, Hours_worked_last_job, Hourly_wage_last_job, Canadian_work_experience, Ability_to_work, Hours_available_to_work, Desired_Hourly_Wage].join(','));
      return acc;
    }, []);
    let timeStamp = new Date();
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `client_${(timeStamp.getUTCFullYear())}-${(timeStamp.getUTCMonth() + 1).toString().padStart(2,"0")}-${(timeStamp.getUTCDate()).toString().padStart(2,"0")}-${(timeStamp.getUTCHours()).toString().padStart(2,"0")}${(timeStamp.getUTCMinutes()).toString().padStart(2,"0")}${(timeStamp.getUTCSeconds()).toString().padStart(2,"0")}.csv`,
      fileType: 'text/csv',
    });
  }
  
  const handleClientNameSelect = (selected) => {
    reset();
    selected ? updateClientDetails(selected) : reset();
  }

  useEffect(() => {
    if(selectedClientItems.length > 0) {
      for(let i = 0; i < 3; i++) {
        document.getElementById(i).click();
      }
    }
  }, [selectedClientItems]);

  useEffect(() => {
    let tempArr = [];
    clients2.forEach((client) => {
      tempArr.push({"value": client.Client_ID, "label" : client.clientName + " (" + client.Client_ID + ")"});
    });  
    setClientsName(tempArr);
  }, [])

  useEffect(() => {
    if(clientID) {
      if(result && result[clientID]) {
        setClientStream(result[clientID].stream);
        let tempArr = [];
        result[clientID].plan_items.forEach((el) => tempArr.push({"item" : el.item, "weight" : el.weight}));
        setSelectedClientItems(tempArr);
      }
    }
  }, [result, clientID])

  return (
    <>
      <Element
        name="aiform" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <ReturnToWorkPredictionSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Return To Work Prediction"
              />
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4 discover-ai-form-container mt--80">
              <ReturnToWorkPredictionForm setOutputData={setOutputData} />
              <div className="form-output mt--40">
                {outputData && <ReturnToWorkPredictionDownloadButton outputData={outputData} />}
              </div>
            </div>
            <div className="return-to-work-prediction-section-divider"></div> */}
            <div className="col-12 row client-details-container mt--80">
              <div className="col-12 col-lg-6 client-detail-wrapper">
                <h4>Client Details</h4>
                <h6>Name</h6>
                <Select
                  className="basic-single client-detail-select-container"
                  placeholder="Select or search by name..."
                  isClearable="true"
                  name="clientName"
                  onChange={handleClientNameSelect}
                  options={clientsName}
                />
                {/* <h6 className="mt--30">Age</h6>
                <p className="client-detail-container">{clientAge}</p>
                <h6>Nationality</h6>
                <p className="client-detail-container">{clientNationality}</p>
                <h6>Years in Canada</h6>
                <p className="client-detail-container">{clientYearsInCanada}</p> */}
                <h6 className="mt--30">Stream</h6>
                <p className="client-detail-container">{clientStream}</p>
                {isLoading && <div className="mt-4">Processing data...<br></br><img src="../images/loading.gif" alt="illustration" /></div>}
              </div>
              <div className="col-12 col-md-6 client-predictions-container">
                <h4>Predictions</h4>
                <h6>Return to work (RTW)</h6>
                <div className="d-flex flex-row justify-content-evenly align-items-center mt--40">
                  {/* <img src="/images/shapes/shape-24-01.png" alt="illustration" /> */}
                  <Pie percentage={percentValue} />
                  <h2>{percentValue}%</h2>
                </div>
                <h6 className="mt--40">Recommended Plan items to increase RTW</h6>
                <div className="recommended-plan-items-container mt--20">
                  {selectedClientItems.map((element, idx) => (
                    <button key={idx} className="recommended-plan-item edu-btn btn-bg-alt" id={idx} value={element.weight} onClick={() => handleBtnClick(element.weight, idx)}>{element.item}</button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default ReturnToWorkPredictionFormSection;