import React from 'react';
import DataTalentBanner from '../../../components/data-talent/DataTalentBanner';
import DataTalentDescription from '../../../components/data-talent/DataTalentDescription';
import DataTalentMedia from '../../../components/data-talent/DataTalentMedia';
import DataTalentHowDoesItWork from '../../../components/data-talent/DataTalentHowDoesItWork';
import DataTalentWhyChooseUs from '../../../components/data-talent/DataTalentWhyChooseUs';
import DataTalentCurriculum from '../../../components/data-talent/DataTalentCurriculum';
import DataTalentContribute from '../../../components/data-talent/DataTalentContribute';
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';

const JobsAndAutomation = () => {
	return (
		<>
			<SEO
				title="DataTalent"
				description="Unlock 80 hours, fully subsidized, Digital Tech internships for Canadian employers, to build DataTalent pipeline using our Upskilled Talents."
			/>
			<HeaderTwo styles="header-transparent header-style-2" searchDisable />
			<DataTalentBanner pageTitle="Jobs & Automation" />
			<DataTalentDescription />
			<DataTalentMedia />
			<DataTalentHowDoesItWork />
			<DataTalentWhyChooseUs />
			<DataTalentCurriculum />
			<DataTalentContribute />
			<FooterOne />
		</>
    )
}
export default JobsAndAutomation;