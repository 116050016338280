import React from 'react';
import { Element } from 'react-scroll';
import BASectionTitle from './BASectionTitle';

const businessPrograms = [
  {
    image: "program1.png",
    title: "Corporate Innovation Program",
    description: "Blurb Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia est qui ipsa voluptas repellat officiis aperiam."
  },
  {
    image: "program2.png",
    title: "Cycle Momentum",
    description: "Blurb Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia est qui ipsa voluptas repellat officiis aperiam."
  },
  {
    image: "program3.png",
    title: "Datapreneur",
    description: "Blurb Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia est qui ipsa voluptas repellat officiis aperiam."
  },
  {
    image: "program4.png",
    title: "Founder Fuel",
    description: "Blurb Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia est qui ipsa voluptas repellat officiis aperiam."
  },
  {
    image: "program5.png",
    title: "HyperScale AI",
    description: "Blurb Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia est qui ipsa voluptas repellat officiis aperiam."
  },
]

const BAGenAIApplication = () => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="service-wrapper-1 edu-section-gap home-one-cat"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row align-items-center">
            <div className="col-lg-12 mb-4">
              <BASectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Business Accelerator Programs"
              />
            </div>
            {businessPrograms.map((program, idx) => (<div className="bap-card col-lg-4 mt-4" key={idx}>
              <div className="bg-white rounded p-4">
                <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/${program.image}`} alt="generative ai in fintech" />
                <h5 className="mb-0">{program.title}</h5>
                <p className="mb-0">{program.description}</p>
              </div>
            </div>))}
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAGenAIApplication;