import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import PartnershipsBanner from '../../components/partnerships/PartnershipsBanner';
import PartnershipsDescription from '../../components/partnerships/PartnershipsDescription';
import Partners from '../../components/partnerships/Partners';
import PartnershipsMedia from '../../components/partnerships/PartnershipsMedia';
import WhoWeWorkWith from '../../components/partnerships/WhoWeWorkWith';
import PartnershipsSuccessStory from '../../components/partnerships/PartnershipsSuccessStory';
import PartnershipsWorkTogether from '../../components/partnerships/PartnershipsWorkTogether';
import PartnershipsWhatWeOffer from '../../components/partnerships/PartnershipsWhatWeOffer';
import PartnershipsOpportunities from '../../components/partnerships/PartnershipsOpportunities';
import PartnershipsCallToAction from '../../components/partnerships/PartnershipsCallToAction';
import PartnershipsFAQ from '../../components/partnerships/PartnershipsFAQ';
import PartnershipsOfferings from '../../components/partnerships/PartnershipsOfferings';
import PartnershipsBlogs from '../../components/partnerships/PartnershipsBlogs';

const Partnerships = () => {
  return (
    <>
      <SEO title="Partnerships" />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <PartnershipsBanner />
      <PartnershipsWhatWeOffer />
      <PartnershipsOfferings />
      <Partners />
      <PartnershipsOpportunities />
      <PartnershipsFAQ />
      <PartnershipsBlogs />
      <PartnershipsCallToAction />
      {/* <PartnershipsDescription /> */}
      {/* <PartnershipsMedia /> */}
      {/* <WhoWeWorkWith /> */}
      {/* <PartnershipsSuccessStory /> */}
      {/* <PartnershipsWorkTogether /> */}
      <FooterOne />
    </>
  )
}

export default Partnerships;