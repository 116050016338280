import React from 'react';

const AMPONavbar = ({
  isSticky,
  handleSkillsboostClick,
  handleAIReadyClick,
  handleCareerLaunchClick,
  activeAMPOTab
}) => {

  return (
    <>
      {isSticky && <div className={`dpo-nav d-flex flex-row gap-3${isSticky ? " dpo-nav-sticky" : ""}`} id="DPOMobileNavbar">
        <div>
          <button className={`dpo-nav-item${activeAMPOTab === "Skillsboost" ? " active-program-overview" : ""}`} id="SkillsboostMobileNavbar" onClick={handleSkillsboostClick}>
            SkillsBoost
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeAMPOTab === "AIReady" ? " active-program-overview" : ""}`} id="AIReadyMobileNavbar" onClick={handleAIReadyClick}>
            AIReady
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeAMPOTab === "CareerLaunch" ? " active-program-overview" : ""}`} id="CareerLaunchMobileNavbar" onClick={handleCareerLaunchClick}>
            CareerLaunch
          </button>
        </div>
      </div>}
    </>
  )
}

export default AMPONavbar;