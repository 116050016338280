import React, { useEffect, useState } from 'react';
import SEO from '../../../common/SEO';
import OurAppliedAISolutions from '../../../components/applied-ai-solutions/OurAppliedAISolutions';
import CustomSolutions from '../../../components/applied-ai-solutions/CustomSolutions';
import AppliedAISolutionsBanner from '../../../components/applied-ai-solutions/AppliedAISolutionsBanner';
import AppliedAISolutionsDescription from '../../../components/applied-ai-solutions/AppliedAISolutionsDescription';
import AppliedAISolutionsMedia from '../../../components/applied-ai-solutions/AppliedAISolutionsMedia';
import FooterOne from '../../../common/footer/FooterOne';
import HeaderTwo from '../../../common/header/HeaderTwo';
import { scrollIntoViewWithOffset } from '../../../utils/scrollToViewWithOffset';

const AppliedAISolutions = ({
  filterValue,
  setFilterValue
}) => {

  useEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if(anchor) {
      scrollIntoViewWithOffset(anchor);
    }
  }, [])

  return (
    <>
      <SEO
        title="Applied AI Solutions"
        description=""
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <AppliedAISolutionsBanner setFilterValue={setFilterValue} />
      <AppliedAISolutionsDescription />
      <AppliedAISolutionsMedia />
      <OurAppliedAISolutions filterValue={filterValue} />
      {/* <CustomSolutions /> */}
      <FooterOne />
    </>
  )
}

export default AppliedAISolutions;