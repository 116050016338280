import React from "react";

const BAVerticalTabContent = (props) => {
  let detail = props.data;

  return (
    <div
      key={props.index}
      className={`ba-vertical-tab-content${props.activeTabId === props.index ? " d-block" : " d-none"}`}
    >
      <>
        <h4>{detail.content.title}</h4>
        <li className="d-block">
          <p className="mb-0">{detail.content.description}</p>
          <p className="mb-0 mt-4" style={{color: "var(--color-primary)", fontWeight: "600"}}>FEATURES</p>
          {detail.content.bullets.map((bullet, idx) => 
          <div className="d-flex flex-row mt-4" key={idx}>
            <div className="col-1 ba-feature-icon">
              <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/${bullet.image}`} alt="illustration" />
            </div>
            <div className="col-11 ba-feature-content">
              <h5 className="mb-2">{bullet.head}</h5>
              <p className="mb-2">{bullet.body}</p>
            </div>
          </div>
          )}
        </li>
      </>
    </div>
  );
}

export default BAVerticalTabContent;
