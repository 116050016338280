import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutUsOurMission = () => {

  return (
    <>
    <Element
        name="about-us-our-mission"
        className="service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap home-one-cat"
      >
        <div className="container about-feature-list eduvibe-animated-shape about-us-our-mission-container">
          <div className="row g-5">
            <div className="col-12">
              <SectionTitle
                classes="text-center"
                title="Driving Innovation and Sustainability Across Industries"
                slogan="Our Mission"
              />
              <p className="mt--40">Our mission is to create a dynamic ecosystem where innovative tech solutions and sustainable practices drive progress across multiple sectors including CleanTech, Advanced Manufacturing, Business Intelligence, Finance, and Healthcare.</p>
            </div>
            <div className="col-lg-4">
              <div className="service-card service-card-3 text-left shape-bg-1">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-Browser"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">Expertise</h6>
                    <p>Utilizing cutting-edge technology and industry knowledge to deliver exceptional results.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-card service-card-3 text-left shape-bg-2">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-Settings"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">Collaboration</h6>
                    <p>Partnering with Industry Leaders to create impactful learning and working experiences.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-card service-card-3 text-left shape-bg-3">
                <div className="inner">
                  <div className="icon">
                    <a href="#">
                      <i className="icon-Destination"></i>
                    </a>
                  </div>
                  <div className="content">
                    <h6 className="title">Sustainability</h6>
                    <p>Prioritizing eco-friendly practices and sustainable job creation.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default AboutUsOurMission;