import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';

const FeatureItems = [
  {
    title: 'Projects Completed',
    number: 300,
    image: 'about-image-01.png'
  },
  {
    title: 'Enrollments',
    number: 10000,
    image: 'about-image-02.png'
  },
];

const AboutUsWhatWeDo = () => {

  return (
    <>
    <Element
        name="about-us"
        className="service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap home-one-cat"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5 justify-content-center">
            <div className="col-12">
              <SectionTitle 
                classes = "text-center"
                title = "Driving Innovation Since 2019"
                slogan = "what we do"
              />
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-1 row mt--40">
              <div className="col-7 d-flex flex-column justify-content-center">
                <TrackVisibility once className="counter text-center" style={{color: "var(--color-primary)"}} tag="h3">
                  { ( { isVisible } ) => isVisible && 
                    isVisible ? <><CountUp className="eduvibe-about-counterup" end={FeatureItems[0].number} duration={1.5} delay={.1} start={0} />+</> : ''                      
                  }
                </TrackVisibility>
                <p className="text-center">Work Integrated Learning<br></br>Projects Completed</p>
              </div>
              <div className="col-5 d-flex flex-column justify-content-center">
                <img className="what-we-do-images" src={`${process.env.PUBLIC_URL}/images/about/about-09/projects-completed.webp`} alt="Shape Thumb" />
              </div>
              <div className="col-5 d-flex flex-column justify-content-center mt--20">
                <img className="what-we-do-images" src={`${process.env.PUBLIC_URL}/images/about/about-09/enrollments.webp`} alt="Shape Thumb" />
              </div>
              <div className="col-7 d-flex flex-column justify-content-center mt--20">
                <TrackVisibility once className="counter text-center" style={{color: "var(--color-primary)"}} tag="h3">
                  { ( { isVisible } ) => isVisible && 
                    isVisible ? <><CountUp className="eduvibe-about-counterup" end={FeatureItems[1].number} duration={1.5} delay={.1} start={0} />+</> : ''
                  }
                </TrackVisibility>
                <p className="text-center">Course Enrollments</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 mt--40">
              <div className="feature-style-4 mt--20">
                <div className="edu-feature-list">
                  <div className="icon">
                    <img src="/images/about/about-09/fluent_brain-circuit-24-regular.png" alt="icon thumb" />
                  </div>
                  <div className="content">
                    <h6 className="title">Applied AI solutions</h6>
                    <p>Revolutionizing Industries with Innovative AI solutions and comprehensive AI expertise spanning Predictive Analytics, Generative AI, Anomaly Detection, Sentiment Analysis, Recommendation Systems, Natural Language Processing, Knowledge Mining and beyond.</p>
                  </div>
                </div>
                <div className="edu-feature-list color-var-2">
                  <div className="icon">
                    <img src="/images/about/about-09/ph_building-office.png" alt="icon thumb" />
                  </div>
                  <div className="content">
                    <h6 className="title">Industry Aligned upskilling</h6>
                    <p>Empowering job-seekers for a data-driven future. Our programs, developed in collaboration with employers and workforce development experts, empowers job-seekers to swiftly transition into data-driven roles. Tailored to meet the evolving needs of both employers and job-seekers.</p>
                  </div>
                </div>
                <div className="edu-feature-list">
                  <div className="icon">
                    <img src="/images/about/about-09/streamline_ai-technology-spark.png" alt="icon thumb" />
                  </div>
                  <div className="content">
                    <h6 className="title">Cutting-Edge Research</h6>
                    <p>We prioritize creating a sustainable job economy. Join our rapidly growing research programs and become part of a vibrant community committed to personal and professional development in Battery Value Chain, Supply Chain Optimization and Data Center Analytics. Unlock new opportunities, gain valuable skills, and thrive in an environment that fosters growth. Embark on a transformative journey with us!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default AboutUsWhatWeDo;