import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PartnershipsSectionTitle from './PartnershipsSectionTitle';
import SectionTitle from '../sectionTitle/SectionTitle';

const items = [
  {
    "id": 1,
    "title": "Real-world Analytics — Exploring Lending Club Loans",
    "image": "data-talent/article_real-world_analytics_v2.webp",
    "date": "7 January, 2024",
    "shortDate": "7 Jan",
    "author": ["Claude"],
    "readingTime": "3 Min Read",
    "categories": ["UMcG"],
    "tags": ["STEM", "Students", "Technology", "STEM Education"],
    "details": `As a participant in M2M Tech's DataTalent Program, Claude de Villeres, researched and wrote about the financial technology sector for his Capstone Project. His article “Real-world Analytics - Exploring Lending Club Loans” provides a comprehensive analysis of loans within the Lending Club.
    The Lending Club is an online loan marketplace which provides users with easier access to reduced interest rates across various loan types. De Villeres refers to the 2007 to 2011 dataset from the Lending Club which examines details about credit scores, finance inquiries, collections and more with approximately 39,000 loans recorded.`,
    "link": "https://medium.com/@jvilleres3/real-world-analytics-exploring-lending-club-loans-c84a84fe0379",
  },
  {
    "id": 2,
    "title": "Real-world Analytics — Exploring Lending Club Loans",
    "image": "data-talent/article_real-world_analytics_v2.webp",
    "date": "7 January, 2024",
    "shortDate": "7 Jan",
    "author": ["Claude"],
    "readingTime": "3 Min Read",
    "categories": ["UMcG"],
    "tags": ["STEM", "Students", "Technology", "STEM Education"],
    "details": `As a participant in M2M Tech's DataTalent Program, Claude de Villeres, researched and wrote about the financial technology sector for his Capstone Project. His article “Real-world Analytics - Exploring Lending Club Loans” provides a comprehensive analysis of loans within the Lending Club.
    The Lending Club is an online loan marketplace which provides users with easier access to reduced interest rates across various loan types. De Villeres refers to the 2007 to 2011 dataset from the Lending Club which examines details about credit scores, finance inquiries, collections and more with approximately 39,000 loans recorded.`,
    "link": "https://medium.com/@jvilleres3/real-world-analytics-exploring-lending-club-loans-c84a84fe0379",
  },
  // {
  //   "id": 2,
  //   "title": "University of Victoria, AICLUB",
  //   "image": "students-spotlight/spotlight-02_v2.webp",
  //   "date": "15 May, 2023",
  //   "shortDate": "15 May",
  //   "author": ["Jaclyn"],
  //   "readingTime": "3 Min Read",
  //   "categories": ["UVic"],
  //   "tags": ["STEM", "Students", "Technology", "AI", "STEM Education"],
  //   "details": "We're excited to showcase the University of Victoria Artificial Intelligence Club (UVic AI) and their incredible contributions to the field of artificial intelligence.",
  //   "link": "https://medium.com/m2mtechconnect/stem-student-spotlight-uvic-ai-club-university-of-victoria-663147b7497c",
  // },
  // {
  //   "id": 3,
  //   "title": "University of McGill, Sumaya Soufi",
  //   "image": "students-spotlight/spotlight-03.webp",
  //   "date": "24 July, 2023",
  //   "shortDate": "24 Jul",
  //   "author": ["M2M Tech"],
  //   "readingTime": "3 Min Read",
  //   "categories": ["UMcG"],
  //   "tags": ["STEM", "Students", "Tech","Machine Learning", "STEM Education"],
  //   "details": "We're excited to showcase Sumaya Soufi! Sumaya is a student from McGill University (located in Quebec), and she participated in our Work Integrated Learning opportunity.",
  //   "link": "https://medium.com/m2mtechconnect/stem-student-spotlight-sumaya-soufi-university-of-mcgill-e58d7997e594",
  // },
]

const PartnershipsBlogs = () => {
    return (
        <>
          <div className="edu-blog-area eduvibe-datatalent-blog edu-section-gap bg-image home-one-cat">
            <div className="container eduvibe-animated-shape">
              <div className="row g-5 align-items-center">
                <div className="col-lg-12">
                  <SectionTitle
                    classes = "text-center"
                    slogan = "Our Work"
                    title = "Read Out Partnerships Blogs Below!"
                  />
                </div>
              </div>
              <div className="row g-5 mt--30">
                { 
                  items.toReversed().map((item) => (
                    <ScrollAnimation 
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                      className="col-lg-6 col-sm-6 col-12"
                      key={ item.id } 
                    >
                      <div className="edu-blog blog-type-3 radius-small bg-color-white">
                        <div className="inner">
                          <div className="content">
                            <h4 className="title">
                              <a href={item.link}>{item.title}</a>
                            </h4>
                            <div className="blog-card-bottom">
                              <ul className="blog-meta">
                                <li><i className="icon-calendar-2-line"></i>{ item.date }</li>
                                <li><i className="icon-user-line"></i>Posted By <span className="color-primary">{item.author}</span></li>
                              </ul>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <a href={item.link}>
                              <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt="Blog Thumb" />
                            </a>
                          </div>
                          <div className="description">
                            <p style={{whiteSpaceCollapse: "preserve"}}>{ item.details }</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  ) )
                }
              </div>
              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-02.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-2">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-15-02.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-3">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-03.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-4">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-17.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default PartnershipsBlogs;