import React, { useEffect, useState } from 'react';
import useForm from '../../../customhooks/UseForm';

// const FORM_ENDPOINT = "https://formspree.io/f/mnqobvrw"; // TODO - update to the correct endpoint

const AIForm = ({ setOutputData }) => {
  const [FORM_ENDPOINT ,setFORM_ENDPOINT] = useState();

  const { handleSubmit, status, message } = useForm();

  const handleAPI = (e) => {
    setFORM_ENDPOINT(e.target.value);
  }

  useEffect(() => {
    if (status === "success") {
      setOutputData(message);
    }
  }, [status, message, setOutputData])
  
  useEffect(() => {
    if (status === "error") {
      setOutputData("Something bad happened \n" + message);
    }
  }, [status, message, setOutputData])

  return(
    <>
    <div className="pt-0 mb-4">
      <label className="w-100">API
        <input
          type="text"
          name="api"
          placeholder="API link"
          required
          onChange={handleAPI}
        />
      </label>
    </div>
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
    >
      <div className="pt-0 mb-3">
        <label className="w-100">Input 1
          <textarea
            placeholder="Text input 1"
            name="service"
            rows="5"
            required
          />
        </label>
      </div>
      <div className="pt-0 mb-3">
        <label className="w-100">Input 2
          <textarea
            placeholder="Text input 2"
            name="profile"
            rows="5"
            required
          />
        </label>
      </div>      
      {/* {status !== "loading" && ( */}
        <div className="pt-0 mb-3">
          <button className="edu-btn btn-secondary" type="submit">
            Submit
          </button>
        </div>
      {/* )} */}
    </form>    
    </>
  )
}

export default AIForm;