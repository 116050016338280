import React, { useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';
import Select from 'react-select';
import HealthCareInsightsSectionTitle from './HealthCareInsightsSectionTitle';
// import { AzureOpenAIClient } from "openai";
import { OpenAIClient, AzureKeyCredential } from "@azure/openai";

const sampleResponse = [
  { 
    "Influenza (flu)" : {
      "Symptoms": ["fever", "cough", "sore throat", "body aches", "fatigue"],
      "Prevention": ["annual flu vaccine", "frequent handwashing"],
      "Likelihood": "5-20% of the population",
    }
  },
  {
    "Common cold" : {
      "Symptoms": ["runny or stuffy nose", "sneezing", "sore throat", "mild cough"],
      "Prevention": ["frequent handwashing", "avoiding close contact with sick individuals"],
      "Likelihood": "90% of the population",
    }
  },
  {
    "Norovirus (stomach flu)": {
      "Symptoms": ["nausea", "vomiting", "diarrhea", "stomach cramps"],
      "Prevention": ["frequent handwashing", "proper food handling and preparation"],
      "Likelihood": "10-20% of the population",
    }
  },
  {
    "Respiratory syncytial virus (RSV)": {
      "Symptoms": ["cough", "runny nose", "fever", "wheezing (more severe in infants and older adults)"],
      "Prevention": ["frequent handwashing", "avoiding close contact with sick individuals"],
      "Likelihood": "50-70% of infants and young children",
    }
  },
  {
    "Seasonal allergies (hay fever)": {
      "Symptoms": ["sneezing", "itchy or watery eyes", "runny or stuffy nose"],
      "Prevention": ["avoiding allergens", "using air purifiers", "taking antihistamines"],
      "Likelihood": "20-30% of the population",
    }
  },
  {
    "Streptococcal pharyngitis (strep throat)": {
      "Symptoms": ["sore throat", "difficulty swallowing", "fever", "swollen tonsils"],
      "Prevention": ["avoiding close contact with sick individuals", "proper hand hygiene"],
      "Likelihood": "10-15% of the population",
    }
  },
  {
    "Pneumonia": {
      "Symptoms": ["cough", "fever", "chest pain", "difficulty breathing"],
      "Prevention": ["pneumococcal vaccine", "frequent handwashing", "avoiding smoking"],
      "Likelihood": "1-5% of the population",
    }
  },
  {
    "Gastroenteritis (stomach bug)": {
      "Symptoms": ["diarrhea", "vomiting", "stomach cramps", "nausea"],
      "Prevention": ["frequent handwashing", "proper food handling and preparation"],
      "Likelihood": "10-20% of the population",
    }
  }
]

const sampleResponse2 = {
  "seasonal_illnesses": [
    {
      "illness": "Influenza",
      "symptoms": "Fever, cough, sore throat, body aches",
      "prevention": "Annual flu vaccine, hand hygiene, avoiding close contact",
      "likelihood": "10%",
      "seasonal": true
    },
    {
      "illness": "Common cold",
      "symptoms": "Runny nose, sneezing, sore throat",
      "prevention": "Hand hygiene, avoiding close contact",
      "likelihood": "30%",
      "seasonal": true
    },
    {
      "illness": "Norovirus",
      "symptoms": "Nausea, vomiting, diarrhea",
      "prevention": "Hand hygiene, proper food handling",
      "likelihood": "5%",
      "seasonal": true
    },
    {
      "illness": "Strep throat",
      "symptoms": "Sore throat, difficulty swallowing, fever",
      "prevention": "Avoiding close contact, hand hygiene",
      "likelihood": "15%",
      "seasonal": true
    }
  ],
  "sources": [
    {
      "title": "Centers for Disease Control and Prevention (CDC) - Influenza",
      "link": "https://www.cdc.gov/flu/index.htm"
    },
    {
      "title": "Mayo Clinic - Common cold",
      "link": "https://www.mayoclinic.org/diseases-conditions/common-cold/symptoms-causes/syc-20351605"
    },
    {
      "title": "Centers for Disease Control and Prevention (CDC) - Norovirus",
      "link": "https://www.cdc.gov/norovirus/index.html"
    },
    {
      "title": "Mayo Clinic - Strep throat",
      "link": "https://www.mayoclinic.org/diseases-conditions/strep-throat/symptoms-causes/syc-20350338"
    }
  ]
}

const TEMPERATURE = 0;
const MAX_TOKENS = 1000;

const HealthCareInsightsFormSection = () => {
  const [countriesAndProvinces, setCountriesAndProvinces] = useState();
  const [province, setProvince] = useState();
  const [country, setCountry] = useState();
  // const [countryOptions, setCountryOptions] = useState();
  const [provinceOptions, setProvinceOptions] = useState();
  const [insight, setInsight] = useState();
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [countdownView, setCountdownView] = useState(false);
  
  const remainingTime = 60;

  const selectProvince = useRef();
  
  const countryOptions = [
    {
      label: "United States",
      value: "United States",
    },
    {
      label: "Canada",
      value: "Canada",
    },
    {
      label: "Mexico",
      value: "Mexico",
    },
  ]
  
  const reset = () => {
    selectProvince.current.setValue(null);
    setProvince(null);
    // setProvinceOptions(null);
    setInsight();
  }

  const handleChangeProvince = async (selected) => {
    if(selected) {
      setProvince(selected.value);
      let temp;
      if(country && selected.value) {
        // temp = `Country: ${country} and Province: ${province}. \n\nThis is a long text to add scroll effect to the chat. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
        // Recusandae quasi expedita repellendus sunt placeat hic eveniet debitis modi. Minima, provident laboriosam cum esse nam placeat nesciunt saepe tempore excepturi at.
        // Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus vel perferendis quis quo labore et vitae libero incidunt saepe, beatae at quos minus, dolorem numquam velit provident quae.
        // Voluptatibus, omnis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas magni voluptates officia et.
        // Adipisci, nam error odit quas vel soluta aut illo corrupti eveniet dolores voluptatibus, tempore obcaecati nihil. Enim! Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        // Beatae, voluptas nostrum, cumque modi quod adipisci libero natus eos vero aliquid numquam dolore dolores, repellendus alias perferendis illum dicta? Cupiditate, labore.
        // Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit harum non vero cupiditate esse. Odit, nobis quod.
        // Laudantium fugiat nemo architecto possimus minima repellendus numquam, doloremque quis eveniet. Ut, quidem! Lorem ipsum dolor sit amet consectetur adipisicing elit.
        // Possimus magni voluptatem necessitatibus mollitia voluptas laboriosam sunt quasi vero ipsa repellendus, quaerat asperiores saepe totam cupiditate laborum quis accusamus rem deleniti.
        // This is a long text to add scroll effect to the chat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae quasi expedita repellendus sunt placeat hic eveniet debitis modi.
        // Minima, provident laboriosam cum esse nam placeat nesciunt saepe tempore excepturi at. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        // Delectus vel perferendis quis quo labore et vitae libero incidunt saepe, beatae at quos minus, dolorem numquam velit provident quae.
        // Voluptatibus, omnis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas magni voluptates officia et.
        // Adipisci, nam error odit quas vel soluta aut illo corrupti eveniet dolores voluptatibus, tempore obcaecati nihil. Enim! Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        // Beatae, voluptas nostrum, cumque modi quod adipisci libero natus eos vero aliquid numquam dolore dolores, repellendus alias perferendis illum dicta? Cupiditate, labore.
        // Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit harum non vero cupiditate esse. Odit, nobis quod.
        // Laudantium fugiat nemo architecto possimus minima repellendus numquam, doloremque quis eveniet. Ut, quidem! Lorem ipsum dolor sit amet consectetur adipisicing elit.
        // Possimus magni voluptatem necessitatibus mollitia voluptas laboriosam sunt quasi vero ipsa repellendus, quaerat asperiores saepe totam cupiditate laborum quis accusamus rem deleniti.`;
        // temp = [...sampleResponse];
        let location = `${selected.value}, ${country}`;
        // console.log(location);
        // generate('British Columbia, Canada');
        setLoading(true);
        setCountdown(remainingTime);
        await generate(location);
        setLoading(false);
      } else {
        temp = `Please select a country and/or a province/state!`;
      }
      // setInsight([...temp]);
      // let data = await JSON.parse(temp);
      // console.log(data.seasonal_illnesses);
      // setInsight(JSON.parse(data));
    } else {
      setProvince(null);
    }
  }

  const handleChangeCountry = (selected) => {
    selected ? setCountry(selected.value) : setCountry(null);
  }

  const generate = async (location) => {
    try {
      // Get and load config settings
      const azureOaiEndpoint = process.env.REACT_APP_AZURE_OAI_ENDPOINT;
      const azureOaiKey = process.env.REACT_APP_AZURE_OAI_KEY;
      const azureOaiDeployment = process.env.REACT_APP_AZURE_OAI_DEPLOYMENT;
  
      const client = new OpenAIClient(azureOaiEndpoint, new AzureKeyCredential(azureOaiKey));
      
      const messages = [
        {"role": "system", "content": "You are a health reporter writing on seasonal illnesses within " + location},
        {"role": "user", "content": "In JSON format and the fewest words possible without an intro and conclusion, provide bullet points symptoms, preventions, a percentage value of the likelihood in getting each illness and true or false value of if the illness is seasonal. Provide a bullet point section for quoting sources and links used to help support the data"}
      ];

      const reponseTemplate = {
        name: 'structure_JSON',
        description: 'Get the Seasonal Illnesses in a given location',
        parameters: {
          type: 'object',
          properties: {
            ListOfIllnesses: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Name: { type: 'string', description: 'Illness name' },
                  symptoms: { type: 'string', description: 'List of symptoms' },
                  preventions: { type: 'string', description: 'List of preventions' },
                  likelihood: { type: 'integer', description: 'Percentage score of the likelihood of obtaining' },
                  seasonal: { type: 'string', description: 'True or False value if Seasonal or not' }
                }
              }
            },
            Sources: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Name: { type: 'string', description: 'Illness name' },
                  Source: { type: 'string', description: 'Sources and links used to help support the data' }
                }
              }
            }
          }
        }
      }
      const options = {
        temperature: TEMPERATURE,
        maxTokens: MAX_TOKENS,
        tools: [
          {
            type: "function",
            function: reponseTemplate,
          },
        ],
      }
      // Send request to Azure OpenAI model
      const response = await client.getChatCompletions(azureOaiDeployment, messages, options);
      // const response = await client.getChatCompletions(azureOaiDeployment, messages, { temperature: TEMPERATURE, maxTokens: MAX_TOKENS });
      // const data = response.choices[0].message.content;
      const data = response.choices[0].message.toolCalls[0].function.arguments;
      let temp = JSON.parse(data);
      console.log(temp);
      setInsight(temp);
      // console.log(`Output for: ${location}\n${response.choices[0].message.content}\n`);
      // console.log(`Prompt tokens: ${response.usage.promptTokens}\nCompletion tokens: ${response.usage.completionTokens}\nTotal tokens: ${response.usage.totalTokens}\n`);
    } catch (ex) {
      console.error(ex);
    }
  }

  useEffect(() => {
    // console.log(countdown);
    if(countdown > 0) {
      const interval = setInterval(() => {
        setCountdown(countdown => countdown - 1);
      }, 1000);
      setCountdownView(true);
      return () => {
        clearInterval(interval);
      }
    } else {
      setCountdownView(false);
    }
  }, [countdown])

  // useEffect(() => {
  //   reset();
  //   if(province) {
  //     setCountdown(remainingTime);      
  //   } else {
  //     reset();
  //   }
  // }, [province])

  useEffect(() => {
    if(country) {
      let selectedCountry = countriesAndProvinces.filter((item) => item.name === country);
      let tempArr = [];
      selectedCountry[0].states.map((item) => tempArr.push({"label" : item.name, "value": item.name}));
      setProvinceOptions([...tempArr]);
      setProvince(null);
    } else {
      reset();
      setProvinceOptions(null);
    }
  }, [country, countriesAndProvinces])
  
  useEffect(() => {
    let fetchCountries = async () => {
      let res = await fetch("https://countriesnow.space/api/v0.1/countries/states", {
        method : "GET",
      });
    let data = await res.json();
    setCountriesAndProvinces(data.data);
    // console.log(data.data);
  }
  fetchCountries();
  }, [])

  return (
    <>
      <Element
        name="healthcareInsights" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <HealthCareInsightsSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Health Care Insights"
              />
            </div>
            <div className="col-12 input-section mt--40 d-flex flex-row flex-wrap justify-content-center align-items-start">
              {/* <form action={CHATBOT_FORM_ENDPOINT}
                onSubmit={handleSubmit}
                method="POST"
                className="d-flex flex-row flex-wrap justify-content-center align-items-center"
              > */}
                <Select
                  // value={country || ""}
                  className="basic-single m-3 health-care-country-select"
                  placeholder="Select Country..."
                  options={countryOptions}
                  onChange={handleChangeCountry}
                  isClearable="true"
                  name="Countries"
                  required
                />
                <Select
                  // value={province || ""}
                  isDisabled={countdown > 0 ? true : false}
                  ref={selectProvince}
                  className="basic-single m-3 health-care-province-select"
                  placeholder="Select Province..."
                  options={provinceOptions}
                  onChange={handleChangeProvince}
                  isClearable="true"
                  name="Provinces"
                />
                {/* <button type="submit" id="HealthCareInsightsSubmit" className="edu-btn btn-medium health-care-submit-btn">Go</button> */}
              {/* </form> */}
            </div>
            {countdownView > 0 && <p className="text-center">Please wait {countdown} {countdown === 1 ? "second" : "seconds"} before using the model again.</p>}
            {!insight ? <div className="height-300 text-center">{loading && <div className="mt-4">Processing data...<br></br><img src="../images/loading.gif" alt="illustration" /></div>}</div> : <div className="col-12 chatbot-container mt--40">
              <div className="insights-section">
                {/* This area will have chat bubbles here. */}
                {insight && insight.ListOfIllnesses.map((eachResponse, idx) => <div key={idx} className="insights-section-text">
                  {/* <h4 className="insights-section_heading">{Object.entries(eachResponse)[0][0]}</h4> */}
                  <h4 className="insights-section_heading">{eachResponse.Name}</h4>
                  <ul className="insights-section_subheading">
                    <li><h5>Symptoms :</h5>
                      <ul className="insights-section_symptoms">
                        {eachResponse.symptoms.split(', ').map((eachKey, eachIdx) => <li key={eachIdx} className="insights-section_details">
                          {eachKey.charAt(0).toUpperCase() + eachKey.slice(1)}
                        </li>)}
                      </ul>
                    </li>
                    <li><h5>Preventions :</h5>
                      <ul className="insights-section_prevention">
                        {eachResponse.preventions.split(', ').map((eachKey, eachIdx) => <li key={eachIdx} className="insights-section_details">
                          {eachKey.charAt(0).toUpperCase() + eachKey.slice(1)}
                        </li>)}
                      </ul>
                    </li>
                    <li><h5>Likelihood :</h5>
                      <ul className="insights-section_liklihood">
                        <li className="insights-section_details">
                          {/* {eachResponse.likelihood.charAt(0).toUpperCase() + eachResponse.likelihood.slice(1)} */}
                          {eachResponse.likelihood}%
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>)}
              </div>
            </div>}
            {insight && <h4 className="mt--40 mb-0">Sources</h4>}
            {insight && <ol className="px-4">{insight.Sources.map((value, idx) => <li key={idx} className="mt-4"><a href={value.Source} className="eduvibe-status status-06 d-flex flex-row align-items-center justify-content-between" style={{"maxWidth": "max-content"}} rel="noreferrer" target="_blank">{value.Name}&nbsp;<i className="icon-arrow-right-line-right"></i></a></li>)}</ol>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default HealthCareInsightsFormSection;