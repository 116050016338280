import React, { useState } from "react";
import BAVerticalTabList from "./BAVerticalTabList";
import BAVerticalTabContent from "./BAVerticalTabContent";

const BAVerticalTab = ({BAServiceTabContent}) => {
  const [activeTabId, setActiveTabId] = useState(0);

  const btnClick = (id) => {
    setActiveTabId(id);
  }

  return (
    <div className="container ba-services d-none d-md-block">
      <div className="row">
        <div className="col-lg-3">
          <div>
            <ul className="p-0">
              {BAServiceTabContent.map((service, index) => (
                <BAVerticalTabList
                  key={index}
                  onClick={btnClick}
                  data={service}
                  index={index}
                  activeTabId={activeTabId}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-9">
          {BAServiceTabContent.map((service, index) => (
            <BAVerticalTabContent
              data={service}
              key={index}
              index={index}
              activeTabId={activeTabId}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BAVerticalTab;