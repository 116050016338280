import React from 'react';
import { Link } from 'react-router-dom';
import ProgramsSectionTitle from './ProgramsSectionTitle';

const items = [
  {
    id: 1,
    title: 'AI Prototype for Public Sector',
    info: 'The goal of the project is to build a prototype of an AI-based tool that can be used by public sector organizations to predict service needs based on client characteristics and results.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/public_sector_AI_v2_thumbnail.webp',
    link: '/programs/work-integrated-learning/ai-prototype-for-public-sector'
  },
  {
    id: 2,
    title: 'Artificial intelligence for speech enhancement',
    info: 'This project aims to use data collection with field work to create a model to improve the performance of RMK mics, a device used to receive and deliver sound.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AI_speech_enhancement_v2_thumbnail.webp',
    link: '/programs/work-integrated-learning/artificial-intelligence-for-speech-enhancement'
  },
  {
    id: 3,
    title: 'Convert Chat Threads into a Vector Database',
    info: 'The goal of this project is to apply the latest AI & ML techniques to an existing dataset to create an AI/ML model that supports development of more content and user access with the platform\'s evolution.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AIChat_db_thumbnail.webp',
    link: '/programs/work-integrated-learning/convert-chat-threads-into-a-vector-database'
  },
  {
    id: 4,
    title: 'AI & ML for Sports Data Site',
    info: 'Development of an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AI_sports_data_thumbnail.webp',
    link: '/programs/work-integrated-learning/aritificial-intelligence-and-machine-learning-for-sports-data-site'
  },
  {
    id: 5,
    title: 'Data Analytics Skills Assignments',
    info: 'We are launching an exciting initiative aimed at developing the next generation of data analytics talent in higher education.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/Data_Analytics_skills_thumb.webp',
    link: '/programs/work-integrated-learning/data-analytics-skills-assignments'
  },
  {
    id: 6,
    title: 'Health Sciences Data Analytics',
    info: 'Develop a pilot project to illustrate the role of multi-edge computing in Neuraura\'s long-term vision for LoOoP.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/health_sciences_data_analytics_thumb.webp',
    link: '/programs/work-integrated-learning/health-sciences-data-analytics'
  },
  {
    id: 7,
    title: 'Supply Chain Optimization',
    info: 'The goal of this project is to create an efficient supply chain optimization system using Python libraries such as NumPy and Pandas.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Ecommerce',
    image: 'project-details/supply-chain-optimization_thumbnail.webp',
    link: '/programs/work-integrated-learning/supply-chain-optimization',
    form: true,
  },
  {
    id: 8,
    title: 'Medical Diagnosis Project',
    info: 'The main goal of this project is to create a medical diagnosis system using classification supervised learning. This system will be used to accurately diagnose medical conditions based on patient data.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Health Tech',
    image: 'project-details/medical-diagnosis-project_thumbnail.webp',
    link: '/programs/work-integrated-learning/medical-diagnosis-project',
    form: true,
  },
  {
    id: 9,
    title: 'Housing Market Price Prediction',
    info: 'The goal of this project is to create a housing market price prediction using regression supervised learning.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'FinTech',
    image: 'project-details/housing-market-price-prediction_thumbnail.webp',
    link: '/programs/work-integrated-learning/housing-market-price-prediction',
    form: true,
  },
  {
    id: 10,
    title: 'Clean Energy Consumption Forecasting Project',
    info: 'The goal of this project is to create a forecasting system using Regression Supervised Machine Learning to predict Clean Energy consumption.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'CleanTech',
    image: 'project-details/clean-energy-consumption-forecasting-project_thumbnail.webp',
    link: '/programs/work-integrated-learning/clean-energy-consumption-forecasting-project',
    form: true,
  },
  {
    id: 11,
    title: 'Intrusion Detection Project',
    info: 'The goal of this project is to create an IoT Intrusion Detection system that uses Decision Trees to identify anomalous activities that may indicate potential attacks or unauthorized access attempts. This will help in early detection and prevention of security breaches.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'IoT, CyberSecurity',
    image: 'project-details/intrusion-detection-project_thumbnail.webp',
    link: '/programs/work-integrated-learning/intrusion-detection-project',
    form: true,
  },
  {
    id: 12,
    title: 'Healthcare Monitoring and Management Project',
    info: 'The goal of this project is to create a Healthcare Monitoring and Management system that uses IoT devices such as wearables, medical sensors and connected healthcare systems to collect patient data using Deep Learning.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'IoT, Health Tech',
    image: 'project-details/healthcare-monitoring-and-management-project_thumbnail.webp',
    link: '/programs/work-integrated-learning/healthcare-monitoring-and-management-project',
    form: true,
  },
  {
    id: 13,
    title: 'Predictive Maintenance Solution',
    info: 'The goal of this project is to build a predictive maintenance solution using Deep Learning.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Advanced Manufacturing',
    image: 'project-details/predictive-maintenance-solution_thumbnail.webp',
    link: '/programs/work-integrated-learning/predictive-maintenance-solution',
    form: true,
  },
  {
    id: 14,
    title: 'Crop Yield Prediction Project',
    info: 'The main goal of this project is to develop a crop yield prediction system using machine learning techniques.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'AgTech',
    image: 'project-details/crop-yield-prediction-project_thumbnail.webp',
    link: '/programs/work-integrated-learning/crop-yield-prediction-project',
    form: true,
  }
];

const IndustryPartnerWIL = ( {
  setShowUserInterestForm
  } ) => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 edu-section-gap bg-image" id="Work_Integrated_Learning">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <ProgramsSectionTitle
                classes="text-center"
                title="Industry Partner Work Integrated Learning"
              />
            </div>
            { items.map( ( data , i ) => (
              <div key={data.id} className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="service-card program-service-card service-card-1 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <a href={ data.link }>
                        <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} alt="Category Thumb" loading="lazy" />
                      </a>
                    </div>
                    <div className="content program-info">
                      <div className="program-info-content d-flex justify-content-between">
                        <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                        <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                      </div>
                      <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                      <p className="description">{ data.info }</p>
                      <div className="d-flex justify-content-between flex-wrap mt-3">
                        {data.form ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> : <a href="/" style={{visibility : "hidden"}}>No link</a>}
                        <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) ) }
          </div>
        </div>
      </div>
    </>
  )
}

export default IndustryPartnerWIL;