import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import BABanner from '../../components/business-accelerator/BABanner';
import BADescription from '../../components/business-accelerator/BADescription';
import BAWhoShouldApply from '../../components/business-accelerator/BAWhoShouldApply';
import BAWhy from '../../components/business-accelerator/BAWhy';
import BASuccessStory from '../../components/business-accelerator/BASuccessStory';
import BAGenAI from '../../components/business-accelerator/BAGenAI';
import BAGenAIApplication from '../../components/business-accelerator/BAGenAIApplication';

const BusinessAccelerator = () => {
	return (
		<>
			<SEO
				title="Business Accelerator"
				description="Powering Innovations with our AI solutions."
			/>
			<HeaderTwo styles="header-transparent header-style-2" searchDisable />
			<BABanner />
			<BAWhy />
			<BADescription />
			<BAGenAI />
			<BAGenAIApplication />
			<BASuccessStory />
			<BAWhoShouldApply />
			<FooterOne />
		</>
    )
}
export default BusinessAccelerator;