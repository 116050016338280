import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';

const AMProgramOverview = ({
  targetRefAM,
  innerRefAM,
  showSkillsboost,
  showAIReady,
  showCareerLaunch,
  handleSkillsboostClick,
  handleAIReadyClick,
  handleCareerLaunchClick
}) => {

  const skillsboostBenefits = [
    {
      image: "courses.svg",
      title: "Access to Online Courses",
      description: "24/7 access to courses in Data Science, Machine Learning, and AI."
    },
    {
      image: "certificate.svg",
      title: "Industry-Recognized Certification",
      description: "Earn a certification to enhance your resume and show your skills."
    },
    {
      image: "ai_resume_builder.svg",
      title: "AI Resume Builder",
      description: "Access exclusive job listings in Data Science, Machine Learning, and AI."
    },
    {
      image: "workshops.svg",
      title: "Webinars and Workshops",
      description: "Join live webinars and workshops to build essential career skills."
    },
    {
      image: "community.svg",
      title: "Community Access",
      description: "Connect with peers and mentors in an active online community."
    }
  ]

  const aireadyBenefits = [
    {
      image: "advanced_courses.svg",
      title: "Advanced Courses",
      description: "Specializations in deep learning, NLP, and data analytics."
    },
    {
      image: "work_integrated.svg",
      title: "Work Integrated Projects",
      description: "Real-world, employer-led projects with companies in diverse industries."
    },
    {
      image: "mentorship.svg",
      title: "Mentorship",
      description: "Monthly one-on-one guidance from industry experts."
    },
    {
      image: "job_search.svg",
      title: "Job Placement Assistance",
      description: "Exclusive job listings and portfolio building support."
    }
  ]

  const careerLaunchBenefits = [
    {
      image: "placement.svg",
      title: "Guaranteed Internship Placement",
      description: "Hands-on experience with support throughout."
    },
    {
      image: "personalized_career_coaching.svg",
      title: "Personalized Career Coaching",
      description: "Bi-weekly sessions to guide career planning and goal setting."
    },
    {
      image: "networking.svg",
      title: "Premium Networking Opportunities",
      description: "Exclusive access to industry events and connections with leaders."
    },
    {
      image: "extended.svg",
      title: "Extended Access & Updates",
      description: "Lifetime access to new course materials and ongoing support."
    }
  ]

  return (
    <>
      <Element
        name="ProgramOverview"
        className="am-program-overiew datatalent-section-gap"
        id="ProgramOverview"
      >
        <div className="container inner" ref={targetRefAM}>
          <div className="row g-5 align-items-center datatalent-showcases">
            <div className="col-lg-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-left"
                slogan = "Program Overview"
                title = "Our Annual Membership Program Packages"
              />
            </div>
            <div className="col-lg-12 mt-0 p-0">
              <div className="dpo-nav d-flex flex-row gap-3 me-0" ref={innerRefAM}>
                <div>
                  <button className="dpo-nav-item active-program-overview" id="Skillsboost" onClick={handleSkillsboostClick}>
                    SkillsBoost
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="AIReady" onClick={handleAIReadyClick}>
                    AIReady
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="CareerLaunch" onClick={handleCareerLaunchClick}>
                    CareerLaunch
                  </button>
                </div>
              </div>
            </div>
            {showSkillsboost && <>
              <div className="p-0 ampo-description">
                <h4>SkillsBoost: The Basic Membership Program</h4>
                <div className="mt-4">
                  <p className="mb-0">The SkillsBoost program provides you with the <strong>fundamental knowledge</strong> and tools to <strong>kickstart your tech career</strong>. Designed for learners seeking <strong>flexibility, this self-paced</strong> program allows you to gain <strong>in-demand skills</strong>, earn <strong>industry-recognized certification</strong>, and connect with <strong>experts</strong>.</p>
                </div>
              </div>
              <div className="skillsboost-benefits-card-container d-flex flex-row flex-wrap justify-content-center p-0">
                {skillsboostBenefits.map((skillsboostBenefit, idx) => (<div className="col-lg-4" key={idx}>
                  <div className="skillsboost-benefits-card mx-0 mx-md-2">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ skillsboostBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{skillsboostBenefit.title}</p>
                    <p className="mb-0">{skillsboostBenefit.description}</p>
                  </div>
                </div>))}
              </div>
              <p className="text-center cursor-pointer mb-0" onClick={() => scrollToViewFromTop("MembershipPackages")}><span className="ampo-cta">See Pricing</span> <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/down_arrow.svg`} alt="down arrow" /></p>
            </>}
            {showAIReady && <>
              <div className="p-0 ampo-description">
                <h4>AI Ready: The Professional Membership Program</h4>
                <div className="mt-4">
                  <p className="mb-0">The AI Ready program advances your learning with <strong>specialized courses</strong> in deep learning, natural language processing, and data analytics. Gain <strong>real-world experience</strong> through <strong>employer-led projects</strong> and receive <strong>personalized mentorship</strong> to thrive in today's job market.</p>
                </div>
              </div>
              <div className="aiready-benefits-container d-none d-md-flex flex-row align-items-center">
                <div className="col-lg-4 d-flex flex-wrap justify-content-end">
                  <div className="skillsboost-benefits-card">
                    <p className="skillsboost-benefits-card-header my-2">All Benefits of SkillsBoost</p>
                    <ul className="mb-0">
                      {skillsboostBenefits.map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1 text-center">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
                </div>
                <div className="col-lg-7 aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                  {aireadyBenefits.map((aireadyBenefit, idx) => (<div className="col-lg-6" key={idx}>
                    <div className="skillsboost-benefits-card">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ aireadyBenefit.image }`} alt="illustration" />
                      <p className="skillsboost-benefits-card-header my-2">{aireadyBenefit.title}</p>
                      <p className="mb-0">{aireadyBenefit.description}</p>
                    </div>
                  </div>))}
                </div>
              </div>
              <div className="col-12 d-flex d-md-none flex-wrap p-0 m-0 justify-content-center">
                <div className="skillsboost-benefits-card">
                  <p className="skillsboost-benefits-card-header my-2">All Benefits of SkillsBoost</p>
                  <ul className="mb-0">
                    {skillsboostBenefits.map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                  </ul>
                </div>
              </div>
              <div className="col-12 d-md-none text-center">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
              </div>
              <div className="col-12 d-md-none aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                {aireadyBenefits.map((aireadyBenefit, idx) => (<div className="col-12 d-flex justify-content-center" key={idx}>
                  <div className="skillsboost-benefits-card">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ aireadyBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{aireadyBenefit.title}</p>
                    <p className="mb-0">{aireadyBenefit.description}</p>
                  </div>
                </div>))}
              </div>
              <p className="text-center cursor-pointer mb-0" onClick={() => scrollToViewFromTop("MembershipPackages")}><span className="ampo-cta">See Pricing</span> <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/down_arrow.svg`} alt="down arrow" /></p>
            </>}
            {showCareerLaunch && <>
              <div className="p-0 ampo-description">
                <h4>CareerLaunch: The Premium Membership Program</h4>
                <div className="mt-4">
                  <p className="mb-0">Take control of your future with CareerLaunch. Through <strong>guaranteed internships</strong>, personalized <strong>coaching</strong>, and exclusive <strong>networking opportunities</strong>, you'll gain the experience, skills, and connections to accelerate your career to the next level.</p>
                </div>
              </div>
              <div className="aiready-benefits-container d-none d-md-flex flex-row align-items-center">
                <div className="col-lg-4 d-flex flex-wrap justify-content-end">
                  <div className="skillsboost-benefits-card">
                    <p className="skillsboost-benefits-card-header my-2">All Benefits of AIReady</p>
                    <ul className="mb-0">
                      {skillsboostBenefits.concat(aireadyBenefits).map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1 text-center">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
                </div>
                <div className="col-lg-7 aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                  {careerLaunchBenefits.map((careerLaunchBenefit, idx) => (<div className="col-lg-6" key={idx}>
                    <div className="skillsboost-benefits-card">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ careerLaunchBenefit.image }`} alt="illustration" />
                      <p className="skillsboost-benefits-card-header my-2">{careerLaunchBenefit.title}</p>
                      <p className="mb-0">{careerLaunchBenefit.description}</p>
                    </div>
                  </div>))}
                </div>
              </div>
              <div className="col-12 d-flex d-md-none flex-wrap p-0 m-0 justify-content-center">
                <div className="skillsboost-benefits-card">
                  <p className="skillsboost-benefits-card-header my-2">All Benefits of AIReady</p>
                  <ul className="mb-0">
                    {skillsboostBenefits.concat(aireadyBenefits).map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                  </ul>
                </div>
              </div>
              <div className="col-12 d-md-none text-center">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
              </div>
              <div className="col-12 d-md-none aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                {careerLaunchBenefits.map((careerLaunchBenefit, idx) => (<div className="col-12 d-flex justify-content-center" key={idx}>
                  <div className="skillsboost-benefits-card">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ careerLaunchBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{careerLaunchBenefit.title}</p>
                    <p className="mb-0">{careerLaunchBenefit.description}</p>
                  </div>
                </div>))}
              </div>
              <p className="text-center cursor-pointer mb-0" onClick={() => scrollToViewFromTop("MembershipPackages")}><span className="ampo-cta">See Pricing</span> <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/down_arrow.svg`} alt="down arrow" /></p>
            </>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default AMProgramOverview;