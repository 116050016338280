import { useState } from "react";

function useReturnToWorkPredictionForm() {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e, file) => {
    e.preventDefault();
    setStatus('loading');
    setMessage('');

    const finalFormEndpoint = e.target.action;
    // console.log(e.target.elements);
    const data = Array.from(e.target.elements)
      .filter((input) => input.name)
      .reduce((obj, input) => Object.assign(obj, { [input.name]: input.value }), {});

      const formData = new FormData();
      formData.append("inputFile", file, data.inputFile);
      formData.append("resultType", data.resultType);

    fetch(finalFormEndpoint, {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // setMessage("Form Submitted Successfully!");
        setMessage(data.json.results);
        setStatus('success');
      })
      .catch((err) => {
        setMessage(err.toString());
        setStatus('error');
      });
  };

  return { handleSubmit, status, message };
}

export default useReturnToWorkPredictionForm;