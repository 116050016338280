import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import ProgramsData from '../../ProgramsData.json';

const HomeOnePrograms = () => {
    return (
        <>
            <div name="programs" className="home-one-programs edu-service-area service-wrapper-1 edu-section-gap bg-image" id="ProgramshowCase">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes = "text-center"
                                slogan = "What We Do"
                                title = "Our Programs"
                            />
                        </div>
                    </div>

                    <div className="row g-5 mt--25">
                        { ProgramsData.map( ( data , i ) => (
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="col-lg-6 col-md-6 col-sm-6 col-12"
                                animateOnce={ true }
                                key={ i }
                            >
                                <div className="service-card program-service-card service-card-1 radius-small">
                                    <div className="inner">
                                        <div className="thumbnail our-programs-thumbnail">
                                            {/* {data.attribution && <a className="our-programs-attribution" href={ data.attributionLink }>{ data.attribution }</a>}
                                            <a href={ data.link }> */}
                                                <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} alt="Category Thumb" loading="lazy" />
                                            {/* </a> */}
                                        </div>
                                        <div className="content program-info">
                                            <div className="program-info-content d-flex justify-content-between">
                                                <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                                                <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                                                {/* <span className="course-total"><i className="icon-map-pin-line"></i> { data.modeOfCourses }</span> */}
                                            </div>
                                            <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                                            <p className="description">{ data.info }</p>
                                            {/* <p className="description">{ data.info.slice(0, 340) + "..." }</p> */}
                                            <div className="d-flex justify-content-between flex-wrap mt-3">
                                                {data.form ==="skillsboost" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/skillsboost-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                                                data.form === "aiminds" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/aiminds">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                                                data.form === "wil" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                                                data.form === "datatalent" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/cohort-sign-up">Jobseekers login<i className="icon-arrow-right-line-right"></i></a> :
                                                <span></span>}
                                                <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        ) ) }
                    </div>
                    
                    {/* <div className="row text-center mt--60">
                        <div className="col-lg-12">
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="load-more-btn"
                                animateOnce={ true }
                            >
                                <Link className="edu-btn" to="/careers/work-integrated-learning">All Programs<i
                                className="icon-arrow-right-line-right"></i></Link>
                            </ScrollAnimation>
                        </div>
                    </div> */}

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeOnePrograms;