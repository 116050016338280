import React from 'react';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Element } from 'react-scroll';

const JobseekersAbout = () => {
  const skills = [
    {
      icon: "pandas_icon",
      skillName: "Pandas"
    },
    {
      icon: "numpy_icon",
      skillName: "NumPy"
    },
    {
      icon: "scikitlearn_icon",
      skillName: "scikit-learn"
    },
    {
      icon: "tensorflow_icon",
      skillName: "TensorFlow"
    },
    {
      icon: "pie_chart_icon",
      skillName: "Data Visualization"
    },
    {
      icon: "nn_icon",
      skillName: "Neural Networks"
    },
    {
      icon: "ai_chip_icon",
      skillName: "Artificial Intelligence"
    },
    {
      icon: "ml_icon",
      skillName: "No-Code Machine Learning"
    },
    {
      icon: "code_icon",
      skillName: "Low-Code Machine Learning"
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7 mt-0 order-1 order-xl-2 p-0">
              <DataTalentSectionTitle
                classes = "text-left"
                slogan = "What You Will Do"
                title = "About DataTalent"
              />
              <h5 className="m-0">
                DataTalent is a 16-week online program designed to help job-seekers transition into <span className="highlight-text-primary">in-demand, data-driven roles</span>.
              </h5>
              <div className="ds-image-container d-block d-md-none">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/about_datatalent.svg`} alt="illustration" />
              </div>
              <ol className="datatalent-steps-container mt-5">
                <li className="datatalent-step-one">
                  <h6 className="mb-3 datatalent-step-indent">Learn <span style={{fontWeight: "500"}}>- Upskill Through Advanced Courses</span></h6>
                  <p className="datatalent-step-indent">Take your skills to the next level with our <strong>advanced course bundles</strong> in <strong>Data Science, Machine Learning and AI</strong>. These fully online courses allow you to learn at your own pace, featuring engaging content and <strong>create Capstone projects</strong> to put your knowledge to the test.</p>
                </li>
                <li className="datatalent-step-one">
                  <h6 className="mb-3 datatalent-step-indent">Practice <span style={{fontWeight: "500"}}>- Build Real-World Application</span></h6>
                  <p className="datatalent-step-indent">It's time to put your learning into action! Apply your new skills by working on a <strong>real-world Employer Led Work Integrated Learning project</strong>. Collaborate with a small team of <strong>3-4 members</strong> to solve Industry challenges and build hands-on experience that makes your resume shine.</p>
                </li>
                <li className="datatalent-step-one">
                  <h6 className="mb-3 datatalent-step-indent">Excel <span style={{fontWeight: "500"}}>- Get Ready for the Tech Industry</span></h6>
                  <p className="datatalent-step-indent">After completing the courses and the Employer-led project, you'll earn a <strong>$500 stipend</strong> as recognition of your hard work. Based on your performance, different opportunities gets created with the Employers, positioning yourself for a successful career in Tech!</p>
                </li>
              </ol>
            </div>
            <div className="col-lg-5 order-2 order-xl-1 d-none d-md-block">
              <div className="ds-image-container">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/about_datatalent.svg`} alt="illustration" />
              </div>
            </div>
            <div className="col-12 container order-3 p-0">
              <div className="row datatalent-steps-footer">
                <div className="col-lg-5 d-none d-md-flex row mb-2">
                  <p className="col-lg-12 p-0">
                    <strong>Learn The Latest <span className="highlight-text-primary">Industry-Standard</span> Data Science Skills</strong><br></br>
                    Easy to learn for those with or without a technical background.
                  </p>
                  <div className="d-none d-md-block col-lg-1"></div>
                  <div className="col-6 col-lg-4 mt-4 d-flex flex-column align-items-center gap-3">
                    <div className="dsf-num-container">
                      <p><strong>3</strong></p>
                    </div>
                    <p className="text-center"><strong>Capstone Projects</strong></p>
                  </div>
                  <div className="col-6 col-lg-4 mt-4 d-flex flex-column align-items-center gap-3">
                    <div className="dsf-num-container">
                      <strong>1</strong>
                    </div>
                    <p className="text-center"><strong>Employer-Led Project</strong></p>
                  </div>
                </div>
                <div className="col-lg-7 d-none d-md-flex p-0 row justify-content-center">
                  {skills.map((skill, idx) => <div className="col-12 col-lg-4 mb-4 d-flex flex-row align-items-center gap-3" key={idx}>
                    <div className="dsf-icon-container">
                      <img src={`${process.env.PUBLIC_URL}/images/data-talent/icons/${skill.icon}.svg`} alt={skill.skillName} />
                    </div>
                    <p className="dsf-skill">{skill.skillName}</p>
                  </div>)}
                </div>
                {/* For Mobile */}
                <p className="col-12 p-0 d-md-none">
                  <strong>Learn The Latest <span className="highlight-text-primary">Industry-Standard</span> Data Science Skills</strong><br></br>
                  Easy to learn for those with or without a technical background.
                </p>
                <div className="col-12 p-0 mt-5 d-md-none d-flex row flex-row align-items-center">
                  <div className="col-5 p-0 h-100 d-flex flex-column justify-content-between">
                    <div className="col-12 d-flex flex-column align-items-center gap-3">
                      <div className="dsf-num-container">
                        <p><strong>3</strong></p>
                      </div>
                      <p className="text-center"><strong>Capstone Projects</strong></p>
                    </div>
                    <div className="col-12 mb-4 d-flex flex-column align-items-center gap-3">
                      <div className="dsf-num-container">
                        <strong>1</strong>
                      </div>
                      <p className="text-center"><strong>Employer-Led Project</strong></p>
                    </div>
                  </div>
                  <div className="col-7 p-0 row justify-content-center">
                    {skills.map((skill, idx) => <div className="col-12 mb-2 d-flex flex-row align-items-start gap-3 p-0" key={idx}>
                      {/* <div className="dsf-icon-container"> */}
                        <img className="dsf-icon-mobile mt-1" src={`${process.env.PUBLIC_URL}/images/data-talent/icons/${skill.icon}.svg`} alt={skill.skillName} />
                      {/* </div> */}
                      <p className="dsf-skill">{skill.skillName}</p>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default JobseekersAbout;