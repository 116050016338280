import React, { useState, useEffect, useRef } from 'react';
import { Element } from 'react-scroll';
import AppliedAISolutionsSectionTitle from './AppliedAISolutionsSectionTitle';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const items = [
  {
    id: 1,
    title: 'Predictive Maintenance: FourSight',
    info: 'FourSight offers Predictive Maintenance that leverages Advanced Analytics and our Machine Learning models to forecast equipment failures before they occur, minimizing downtime and optimizing maintenance schedules. This allows Manufacturing and CleanTech companies like you to adopt a proactive approach to maintenance rather than reactive.',
    solution: 'Predictive Analytics',
    image: 'applied-ai-solutions/Foursight.jpg',
    link: '/solutions/applied-ai-solutions/foursight',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 2,
    title: 'Generative AI: PostAI',
    info: 'Optimize your Digital Marketing strategies through our seamless integration of automated content creation and scheduling for Social Media platforms using PostAI, ensuring consistent and engaging posts tailored to your target audience.',
    solution: 'Generative AI',
    image: 'applied-ai-solutions/postAI.jpg',
    link: '/solutions/applied-ai-solutions/postai',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 3,
    title: 'Anomaly Detection: Real Estate Outlier',
    info: 'REO is a cutting-edge anomaly detection solution tailored for the real estate industry, specifically designed to identify unusual fluctuations or anomalies in property prices. Leveraging advanced Machine Learning algorithms and Statistical Analysis, REO empowers real estate professionals, investors, and market analysts to detect potential price anomalies that may indicate opportunities or risks in the market.',
    solution: 'Anomaly Detection',
    image: 'applied-ai-solutions/anomaly_detection.jpg',
    link: '/solutions/applied-ai-solutions/real-estate-outlier',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 4,
    title: 'Sentiment Analysis: CourseVibe',
    info: 'Online courses including MOOCs have become increasingly popular, but gathering feedback and gauging user satisfaction can be challenging. Sentiment analysis can help analyze user comments and feedback to understand their sentiment towards the course content, instructor, platform usability, and overall learning experience. By extracting sentiment from user feedback, Course Creators can identify positive aspects that resonate with users and areas where improvements are needed.',
    solution: 'Sentiment Analysis',
    image: 'applied-ai-solutions/courseVibe.jpg',
    link: '/solutions/applied-ai-solutions/coursevibe',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 5,
    title: 'Recommendation Systems: SDG Navigator',
    info: 'SDG Navigator is a sophisticated recommendation system designed to assist businesses in identifying and prioritizing Sustainable Development Goals (SDGs) that align with their business values, operations, and objectives. By analyzing the content of companies\' websites and leveraging natural language processing (NLP) techniques, SDG Navigator offers tailored recommendations on SDGs that companies can feasibly integrate into their sustainability strategies.',
    solution: 'Recommended Systems',
    image: 'applied-ai-solutions/sdg_navigator.jpg',
    link: '/solutions/applied-ai-solutions/sdg-navigator',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 6,
    title: 'Natural Language Processing: TestGenius',
    info: 'TestGenius is an innovative solution that leverages advanced natural language processing (NLP) techniques to automate the generation of test scripts directly from natural language requirements. By interpreting and analyzing textual descriptions of software requirements, TestGenius transforms them into executable test scenarios, streamlining the testing process and enhancing efficiency.',
    solution: 'Natural Language Processing',
    image: 'applied-ai-solutions/testGenius.jpg',
    link: '/solutions/applied-ai-solutions/testgenius',
    // areaOfFocus: 'Smart Agriculture',
  },
  {
    id: 7,
    title: 'Knowledge Mining: HealthGuide+',
    info: 'HealthGuide+ is an innovative knowledge mining platform designed to provide personalized health recommendations for individuals visiting or relocating to a new city. Leveraging advanced Data Analytics and Natural Language Processing (NLP) techniques, HealthGuide+ aggregates and analyzes relevant health-related information from diverse data sources, including government health agencies, local healthcare providers.',
    solution: 'Knowledge Mining',
    image: 'applied-ai-solutions/health_guide_plus.jpg',
    link: '/solutions/applied-ai-solutions/healthguide-plus',
    // areaOfFocus: 'Smart Agriculture',
  },
]

const OurAppliedAISolutions = ({
  filterValue
}) => {
  const [allItems, setItems] = useState(items);
  const [filter1, setFilter1] = useState();
  const [filter2, setFilter2] = useState();
  const [filters1, setFilters1] = useState();
  // const [filters2, setFilters2] = useState();
  const [filterFromBanner, setFilterFromBanner] = useState();

  const selectSolutions = useRef();

  const handleFilter1 = (selected) => {
    selected ? setFilter1(selected.value) : setFilter1();
  }
  
  // const handleFilter2 = (selected) => {
  //   selected ? setFilter2(selected.value) : setFilter2();
  // }
      
  useEffect(() => {
    let options = [];
    items.forEach((item) => {
      options.includes(item.solution) === false && options.push(item.solution)
    });
    let filter = [];
    options.forEach((option) => {
      const obj = {};
      obj["value"] = option;
      obj["label"] = option;
      filter.push(obj)
    });
    setFilters1(filter);
  }, []);
  
  // useEffect(() => {
  //   let options = [];
  //   // If first filter applied, update second filter options accordingly
  //   if(filter1) {
  //     items.forEach((item) => {
  //       item.solution === filter1 && options.includes(item.areaOfFocus) === false && options.push(item.areaOfFocus);
  //     });
  //     //If first filter changes, clear second filter
  //     selectAreaOfFocus.current.state.selectValue[0] && selectAreaOfFocus.current.setValue(null);
  //   } else {
  //     items.forEach((item) => {
  //       options.includes(item.areaOfFocus) === false && options.push(item.areaOfFocus)
  //     });
  //   }
  //   let filter = [];
  //   options.forEach((option) => {
  //     const obj = {};
  //     obj["value"] = option;
  //     obj["label"] = option;
  //     filter.push(obj)
  //   });
  //   setFilters2(filter);
  // }, [filter1]);
  
  // Filter (works)
  // useEffect(() => {
  //   let filtered;
  //   if(filter1) {
  //     if(filter2) {
  //       filtered = items.filter((item) => item.solution === filter1 && item.areaOfFocus === filter2);
  //     }
  //   } else if(filter2) {
  //     filtered = items.filter((item) => item.areaOfFocus === filter2);
  //   } else {
  //     filtered = items;
  //   }
  //   setItems(filtered);
  // }, [filter1, filter2])
  
  // Same filter as above but condensed
  useEffect(() => {
    console.log("selectSolutions is: ", selectSolutions);
    let filtered;
    filter1 ? filter2 ? filtered = items.filter(item => item.areaOfFocus === filter2 && item.solution === filter1) : filtered = items.filter(item => item.solution === filter1) : filter2 ? filtered = items.filter(item => item.areaOfFocus === filter2) : filtered = items;
    setItems(filtered);
  }, [filter1, filter2])

  useEffect(() => {
    console.log("filter value", filterValue);
    if(filterValue) {
      setFilterFromBanner(filterValue);
    }
  }, [filterValue])

  useEffect(() => {
    console.log("fillter from banner", filterFromBanner);
    if(filterFromBanner) {
      selectSolutions.current.selectOption({label: filterFromBanner, value: filterFromBanner});
    }
  }, [filterFromBanner])

  return (
    <>
      <Element
        name="our-applied-ai-solutions"
        className="edu-about-area edu-section-gap service-wrapper-1 bg-white"
        id="OurAppliedAISolutions"
      >
        <div className="container">
          <div className="row g-5 justify-content-center">
            <div className="col-12">
              <AppliedAISolutionsSectionTitle
                title="Our Applied AI Solutions"
                classes="text-center"
              />
            </div>
            <div className="col-12 col-md-4">
              <Select
                ref={selectSolutions}
                className="basic-single"
                placeholder="Solution..."
                onChange={handleFilter1}
                isClearable="true"
                name="solution"
                options={filters1}
              />
            </div>
            {/* <div className="col-12 col-md-4">
              <Select
                ref={selectAreaOfFocus}
                className="basic-single"
                placeholder="Project..."
                onChange={handleFilter2}
                isClearable="true"
                name="area of focus"
                options={filters2}
              />
            </div> */}
            <div className="col-12 row mt--80">
              { <>
                <h5 className="col-12 text-center">{allItems.length === items.length ? "" : allItems.length === 0 ? "No projects match your filters." : allItems.length === 1 ? "1 project match your filters." : allItems.length + " projects match your filters." }</h5> {allItems.map( ( data , i ) => (
                <div key={data.id} className="col-lg-4 col-md-6 col-sm-6 col-12 mt--20">
                  <Link to={data.link}>
                    <div className="research-projects research-service-card radius-small">
                      <div className="inner d-flex flex-column">
                        <div className="thumbnail" style={{height: "225px", overflow: "hidden"}}>
                          <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} style={{marginTop: "-50px"}} alt="solution Thumb" loading="lazy" />
                        </div>
                        <div className="content-wrapper d-flex flex-column flex-grow-1 justify-content-between">
                          <div className="content program-info">
                            <h6 className="title">{ data.title }</h6>
                            <p className="description">{ data.info }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                ) ) }
              </> }
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default OurAppliedAISolutions;