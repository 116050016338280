import React, { useContext, useState } from 'react';
import { Element } from 'react-scroll';
import BASectionTitle from './BASectionTitle';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import BAServiceTabContent from './BAServiceTabContent.json';
import BAVerticalTab from './ba-services/BAVerticalTab';


const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton( eventKey );
  const isCurrentEventKey = activeEventKey === eventKey;
  return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState( '' );

  function toggleActive( id ) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
  <>
    <div className="d-flex d-md-none flex-row">
      <Accordion bsPrefix="edu-accordion-02 d-flex flex-row flex-wrap" defaultActiveKey={activeId} flush>
        {BAServiceTabContent.map( ( accordion, i ) => (
          <div className="col-12 col-lg-6" key={i}>
            <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item p-2 ${activeId === i.toString() ? 'bg-active' : ''}`}>
              <div className="edu-accordion-header">
                <CustomToggle eventKey={i.toString()}>{ accordion.title }</CustomToggle>
              </div>
              <Accordion.Body bsPrefix="edu-accordion-body">
                <ul>
                  <li className="d-block">
                    <p className="mb-0">{accordion.content.description}</p>
                    <ul>
                      {accordion.content.bullets.map((bullet, idx) => 
                      <li className="d-block px-5" key={idx}><strong>{bullet.head}</strong>: {bullet.body}</li>
                      )}
                    </ul>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
    </div>
  </>
  )
}

const BADescription = () => {
  return (
    <>
      <Element
        className="ba-description-content service-wrapper-1 style-variation3 edu-section-gap home-one-cat"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <BASectionTitle
                classes = "text-center"
                title = "Our Accelerator Services"
              />
            </div>
            <div className="col-12">
              <div className="data-talent-description m-auto">
                <p>Our AI-Focused Business Accelerator is designed to deliver tangible value to startups, small businesses, and established companies looking to integrate AI into their operations. Here's how we can help:</p>
              </div>
              <CurriculumContent />
              <BAVerticalTab BAServiceTabContent={BAServiceTabContent} />
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
        
      </Element>
    </>
  )
}

export default BADescription;