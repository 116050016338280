import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const FeatureItems = [
    {
        title: 'Access Top Talent',
        info: 'Tap into our network of highly skilled professionals who are passionate about [M2M s Focus Area]',
        icon: 'icon-Destination',
        shape: 'shape-bg-1',
        link: '#'
    },
    {
        title: 'Joint Innovation',
        info: 'Collaborate on cutting-edge projects and research initiatives that push the boundaries of possibility.',
        icon: 'icon-Browser',
        shape: 'shape-bg-2',
        link: '#'
    },
    {
        title: 'Expanded Reach',
        info: 'Leverage our combined expertise and resources to access new markets and customer segments.',
        icon: 'icon-Lock',
        shape: 'shape-bg-3',
        link: '#'
    },
    {
        title: 'Shared Success',
        info: 'Achieve your business goals faster and more efficiently through collaboration.',
        icon: 'icon-Settings',
        shape: 'shape-bg-4',
        link: '#'
    }
];

const FeatureTwo = ( { wrapperClass, bgColor, alignment, style } ) => {
    return (
        <div className={ `row eduvibe-about-one-service ${ wrapperClass || 'g-5 mt--20' }` }>
            { FeatureItems.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-12${ style ? ' '+style : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={ `service-card service-card-3 ${ alignment ? ' ' + alignment : 'text-left' } ${ data.shape } ${ bgColor || '' } ` }>
                        <div className="inner">
                            <div className="icon">
                                <a href={ data.link }>
                                    <i className={ data.icon }></i>
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                                {/* <div className="read-more-btn">
                                    <a className="btn-transparent sm-size heading-color" href={ data.link }>Learn More<i className="icon-arrow-right-line-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default FeatureTwo;