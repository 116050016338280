import React from 'react';
import { Link } from 'react-router-dom';

const DataTalentFooter = () => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 datatalent-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 position-relative datatalent-footer">
            <div className="col-lg-7 p-0 mt-0">
              <h5>Ready to take the next step in your career and become a Data Science Expert?</h5>
              <p className="d-none d-md-block">Unsure if DataTalent is the right program for you?</p>
              <Link to="/contact-us" className="d-none d-md-flex gap-2"><span className="highlight-text-primary"><em>Chat with one of our program<br></br>coordinators to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></Link>
            </div>
            <div className="col-lg-2 d-none d-md-block"></div>
            <div className="col-lg-3 mt-0 p-0 d-flex justify-content-center align-items-center">
              <a className="edu-btn datatalent-cta" href="https://form.jotform.com/m2mtech/cohort-sign-up">Apply Now</a>
            </div>
            <p className="mb-0 p-0 d-block d-md-none">Unsure if DataTalent is the right program for you?</p>
            <Link to="/contact-us" className="d-flex d-md-none gap-2 mt-0 p-0"><span className="highlight-text-primary"><em>Chat with one of our program<br></br>coordinators to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataTalentFooter;