import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "FourSight",
    "image": "fourSight-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Predictive Maintenance",
    "location": "FourSight_URL",
    "going": "140+"
  }
]

const FourSight = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="FourSight"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="FourSight"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    FourSight offers Predictive Maintenance that leverages Advanced Analytics and our Machine Learning models to forecast equipment failures before they occur, minimizing downtime and optimizing maintenance schedules. This allows Manufacturing and CleanTech companies like you to adopt a proactive approach to maintenance rather than reactive.
                  </p>
                  <h4>Key Components</h4>
                  <ul>
                    <li>
                      <strong>Data Collection</strong>: Continuous monitoring of equipment performance data using sensors, IoT devices, and industrial control systems to capture real-time operational data and performance metrics.
                    </li>
                    <li>
                      <strong>Data Analytics</strong>: Analysis of historical equipment data, including patterns, trends, and anomalies, to identify early indicators of potential failures and develop predictive models.
                    </li>
                    <li>
                      <strong>Predictive Models</strong>: Development and deployment of machine learning models and algorithms that use historical data to predict future equipment behavior and anticipate maintenance needs.
                    </li>
                    <li>
                      <strong>Condition Monitoring</strong>: Real-time monitoring of equipment health and performance indicators to assess the current condition and identify any deviations from normal operating parameters.
                    </li>
                    <li>
                      <strong>Maintenance Planning</strong>: Utilization of predictive maintenance insights to optimize maintenance schedules, prioritize maintenance tasks, and allocate resources efficiently.
                    </li>
                    <li>
                      <strong>Integration with CMMS</strong>: Integration of predictive maintenance systems with computerized maintenance management systems (CMMS) to streamline work order generation, track maintenance activities, and record maintenance history.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default FourSight;