import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "RealEstateOutlier",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Anomaly Detection",
    "location": "RealEstateOutlier_URL",
    "going": "140+"
  }
]

const RealEstateOutlier = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="RealEstateOutlier"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="RealEstateOutlier"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    REO is a cutting-edge anomaly detection solution tailored for the real estate industry, specifically designed to identify unusual fluctuations or anomalies in property prices. Leveraging advanced Machine Learning algorithms and Statistical Analysis, REO empowers real estate professionals, investors, and market analysts to detect potential price anomalies that may indicate opportunities or risks in the market.
                  </p>
                  <h4>Key Features</h4>
                  <ul>
                    <li>
                      <strong>Comprehensive Data Analysisy</strong>: REO conducts comprehensive analysis of real estate market data, including property prices, location attributes, market trends, and historical transaction data.
                    </li>
                    <li>
                      <strong>Anomaly Detection Algorithms</strong>: Utilizes advanced anomaly detection algorithms to identify deviations from typical price patterns.
                    </li>
                    <li>
                      <strong>Customizable Thresholds</strong>: Allows users to set customizable anomaly detection thresholds based on property type, location, price range, and other relevant parameters.
                    </li>
                    <li>
                      <strong>Interactive Visualization</strong>: Provides interactive visualization tools to explore detected anomalies, visualize price trends, and analyze the impact of anomalies on market dynamics.
                    </li>
                    <li>
                      <strong>Historical Insights</strong>: Offers historical anomaly detection insights to understand past price fluctuations and their correlation with market events or economic indicators.
                    </li>
                    <li>
                      <strong>Market Segmentation</strong>: Segments the real estate market into different categories, such as residential, commercial, or industrial properties, to provide tailored anomaly detection models for specific market segments.
                    </li>
                    <li>
                      <strong>Risk Assessment</strong>: Assesses the potential risks associated with detected anomalies and provides recommendations for risk mitigation strategies, investment decisions, and pricing strategies.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default RealEstateOutlier;