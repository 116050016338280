import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
	{
		"id": 1,
		"title": "AI & ML for Sports Data Site",
		"image": "AI_sports_data.webp",
		"website": "https://rolling-wave.com/",
		"industry": "Education, It & computing",
		"rating": "4.8 Google Rating",
		"duration": "12 Weeks",
		"category": "Business Services",
		"location": "https://rolling-wave.com/",
		"going": "140+",
		"company": "Rolling Wave & Rolling Insights"
	}
]

const AIANDMLForSportsDataSite = ({
	showRegisterForm,
	setShowRegisterForm,
	company
	}) => {
	const eventItem = EventData[0];
	return (
		<>
			<SEO
				title={ eventItem.title }
				description=""
			/>
			<Layout>
				{/* <BreadcrumbOne 
					title="AI & ML for Sports Data Site"
					rootUrl="/programs/industry-work-integrated-learning"
					parentUrl="Industry Partner Work Integrated Learning"
					currentUrl="AI & ML for Sports Data Site"
				/> */}
				<div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
					<div className="container">
						<div className="row g-5">
							<div className="col-lg-12">
								<div className="thumbnail">
									<img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
								</div>
							</div>
						</div>
						<div className="row g-5">
							<div className="col-lg-7">
								<div className="content">
									<h3 className="title">{ eventItem.title }</h3>
									<p>
										<strong>What is the main goal for this project?</strong><br></br>
										The goal of this project is to develop an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.
									</p>
									<p>
										SportWise by Rolling Insights provides users with no-code access to sports statistics and other data to enable users to make more informed Fantasy and Betting decisions. Users select from a directory of data including historical and present season stats, Fantasy League data, and projections to create a personalized view of the data, which updates daily. Rolling Insights is looking to grow the directory of data with a variety of additional data sources such as projection engines or advanced analysis that update daily related to team and player performance.
									</p>
									<p>
										Students will develop an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.
									</p>
									<p>
										This will involve several different steps for the students, including:
										<ul>
											<li>
												onducting background research on our existing product and sport industry projection requirements.
											</li>
											<li>
												Analyzing our current dataset.
											</li>
											<li>
												Researching the latest AI / ML techniques and how they could be applied to our data.
											</li>
											<li>
												Developing an AI / ML model that provides unique outcomes or insights into our data.
											</li>
											<li>
												Providing multiple solutions that can be applied to solve the same problem.
											</li>
										</ul>
										Examples of Problems we might like to solve:
										<ul>
											<li>
												Historical and Projected Match Up performance (Team A vs Team B)
											</li>
											<li>
												Historical and Projected Player performance for select key stats
											</li>
											<li>
												Historical and Projected team performance with player combinations (Team A with Player A and B, versus Team B with Player C and D)
											</li>
											<li>
												Home vs Away historical performance & projections
											</li>
											<li>
												Others, we're open to suggestions!
											</li>
										</ul>
									</p>
									<p>
										<strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
										By the end of the project, students should demonstrate:
										<ul>
											<li>
												Understanding of the available dataset.
											</li>
											<li>
												Understanding of the latest AI / ML techniques.
											</li>
											<li>
												Identification of ways in which AI / ML can be applied to our company.
											</li>
										</ul>
										Bonus steps would include:
										<ul>
											<li>
												Providing multiple versions of potential models.
											</li>
										</ul>
										Final deliverables should include:
										<ul>
											<li>
												A final report on the dataset, the problem solved, methodologies and approaches, outcomes and results, and recommended next steps.
											</li>
											<li>
												Source materials such as code and workbooks.
											</li>
										</ul>
									</p>
									<p>
										<strong>How will you support learners in completing the project?</strong><br></br>
										We will be able to provide answers to questions such as:
										<ul>
											<li>
												Our current products and applications of AI / ML
											</li>
											<li>
												The current data set and guidance in navigating it
											</li>
											<li>
												Input on choices, problems or anything else the students might encounter.
											</li>
										</ul>
									</p>
								</div>
							</div>
								<div className="col-lg-5">
									<div className="eduvibe-sidebar">
										<div className="eduvibe-widget eduvibe-widget-details">
												<h5 className="title">ABOUT INDUSTRY PARTNER</h5>
												<div className="widget-content">
													<ul>
														{/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
														<li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
														<li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
														{/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
														<li>
															Rolling Insights is a sports data analytics company with two flagship products that entail a consumer facing sports data analytics web app and a real time API product for small businesses that require sports data. Rolling Insights is a startup incubated within Rolling Wave Consulting, a company that helps organizations achieve their business goals through innovative solutions and methodological project management.
														</li>
													</ul>
													{!company && <div className="read-more-btn mt--45">
														<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
													</div>}
												</div>
										</div>
									</div>
								</div>
								{!company && <div className="col-lg-12">
									<div className="read-more-btn text-center">
										<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
									</div>
								</div>}
						</div>
					</div>
				</div>
			</Layout>
			{showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
		</>
	)
}
export default AIANDMLForSportsDataSite;