import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "CourseVibe",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Sentiment Analysis",
    "location": "CourseVibe_URL",
    "going": "140+"
  }
]

const CourseVibe = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="CourseVibe"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="CourseVibe"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    Online courses including MOOCs have become increasingly popular, but gathering feedback and gauging user satisfaction can be challenging. Sentiment analysis can help analyze user comments and feedback to understand their sentiment towards the course content, instructor, platform usability, and overall learning experience. By extracting sentiment from user feedback, Course Creators can identify positive aspects that resonate with users and areas where improvements are needed.
                  </p>
                  <h4>Key Benefits</h4>
                  <ul>
                    <li>
                      <strong>User Engagement</strong>: Gain insights into user sentiment and satisfaction levels, allowing you to tailor course content, delivery methods, and learning materials to meet user needs and preferences.
                    </li>
                    <li>
                      <strong>Quality Improvement</strong>: Identify strengths and weaknesses in course content, instructor performance, and platform usability to drive continuous improvement efforts and enhance the overall learning experience.
                    </li>
                    <li>
                      <strong>Instructor Feedback</strong>: Provide instructors with valuable feedback from learners to help them understand their teaching effectiveness, address learner concerns, and adjust their teaching approach accordingly.
                    </li>
                    <li>
                      <strong>Course Personalization</strong>: Personalize course offerings and recommendations based on user sentiment and feedback, ensuring that learners receive relevant and engaging learning experiences.
                    </li>
                    <li>
                      <strong>Retention and Loyalty</strong>: Improve learner retention and loyalty by addressing user concerns, resolving issues promptly, and fostering a positive learning environment that encourages ongoing participation and engagement.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default CourseVibe;