import React, { useContext, useState } from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Link } from 'react-router-dom';
import CurriculumTabContent from '../../../CurriculumTabContent.json';

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton( eventKey );
  const isCurrentEventKey = activeEventKey === eventKey;
  return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState( '0' );

  function toggleActive( id ) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
  <>
    <div className="d-flex flex-row">
      <Accordion bsPrefix="edu-accordion-02 d-flex flex-row flex-wrap" defaultActiveKey={activeId} flush>
        {CurriculumTabContent.map( ( accordion, i ) => (
          <div className="col-12 col-lg-6" key={i}>
            <Accordion.Item eventKey={i.toString()} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item p-2 ${activeId === i.toString() ? 'bg-active' : ''}`}>
              <div className="edu-accordion-header">
                <CustomToggle eventKey={i.toString()}>{ accordion.title }</CustomToggle>
              </div>
              <Accordion.Body bsPrefix="edu-accordion-body">
                <ul>
                  {accordion.content.map( ( title, index ) => (
                    <li key={index}>
                      <div className="text">{title}</div>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
    </div>
  </>
  )
}

const DataTalentProgramOverview = ({
  targetRef,
  innerRef,
  showTimeline,
  showBenefits,
  showCurriculum,
  handleTimelineClick,
  handleBenefitsClick,
  handleCurriculumClick
}) => {

  return (
    <>
      <Element
        name="programOverview"
        className="edu-about-area about-style-1 datatalent-section-gap datatalent-jobseeker-bg"
      >
        <div className="container inner about-feature-list" ref={targetRef}>
          <div className="row g-5 align-items-start">
            <div className="col-lg-12 mt-0 p-0">
              <DataTalentSectionTitle
                classes = "text-start"
                slogan = "DataTalent Process"
                title = "Program Overview"
              />
            </div>
            <div className="col-12 col-lg-8 p-0 position-relative">
              <div className="dpo-nav d-flex flex-row gap-3" ref={innerRef}>
                <div>
                  <button className="dpo-nav-item active-program-overview" id="Timeline" onClick={handleTimelineClick}>
                    Timeline
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="Benefits" onClick={handleBenefitsClick}>
                    Benefits
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="Curriculum" onClick={handleCurriculumClick}>
                    Curriculum
                  </button>
                </div>
              </div>
              {showTimeline && <div className="col-12 dpo-details pe-md-4">
                <div className="dpo-timeline row g-5">
                  <div className="col-12 p-0">
                    <h4 className="dpo-timeline-heading">Learn in a Flexible & Supportive Environment</h4>
                    <p className="dpo-timeline-description">
                      The 16-week program is designed to complement the busy schedules of working professionals. All learning material is self-directed and asynchronous with live support sessions provided weekly.<br></br><br></br>
                      Below is the suggested timeline for completing the program:
                    </p>
                  </div>
                  <div className="col-12 p-0 dpo-timeline-image-container">
                    <img className="dpo-timeline-image" src={`${process.env.PUBLIC_URL}/images/data-talent/datatalent-timeline.png`} alt="timeline" />
                  </div>
                </div>
              </div>}
              {showBenefits && <div className="col-12 dpo-details pe-md-4">
                <div className="dpo-timeline row g-5">
                  <div className="col-12 p-0">
                    <h4 className="dpo-timeline-heading">Elevate Your Career with Industry Exposure through WILs</h4>
                    <p className="dpo-timeline-description">
                      <strong>DataTalent</strong> program, brought to you by <strong>M2M Tech</strong>, in partnership with <strong>Riipen</strong> and <strong>AiModels Tech</strong>, offers a transformative upskilling experience. This program uniquely combines <strong>Employer-driven Work-Integrated Learning (WIL) projects</strong> with <strong>Professional Development opportunities</strong>, giving you invaluable industry exposure. Gain the skills, connections, and real-world experience you need to thrive in today's competitive tech landscape.<br></br><br></br>
                      With DataTalent, you'll enjoy exclusive benefits such as:
                    </p>
                    <ul className="dpo-benefits-list">
                      <li><strong>Hands-on learning</strong> through Work-Integrated Learning (WIL) projects.</li>
                      <li>Opportunity to <strong>Connect with employers</strong> in your desired industry.</li>
                      <li>Access to online courses in <strong>Data Science & Machine Learning</strong>.</li>
                      <li><strong>A $500 reward</strong> upon successful program completion.</li>
                      <li><strong>1-on-1 technical support</strong> to guide you through your learning journey.</li>
                      <li>Comprehensive <strong>Career Support Services</strong>, including resume reviews, interview preparation, and job search guidance.</li>
                    </ul>
                    <p className="text-center mb-3">Partnered With:</p>
                    <div className="dpo-benefits-partner-logo-container text-center d-flex justify-content-center gap-4 gap-lg-5">
                      <img className="col-6 col-lg-3" src={`${process.env.PUBLIC_URL}/images/logo/aimodels-grey-bg-lrg.png`} alt="" />  
                      <img className="col-6 col-lg-3" src={`${process.env.PUBLIC_URL}/images/logo/Riipen_logo.svg`} alt="" />  
                    </div>
                  </div>
                  {/* <div className="col-12 p-0 dpo-timeline-image-container">
                    <img className="dpo-timeline-image" src={`${process.env.PUBLIC_URL}/images/data-talent/datatalent-benefits.png`} alt="timeline" />
                  </div> */}
                </div>
              </div>}
              {showCurriculum && <div className="col-12 dpo-details pe-md-4">
                <div className="dpo-timeline row g-5">
                  <div className="col-12 p-0">
                    <h4 className="dpo-timeline-heading">Comprehensive Curriculum to Master Key Data Skills</h4>
                    <p className="dpo-timeline-description">
                      Our program covers essential skills in data science, from data visualization to advanced machine learning techniques. Here's a quick look at what each course offers. For full details, download the complete syllabus below.
                    </p>
                  </div>
                  <div className="col-12 p-0 dpo-timeline-image-container">
                    <CurriculumContent />
                  </div>
                  <div className="col-12">
                  <div className="read-more-btn mt-0 text-center">
                    <a className="edu-btn" href="https://form.jotform.com/m2mtech/download-datatalent-curriculum">Detailed Curriculum</a>
                  </div>
                  </div>
                </div>
              </div>}
            </div>
            
            <div className="col-12 col-lg-4 d-none d-md-block align-self-end p-0">
              <div className="dpo-aside">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/program_overview.svg`} alt="illustration" />
                <div className="dpo-aside-wrapper">
                  <h5>Throughout the Program</h5>
                  <p>As you work through the program, you will have access to:</p>
                  <p><img src={`${process.env.PUBLIC_URL}/images/data-talent/icons/support_icon.svg`} alt="support" /> &nbsp;<strong>1-on-1 Technical Support</strong></p>
                  <p><img src={`${process.env.PUBLIC_URL}/images/data-talent/icons/resume_icon.svg`} alt="document" /> &nbsp;<strong>Resume Workshops</strong></p>
                  <p><img src={`${process.env.PUBLIC_URL}/images/data-talent/icons/search_icon.svg`} alt="search" /> &nbsp;<strong>Job Search Guidance</strong></p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-12 p-0">
              <div className=" dpo-eligibility d-flex flex-wrap">
                <div className="col-12 col-lg-8 m-0">
                  <h4><span className="highlight-text-primary">Eligibility</span></h4>
                  <h6>To qualify for the DataTalent program, there are two main requirements:</h6>
                  <ol className="mb-0">
                    <li><strong>Be legally eligible to work in Canada</strong> (as a Canadian Citizen, Permanent Resident, or with a work permit)</li>
                    <span className="datatalent-description-span">*work permit holders must have at least one year left on their permit</span>
                    <li><strong>Have 3+ years of work experience</strong> outside of high school or post-secondary education</li>
                  </ol>
                  <p className="mt-3 mb-0 d-none d-md-block"><em>Not eligible for DataTalent? Explore our <Link to="/solutions/industry-aligned-upskilling#annualplans"><span className="highlight-text-primary">annual plans here <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></span></Link></em></p>
                </div>
                <div className="col-12 col-lg-4 dpo-eligibility-experience">
                  <h6 className="mb-3 text-center">Work Experience Criteria</h6>
                  <div className="dpo-eligibility-experience-wrapper p-0">
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="22px" alt="green check" />
                      <p className="mb-0">&nbsp;Canadian Work</p>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="22px" alt="green check" />
                      <p className="mb-0">&nbsp;International Work</p>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="22px" alt="green check" />
                      <p className="mb-0">&nbsp;Part-time Work</p>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} width="22px" alt="green check" />
                      <p className="mb-0">&nbsp;Volunteer (non-profit) positions</p>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/cross.svg`} width="22px" alt="red check" />
                      <p className="mb-0">&nbsp;Co-operative Education Programs</p>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-3">
                      <img src={`${process.env.PUBLIC_URL}/images/icons/cross.svg`} width="22px" alt="red check" />
                      <p className="mb-0">&nbsp;Internships during University Enrollment</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-block d-md-none mt-4">
                  <p className="mt-3 mb-0 text-center"><em>Not eligible for DatTalent?<br></br> Explore our <Link to="/solutions/industry-aligned-upskilling#annualplans"><span className="highlight-text-primary">annual plans here <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-right.svg`} alt="right arrow" /></span></Link></em></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentProgramOverview;