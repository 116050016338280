import React from 'react';
import { Element } from 'react-scroll';
import DiscoverAISectionTitle from './DiscoverAISectionTitle';
import { Link } from 'react-router-dom';

const DiscoverAIWhyChooseUs = ({
  setShowDiscoverAISignUpForm
}) => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="home-one-programs about-style-1 data-talent-eligibility-section edu-section-gap"
      >
        <div className="edu-about-area">
          <div className="container about-feature-list">
            <div className="col-lg-12">
              <DiscoverAISectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Lorem Ipsum"
              />
            </div>
          </div>
          <div className="container">
            <div className="row g-5 align-items-center mt--40">
              <div className="col-lg-5">
                <div className="about-image-gallery">
                  <img className="image-1" src={`${process.env.PUBLIC_URL}/images/data-talent/data-talent-description.jpg`} alt="decoration" loading="lazy" />
                </div>
              </div>
              <div className="col-lg-7 about-feature-list">
                <div className="inner">
                  <h5 className="text-left mb-0 mt-4">Lorem Ipsum</h5>
                  <div className="our-feature mt-4">
                    <div className="icon">
                      <i className="icon-award-fill-solid"></i>
                    </div>
                    <div className="feature-content">
                      <p className="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis veniam distinctio, quam quisquam iste velit mollitia error ab reprehenderit porro quaerat veritatis doloribus, ea natus? Harum quasi repellat officia laborum?</p>
                    </div>
                  </div>
                  <h5 className="text-left mb-0 mt-4">Lorem Ipsum</h5>
                  <div className="our-feature mt-4">
                    <div className="icon">
                      <i className="icon-award-fill-solid"></i>
                    </div>
                    <div className="feature-content">
                      <p className="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis veniam distinctio, quam quisquam iste velit mollitia error ab reprehenderit porro quaerat veritatis doloribus, ea natus? Harum quasi repellat officia laborum?</p>
                    </div>
                  </div>
                  <h5 className="text-left mb-0 mt-4">Lorem Ipsum</h5>
                  <div className="our-feature mt-4">
                    <div className="icon">
                      <i className="icon-award-fill-solid"></i>
                    </div>
                    <div className="feature-content">
                      <p className="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis veniam distinctio, quam quisquam iste velit mollitia error ab reprehenderit porro quaerat veritatis doloribus, ea natus? Harum quasi repellat officia laborum?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5 align-items-center mt--40">              
              <div className="col-lg-12">
                <div className="data-talent-riipen mt--60">
                  {/* <div className="data-talent-image-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen+m2m_logo.svg`} alt="riipen logo" />
                  </div> */}
                  <h4 className="text-center">Get Involved!</h4>
                  <p>
                    Join us on this exciting journey of innovation, knowledge exchange, and mutual growth. Together, we can make a lasting impact on the world of Data Science, Machine Learning, and Artificial Intelligence. For inquiries and to express your interest in collaboration.
                  </p>
                  <div className="data-talent-cta mt--40">
                    <Link className="edu-btn" to="/contact-us">Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default DiscoverAIWhyChooseUs;