import React from 'react';
// import collaboration from '../../assets/images/partnerships/collaboration.gif';
import ContactUsForm from '../contact/ContactUsForm';
import SectionTitle from '../sectionTitle/SectionTitle';

const PartnershipsOpportunities = () => {

    return (
        <div className="edu-feature-area eduvibe-home-one-video edu-section-gap home-one-cat" id="contact">
            <div className="container eduvibe-animated-shape">
                <div className="row row--35">
                    <SectionTitle
                        classes = "text-center"
                        slogan = "How We Can Collaborate"
                        title = "Get In Touch For Partnership Opportunities"
                    />
                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="inner mt_md--40 mt_sm--40">
                            <div className="mt--40">
                                <ContactUsForm />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12 order-1 order-lg-2 d-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/images/partnerships/collaboration.svg`} alt="animation" loading="lazy" />
                    </div>
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-14.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-01.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-15.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnershipsOpportunities;