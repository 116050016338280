import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaPlay } from 'react-icons/fa';
import AppliedAISolutionsSectionTitle from './AppliedAISolutionsSectionTitle';

const items = [
    {
        title: 'Comprehensive Service Offering',
        info: '',
        icon: 'icon-Badge-icon'
    },
    {
        title: 'Diverse Industry Expertise',
        info: '',
        icon: 'icon-student'
    },
    {
        title: 'Cutting-Edge Innovation',
        info: '',
        icon: 'icon-science'
    },
    {
        title: 'Proven Track Record',
        info: '',
        icon: 'icon-ribbon'
    },
    {
        title: 'Strategic Partnership Approach',
        info: '',
        icon: 'icon-calendar-2-line'
    },
    {
        title: 'Ethical and Responsible Practices',
        info: '',
        icon: 'icon-reading-book'
    }
];

const AppliedAISolutionsMedia = () => {
    const [toggler, setToggler] = useState(false);
    const videoLink = ['https://www.youtube.com/embed/lSi2H_ULp4s?autoplay=1&rel=0'];
    return (
        <div className="edu-feature-area eduvibe-home-one-video edu-section-gap home-one-cat">
            <div className="container eduvibe-animated-shape">
                <div className="row row--35">
                    <div className="col-12">
                        <AppliedAISolutionsSectionTitle
                            classes = "text-center"
                            // slogan = "Why Choose Us"
                            title = "Why Choose Us"
                        />
                    </div>
                    <div className="col-lg-5 col-12 order-2 order-lg-1">
                        <div className="inner mt_md--40 mt_sm--40">
                            <div className="feature-list-wrapper mt--10">
                                { items.map( ( data , i ) => (
                                    <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        className="feature-list mt--35 mt_mobile--15"
                                        animateOnce={ true }
                                        key={ i }
                                    >
                                        <div className="icon">
                                            <i className={ data.icon }></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">{ data.title }</h6>
                                            <p>{ data.info }</p>
                                        </div>
                                    </ScrollAnimation>
                                ) ) }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-12 order-1 order-lg-2 d-flex flex-column justify-content-end">
                        <p>
                            <strong>Comprehensive Service Offering</strong>: From initial consultation to ongoing support, we offer end-to-end AI services, providing businesses with a seamless and comprehensive solution to their needs.
                        </p>
                        <p>
                            <strong>Diverse Industry Expertise</strong>: We specialize in providing AI solutions tailored to various industries, ensuring that businesses receive customized and effective strategies.
                        </p>
                        <p>
                            <strong>Cutting-Edge Innovation</strong>: Our team is committed to delivering the latest advancements in AI technology, empowering businesses to stay ahead of the curve in their respective markets.
                        </p>
                        <p>
                            <strong>Proven Track Record</strong>: With a history of successful implementations, we have demonstrated our ability to deliver tangible results and drive business growth through AI solutions.
                        </p>
                        <p>
                            <strong>Strategic Partnership Approach</strong>: We work closely with our clients to understand their unique challenges and goals, fostering collaborative partnerships that yield impactful AI solutions.
                        </p>
                        <p className="mb-4">
                            <strong>Ethical and Responsible Practices</strong>: We prioritize ethical considerations and responsible AI practices in all our solutions, ensuring that businesses can trust in the integrity and reliability of our services.
                        </p>
                        {/* <div className="feature-thumbnail">
                            <div className="main-image video-popup-wrapper video-popup-two">
                                <img src={`${process.env.PUBLIC_URL}/images/videopopup/pexels-monstera-6238170.webp`} alt="Video PopUp Thumb" loading="lazy" />

                                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={ () => setToggler( ! toggler ) }><span><FaPlay className="play-icon" /></span></button>
                            </div>

                            <div className="circle-image">
                                <span></span>
                                <span></span>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-14.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-01.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-15.png`} alt="Shape Thumb" loading="lazy" />
                    </div>
                </div>
            </div>
            <div className="video-lightbox-wrapper">
                <FsLightbox 
                    toggler={ toggler } 
                    sources={ [ <iframe src={videoLink} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                    maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
            </div>
        </div>
    )
}

export default AppliedAISolutionsMedia;