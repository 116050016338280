import React from 'react';
// import { Link } from 'react-router-dom';
import ProgramsSectionTitle from './ProgramsSectionTitle';

const items = [
  {
    id: 1,
    title: 'Data Science Group Courses',
    info: "Dive into the world of Data Analysis and Visualization with our comprehensive bundle. Gain expertise in analyzing data and communicating insights effectively through Visualizations. Perfect for those looking to kickstart their career in Data Analytics.",
    numberOfCourses: '21',
    modeOfCourses: 'Virtual Self-paced',
    // category: 'Digital Technologies',
    image: 'data-science-data-analysis-and-data-visualization-courses.webp',
    bundleLink: 'https://courses.m2mtechconnect.com/bundles/data-science-intro-to-ml-group-courses'
  },
  {
    id: 2,
    title: 'Data Science and Machine Learning Group Courses',
    info: "Take your skills to the next level with our combined bundle offering Data Science, Data Visualization and Machine Learning. Master advanced techniques in predictive modeling, pattern recognition, and more. Ideal for aspiring Data Scientists seeking a deeper understanding of Machine Learning algorithms.",
    numberOfCourses: '50',
    modeOfCourses: 'Virtual Self-paced',
    // category: 'Marketing',
    image: 'data-science-data-analysis-and-data-visualization-courses.webp',
    bundleLink: 'https://courses.m2mtechconnect.com/bundles/data-science-and-machine-learning-bundle'
  },
  {
    id: 3,
    title: 'Data Science, Machine Learning and AI Applications',
    info: "Unlock the full potential of Data Science, Machine Learning and Artificial Intelligence with our comprehensive bundle. Explore cutting-edge AI applications and learn how to leverage AI technologies to drive innovation and solve complex problems. Designed for ambitious professionals ready to excel in the rapidly evolving field of AI.",
    numberOfCourses: '66',
    modeOfCourses: 'Virtual Self-paced',
    // category: 'Marketing',
    image: 'data-science-data-analysis-and-data-visualization-courses.webp',
    bundleLink: 'https://courses.m2mtechconnect.com/bundles/data-science-machine-learning-group-courses'
  },
];

const ProgramsRecommendedCourses = () => {
  return (
    <>
      <div className="home-one-cat edu-service-area service-wrapper-1 edu-section-gap bg-image" id="Work_Integrated_Learning">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <ProgramsSectionTitle
                classes="text-center"
                title="Recommended Courses"
              />
            </div>
            <div className="col-12 courses-description-content mt--40">
              <p>🎓🚀 Become Career-Ready in Data Science, ML, and AI! 🌟</p>
              <p className="mt-3">By completing our comprehensive courses below, participants will acquire a diverse set of skills and knowledge in Data Analysis, Data Visualization, Machine Learning, and AI. 📊🖥️ This will make them well-equipped to pursue our Industry Partner-led Work Integrated Learning opportunities and embark on successful careers in Data Science, ML, and AI-related fields. 🎓🚀</p>
              <p className="mt-3">🔍💼 <strong>Refund Policy</strong>: We are committed to the success of our participants in securing Work Integrated Learning (WIL) opportunities. In the rare event that a participant is unable to find a WIL within 6 months of completing the courses and capstone requirements, we will refund the course fee. 💰</p>
              <p className="mt-3">By offering this refund policy, we aim to provide additional assurance and support to our participants as they embark on their journey towards gaining practical experience and launching their careers in Data Analysis, Machine Learning, and AI. 🌟📈</p>
              {/* <p>By completing our below comprehensive courses, participants will acquire a diverse set of skills and knowledge in Data Analysis, Data Visualization, Machine Learning and AI, making them well-equipped to pursue our Industry Partner led Work Integrated Learning opportunities and embark on successful careers in Data Science, ML and AI related fields.</p>
              <p className="mt-3">We are committed to the success of our participants in securing Work Integrated Learning (WIL) opportunities after completing the courses and capstone requirements. In the event that a participant is unable to find a WIL within 6 months of completing the courses and capstone requirements, we will refund the course fee.</p>
              <p className="mt-3">By offering this refund policy, we aim to provide additional assurance and support to our participants as they embark on their journey towards gaining practical experience and launching their careers in data analysis, machine learning, and AI.</p> */}
            </div>
            { items.map( ( data , i ) => (
              <div key={data.id} className="col-lg-4 col-md-6 col-12">
                <div className="service-card program-service-card service-card-1 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <a href={ data.bundleLink }>
                        <img src={`${process.env.PUBLIC_URL}/images/category/category-01/${ data.image }`} alt="Category Thumb" loading="lazy" />
                      </a>
                    </div>
                    <div className="content program-info">
                      <div className="program-info-content d-flex justify-content-between">
                        <span className="course-total"><i className="icon-Bag"></i> { data.numberOfCourses } courses</span>
                        <span className="course-total"><i className="icon-map-pin-line"></i> { data.modeOfCourses }</span>
                      </div>
                      <h6 className="title mt-3"><a href={ data.bundleLink }>{ data.title }</a></h6>
                      <p className="description">{ data.info }</p>
                      <div className="d-flex justify-content-center flex-wrap mt-3">
                        <a className="edu-btn btn-secondary mt-3" href={data.bundleLink}>Get Bundle<i className="icon-arrow-right-line-right"></i></a>
                        {/* <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) ) }
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgramsRecommendedCourses;