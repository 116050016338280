import React from 'react';
import OurProgramsSectionTitle from './OurProgramsSectionTitle';
import { Element } from 'react-scroll';

const OurProgramsHowItWorks = () => {
	return (
		<>
			<Element name="description" className="edu-section-gap home-one-cat">
				<div className="container eduvibe-animated-shape">
					<div className="justify-content-center align-items-center row--40 row g-5">
						<div className="col-lg-12">
							<OurProgramsSectionTitle 
								classes = "text-center"
								title = "How It Works"
							/>
						</div>
						<div className="col-lg-8">
							<h5 className="mb-0">1. Pick Your Package</h5>
							<p className="mt-0">We have 3 different subscription packages: pick the one that best fits your unique needs and situation!<br></br>Once you've decided, <strong>Click the link to your chosen package!</strong></p>
							<h5 className="mb-0">2. Enjoy The Benefits</h5>
							<p className="mt-0">After purchasing, you'll <strong>receive access to all of the benefits included in your package!*</strong></p>
							<p className="mb-0 mt--60"><strong>*Note:</strong> the benefits of each package are valid for 1 year from the purchase date of the subscription.</p>
						</div>
						<div className="col-lg-4">
							<img src={`${process.env.PUBLIC_URL}/images/packages/subscription-packages.png`} alt="illustration" />
						</div>
					</div>
				</div>
			</Element>
		</>
	)
}

export default OurProgramsHowItWorks;