import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "AI Prototype for Public Sector",
    "image": "public_sector_AI_v2.webp",
    "website": "https://beamgroup.ca/",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Business Services",
    "location": "https://beamgroup.ca/",
    "going": "140+",
    "company": "BeamGroup"
  }
]

const AIPrototypeForPublicSector = ({
  showRegisterForm,
  setShowRegisterForm,
  company
  }) => {
  const eventItem = EventData[0];
  return (
    <>
      <SEO
      title={ eventItem.title }
      description=""
      />
      <Layout>
        {/* <BreadcrumbOne 
          title="AI Prototype for Public Sector"
          rootUrl="/programs/industry-work-integrated-learning"
          parentUrl="Industry Partner Work Integrated Learning"
          currentUrl="AI Prototype for Public Sector"
        /> */}
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{ eventItem.title }</h3>
                  <p>
                    <strong>What is the main goal for this project?</strong><br></br>
                  </p>
                  <p>
                    The goal of the project is to build a prototype of an AI-based tool that can be used by public sector organizations to predict service needs based on client characteristics and results.
                  </p>
                  <p>
                    <strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
                    <ul>
                      <li>
                        Development of the data model
                      </li>
                      <li>
                        Identification of features / requirements
                      </li>
                      <li>
                        Make design mock-ups
                      </li>
                      <li>
                        Build the solution
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>How will you support learners in completing the project?</strong><br></br>
                    Our team will work closely and support the development of the prototype.
                  </p>
                  <p>
                    <strong>What skills or technologies will help learners to complete the project?</strong><br></br>
                    Project participants will need:
                    <ul>
                      <li>
                        To understand how to develop a data model
                      </li>
                      <li>
                        To identify features/requirements
                      </li>
                      <li>
                        To make mock-ups of a design
                      </li>
                      <li>
                        To build a solution using programming languages (Python, Java)
                      </li>
                      <li>
                        Communication skills
                      </li>
                      <li>
                        Agile development
                      </li>
                      <li>
                        A self-motivated attitude
                      </li>
                    </ul>
                  </p>
                  <p>
                    Students can complete this project without any of the prior knowledge listed here. Students will be expected to research and learn more about this above as the project goes along.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT INDUSTRY PARTNER</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
                        <li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        {/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
                        <li>
                          BeamGroup focusses on finding ways to bridge the gap between citizens and businesses by allowing organizations to address any challenges that come with public policy, strategy, operations, and project management. This company aims to use research & analysis, collaboration, and team direction as a three-step process to strategize, analyze, and implement a solution in relation with public policy and public information/data.
                        </li>
                      </ul>
                      {!company && <div className="read-more-btn mt--45">
                        <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                      </div>}

                      {/* <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="/"><i className="icon-linkedin"></i></a>
                          <a className="facebook" href="/"><i className="icon-Fb"></i></a>
                          <a className="twitter" href="/"><i className="icon-Twitter"></i></a>
                          <a className="youtube" href="/"><i className="icon-youtube"></i></a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {!company && <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Layout>
      {showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
    </>
  )
}
export default AIPrototypeForPublicSector;