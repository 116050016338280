import React from "react";

const BAVerticalTabContent = (props) => {
  let detail = props.data;

  return (
    <div
      key={props.index}
      className={`ba-vertical-tab-content${props.activeTabId === props.index ? " d-block" : " d-none"}`}
    >
      <>
        <h4>{detail.title}</h4>
        <li className="d-block">
          <p className="mb-0">{detail.content.description}</p>
          <ul>
            {detail.content.bullets.map((bullet, idx) => 
            <li className="d-block px-5" key={idx}><strong>{bullet.head}</strong>: {bullet.body}</li>
          )}
          </ul>
        </li>
      </>
    </div>
  );
}

export default BAVerticalTabContent;
