import React from 'react';
import Slider from 'react-slick';
import { ProgramSponsorsParams, ProgramSponsorsMobileParams, ProgramSponsorsTabletParams } from '../../utils/script';

let sponsors = [
  {
    id: 1,
    image: "/images/logo/upskill_canada_logo_v3.png",
    alt: "Palette",
  },
  {
    id: 2,
    image: "/images/sponsors/riipen_logo.png",
    alt: "Riipen",
  },
  {
    id: 3,
    image: "/images/sponsors/aimodels_logo.jpg",
    alt: "AiModels",
  },
  {
    id: 4,
    image: "/images/logo/upskill_canada_logo_v3.png",
    alt: "Palette",
  },
  {
    id: 5,
    image: "/images/sponsors/riipen_logo.png",
    alt: "Riipen",
  },
  // {
  //     image: "/images/sponsors/ictc-website-logo-200x90.svg",
  //     alt: "ICTC",
  // },
  // {
  //     id: 3,
  //     image: "/images/sponsors/technation-logo-black.svg",
  //     alt: "Technation",
  // },
  // {
  //     image: "/images/sponsors/InnovateBC_Logomark_teal_rev-1.webp",
  //     alt: "Innovate BC",
  // },
  // {
  //     id: 4,
  //     image: "/images/sponsors/ECO-Logo.svg",
  //     alt: "Eco Canada",
  // },
  // {
  //     id: 5,
  //     image: "/images/sponsors/Cican-logo-en.webp",
  //     alt: "CiCan",
  // },
  // {
  //     id: 6,
  //     image: "/images/sponsors/magnet-logo.png",
  //     alt: "Magnet",
  // },
]

const AboutUsBanner = () => {
  const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left`;
  const item = 5;
  sponsors = item ? sponsors.slice( 0, item ) : sponsors;

  return (
    <>
    <div
        name="about-us"
        className="service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12 col-lg-5">
              <div className="section-title">
                <span className="pre-title">Who we are</span>
                <h3 className="title">Empowering Sustainable Development through Innovation</h3>
              </div>
              <p className="mt--40 about-us-banner-point">At M2M Tech, we are revolutionizing the intersection of advanced technology and sustainability. Our mission is to create a dynamic ecosystem where innovative tech solutions and sustainable practices drive progress across multiple sectors including CleanTech, Advanced Manufacturing, Business Intelligence, Finance, and Healthcare.</p>
            </div>
            <div className="col-12 col-lg-7 d-flex justify-content-center align-items-center">
              <img src={`${process.env.PUBLIC_URL}/images/about/about-09/Default_Empowering_Sustainable_Development_through_Innovation_v2.png`} alt="Shape Thumb" />
            </div>
            {/* <div className="sponsors-desktop row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div>
            
            <div className="sponsors-tablet row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsTabletParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div>
            
            <div className="sponsors-mobile row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsMobileParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div> */}
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsBanner;