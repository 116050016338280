export function scrollToViewFromBottom(selector) {
  setTimeout(() => {
    const y = document.getElementById(selector);
    // const y = document.getElementById(selector).getBoundingClientRect().top;
    // window.scrollTo({
    //   top: y - 80,
    //   behavior: 'smooth'
    // })
    y.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 300)
}