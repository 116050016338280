import React from 'react';
import { Link } from 'react-router-dom';
import PartnershipsSectionTitle from './PartnershipsSectionTitle';
import { Link as ScrollTo } from 'react-scroll';

const PartnershipsCallToAction = () => {
    return (
        <div className="edu-newsletter-area bg-image newsletter-style-3 edu-section-gap bg-color-primary">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-8">
                        <div className="inner">
                            <PartnershipsSectionTitle
                                classes = "text-white text-start"
                                slogan = "Let's Connect"
                                title = "Ready to Drive Innovation Together?"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="newsletter-right-content d-block d-sm-flex align-items-center justify-content-start justify-content-lg-end">
                            <div className="contact-btn">
                                {/* <Link className="edu-btn btn-white" to="#contact">Get Started Now<i className="icon-arrow-right-line-right"></i></Link> */}
                                <ScrollTo
                                    className="edu-btn btn-white"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    duration={200}
                                >
                                    Get Started Now<i className="icon-arrow-right-line-right"></i>
                                </ScrollTo>
                            </div>
                            {/* <div className="contact-info">
                                <a href="tel: +728-238-399-200"><i className="icon-phone-line"></i> +728-238-399-200</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnershipsCallToAction;