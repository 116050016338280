import React from 'react';

const DPBSNavbar = ({
  isStickyDPBS,
  handleCapstoneClick,
  handleWILClick,
  handleBlogsClick,
  activeDPBSTab
}) => {

  return (
    <>
      {isStickyDPBS && <div className={`dpo-nav d-flex flex-row gap-3${isStickyDPBS ? " dpo-nav-sticky" : ""}`} id="DPBSMobileNavbar">
        <div>
          <button className={`dpo-nav-item${activeDPBSTab === "Capstone" ? " active-program-overview" : ""}`} id="CapstoneMobileNavbar" onClick={handleCapstoneClick}>
            Capstone<span className="d-none d-md-inline-block"> &nbsp;Projects</span>
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeDPBSTab === "WILs" ? " active-program-overview" : ""}`} id="WILMobileNavbar" onClick={handleWILClick}>
          <span className="d-block d-md-none">WILs</span><span className="d-none d-md-block">Work-Integrated-Learning (WILs)</span>
          </button>
        </div>
        <div>
          <button className={`dpo-nav-item${activeDPBSTab === "Blogs" ? " active-program-overview" : ""}`} id="BlogsMobileNavbar" onClick={handleBlogsClick}>
          <span className="d-block d-md-none">Blogs</span><span className="d-none d-md-block">Participant Blogs</span>
          </button>
        </div>
      </div>}
    </>
  )
}

export default DPBSNavbar;