import React from 'react';
import OurProgramsSectionTitle from './OurProgramsSectionTitle';
import { Element } from 'react-scroll';

const OurProgramsWhatYouWillGet = () => {
	return (
		<>
			<Element name="description" className="courses-description-content edu-section-gap style-variation3 blog-main-content">
				<div className="container eduvibe-animated-shape">
					<div className="justify-content-center row--40 row g-5">					
						<div className="col-lg-12">
							<OurProgramsSectionTitle 
								classes = "text-center"
								title = "What You Will Get"
							/>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Access to Online Courses</em></h6>
									<p className="feature-description">Comprehensive online courses covering the fundamentals of Data Science, Machine Learning, and Artificial Intelligence.</p>
									<p className="feature-description">Self-paced learning with 24/7 access to course materials.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Industry-Recognized Certification</em></h6>
									<p className="feature-description">Certification upon completion to add to your	resume and LinkedIn profile.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Webinars and Workshops</em></h6>
									<p className="feature-description">Monthly live webinars with industry experts.</p>
									<p className="feature-description">Workshops on essential skills like resume building, interview preparation, and career planning.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Community Access</em></h6>
									<p className="feature-description">Access to a vibrant online community of peers and mentors for networking and support.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Access to M2M's Job Board</em></h6>
									<p className="feature-description">Exclusive access to job listings tailored to Data,	ML, and AI fields.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Advanced Courses and Specializations</em></h6>
									<p className="feature-description">Access to advanced courses and specializations in areas like deep learning, natural language processing, and data analytics.</p>
									<p className="feature-description">Project-based learning with real-world datasets.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Exclusive Work Integrated Learning Projects</em></h6>
									<p className="feature-description">Participation in exclusive employer-led projects.</p>
									<p className="feature-description">Real-world experience with top companies in the Data, ML, and AI fields.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Mentorship Program</em></h6>
									<p className="feature-description">Pairing with industry mentors for personalized guidance and career advice.</p>
									<p className="feature-description">Monthly one-on-one mentorship sessions.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Job Placement Assistance</em></h6>
									<p className="feature-description">Support with job searches, including access to job boards, exclusive listings, and company partnerships.</p>
									<p className="feature-description">Assistance with creating a professional portfolio and showcasing projects.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Guaranteed Internship Placement</em></h6>
									<p className="feature-description">Placement in a relevant internship to gain hands-on experience and build a professional network.</p>
									<p className="feature-description">Support throughout the internship to ensure a valuable learning experience.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Personalized Career Coaching</em></h6>
									<p className="feature-description">Dedicated career coach to assist with career	planning, skill assessment, and goal setting.</p>
									<p className="feature-description">Bi-weekly coaching sessions tailored to individual needs.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Premium Networking Opportunities</em></h6>
									<p className="feature-description">Invitations to exclusive networking events, meetups, and conferences.</p>
									<p className="feature-description">Direct connections with industry leaders and	potential employers.</p>
							</div>
						</div>
						<div className="our-feature">
							<div className="icon">
									<i className="icon-Board"></i>
							</div>
							<div className="feature-content">
									<h6 className="feature-title"><em>Extended Access and Updates</em></h6>
									<p className="feature-description">Lifetime access to course updates and new materials.</p>
									<p className="feature-description">Ongoing support and resources even after	program completion.</p>
							</div>
						</div>
					</div>
				</div>
			</Element>
		</>
	)
}

export default OurProgramsWhatYouWillGet;