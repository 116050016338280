import React from 'react';
import { Element } from 'react-scroll';
import BASectionTitle from './BASectionTitle';

const BAGenAI = () => {
  return (
    <>
      <Element
        name="introduction"
        className="about-style-1 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7 mt-0">
              <div className="inner">
                <BASectionTitle
                  // slogan = "Eligibility"
                  title = "Generative AI at M2M Tech"
                />
                <p className="ba-why-text mt-5 mb-0">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam quo exercitationem et fugit quos nostrum, doloremque impedit necessitatibus illum tempore suscipit temporibus dolorem aperiam! Facere ipsa autem quisquam molestiae quae!<br></br><br></br>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt dolor sunt harum alias et! Provident nam veniam accusantium minus ipsam sapiente? Facere autem labore laudantium impedit ducimus itaque dolores expedita.
                </p>
                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-07.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-image-gallery m-0">
                <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/gen_ai.png`} alt="illustration" loading="lazy" />
                <div className="shape-image shape-image-1">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt--80">
              <BASectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "How M2M Tech is Harnessing Generative AI"
              />
            </div>
            <div className="ba-harnessing-ai-card-1 col-lg-4 mt-4">
              <div className="rounded p-4 position-relative">
                <img className="mb-0" src={`${process.env.PUBLIC_URL}/images/business-accelerator/gen_ai_fintech.png`} alt="generative ai in fintech" />
                <h5 className="mb-0 position-absolute">Gen AI in FinTech</h5>
              </div>
            </div>
            <div className="ba-harnessing-ai-card-2 col-lg-4 mt-4">
              <div className="rounded p-4 position-relative">
              <img className="mb-0" src={`${process.env.PUBLIC_URL}/images/business-accelerator/gen_ai_and_automation.png`} alt="generative ai and automation" />
                <h5 className="mb-0 position-absolute">Gen AI and Automation</h5>
              </div>
            </div>
            <div className="ba-harnessing-ai-card-3 col-lg-4 mt-4">
              <div className="rounded p-4 position-relative">
              <img className="mb-0" src={`${process.env.PUBLIC_URL}/images/business-accelerator/gen_ai_legal.jpg`} alt="generative ai in legal" />
                <h5 className="mb-0 position-absolute">Gen AI in Legal</h5>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAGenAI;