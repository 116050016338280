import React from 'react';
import { Element } from 'react-scroll';
import DiscoverAISectionTitle from './DiscoverAISectionTitle';

const DiscoverAIDescription = () => {
  return (
    <>
      <Element
        name="introduction"
        className="home-one-programs data-talent-eligibility-section style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container about-feature-list">
          <div className="row g-5">
            <div className="col-lg-12">
              <DiscoverAISectionTitle
                classes = "text-center"
                title = "Overview"
              />
            </div>
            <blockquote className="skillsboost-description blockquote-style-1 mt--30">“ Welcome to a realm of endless possibilities! At M2M Tech, we believe in the power of collaboration to drive innovation and shape the future of technology. We extend a warm invitation to forward-thinking employers in the fields of Data Science, Machine Learning, and Artificial Intelligence to embark on a collaborative journey with us. ”
              <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
            </blockquote>
          </div>
          
          <div className="row g-5 mt--40">
            <div className="col-lg-12">
              <DiscoverAISectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "How It Works?"
              />
            </div>
            <div className="row mt--40">
              <div className="col-12 our-feature">
                <div className="icon">
                  <i className="icon-Microscope"></i>
                </div>
                <div className="feature-content">
                  <h6 className="feature-title"><em>Research and Development Projects</em></h6>
                  <p className="feature-description">Unleash the potential of joint research endeavors. Collaborate with us on cutting-edge R&D projects that push the boundaries of technology. By joining forces, we can explore new horizons, solve industry challenges, and contribute to advancements in the ever-evolving landscape of emerging technologies.</p>
                </div>
              </div>
              <div className="col-12 our-feature mt--20">
                <div className="icon">
                  <i className="icon-file-list-3-line"></i>
                </div>
                <div className="feature-content">
                  <h6 className="feature-title"><em>Upskilling and Talent Pool Creation</em></h6>
                  <p className="feature-description">Shape the workforce of tomorrow. Partner with us to design and deliver upskilling programs tailored to meet the specific needs of your industry. Contribute to curriculum development, share industry insights, and actively engage in nurturing the next generation of professionals equipped with the skills your organization values.</p>
                </div>
              </div>
              <div className="col-12 our-feature mt--20">
                <div className="icon">
                  <i className="icon-search-line"></i>
                </div>
                <div className="feature-content">
                  <h6 className="feature-title"><em>Exploring Opportunities</em></h6>
                  <p className="feature-description">Let's co-create success. Explore diverse opportunities for collaboration, from hosting industry-relevant workshops to participating in our events. By aligning your organization with our mission, you gain access to a talent pool of skilled professionals, elevate your brand, and actively shape the future of tech in collaboration with emerging talents.</p>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </Element>
    </>
  )
}

export default DiscoverAIDescription;