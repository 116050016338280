import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import { Element } from 'react-scroll';
import { FaPlay } from 'react-icons/fa';

const JobseekersBanner = () => {
  const [toggler, setToggler] = useState(false);
  const videoLink = ['https://www.youtube.com/embed/lSi2H_ULp4s?autoplay=1&rel=0'];

  return (
    <Element
      name="datatalent-banner"
      className="data-talent-jobseekers-banner banner-style-1 bg-image height-640 d-flex align-items-center"
    >
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-6 banner-left-content">
            <div className="inner">
              <div className="content position-relative">
                <div className="datatalent-jobseekers-breadcrumb d-flex align-items-center">
                  <Link to="/programs">Our Programs</Link>
                  <i className="ri-arrow-drop-right-line"></i>
                  DataTalent
                  <i className="ri-arrow-drop-right-line"></i>
                  Jobseekers</div>
                <h1 className="datatalent-program-title title mt-4">DataTalent Program</h1>
                <p className="description">Build your career in Data Analytics, ML and AI. Join our comprehensive <strong>16 week online program</strong> designed to equip you with advanced skills in <strong>Data Science, Machine Learning</strong> and <strong>AI</strong> aligned with <strong>Industry Oriented Curriculum</strong>.<br></br>
                <br></br>Dive into real-world projects through our <strong>Work Integrated Learning</strong>, where you'll solve real industry problems and gain practical experience that employers value. Network with top relevant Industry experts. Receive a <strong>$500 stipend</strong> upon successful program completion.</p>
                <span className="datatalent-description-span">*eligibility requirements below</span>
                <div className="read-more-btn banner-btns">
                  {/* <button className="edu-btn" onClick={() => setShowEmployerRegisterForm(true)}>Employer Login</button>
                  <button className="edu-btn btn-secondary" onClick={() => setShowUserInterestForm(true)}>Jobseekers Login</button> */}
                  <div className="col-6 col-lg-4">
                    <a className="edu-btn btn-primary text-center" href="https://form.jotform.com/m2mtech/cohort-sign-up">Apply Now</a>
                  </div>
                  <div className="col-6 col-lg-4 d-flex justify-content-end">
                    <Link className="edu-btn btn-bg-alt text-center" to="/contact-us">Contact Us</Link>
                  </div>
                </div>
                <p className="datatalent-partners-header d-none d-md-block mt--40 mb-0"><strong>Funded by</strong>:</p>
                <div className="datatalent-partners d-none d-md-flex align-items-center mt-0 mx-0">
                  <div className="col-lg-3">
                    <a className="p-0" href="https://www.canada.ca/en.html"><img src={`${process.env.PUBLIC_URL}/images/logo/govt_of_canada.svg`} alt="logo" loading="lazy" /></a>
                  </div>
                  <div className="col-lg-5">
                    <a className="p-0" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} alt="logo" loading="lazy" /></a>
                  </div>
                  {/* <div className="col-lg-4">
                    <a className="p-0" href="https://www.riipen.com/"><img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen_logo.png`} alt="logo" loading="lazy" /></a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="order-2 order-xl-3 col-lg-12 col-xl-6 d-flex flex-column align-items-center justify-content-center banner-right-content">
            <div className="feature-thumbnail">
              <div className="main-image video-popup-wrapper video-popup-two">
                <img src={`${process.env.PUBLIC_URL}/images/videopopup/pexels-monstera-6238170_v2.png`} alt="Video PopUp Thumb" loading="lazy" />

                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={ () => setToggler( ! toggler ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
            </div>
            <div className="datatalent-stats-container d-flex align-items-start justify-content-center">
              <div className="col-4 datatalent-stats-courses">
                <h5 className="datatalent-stat-header">14,000+ Course Enrollments</h5>
                {/* <h5 className="datatalent-stat-header d-block d-md-none">80+<br></br>courses</h5> */}
                <p className="datatalent-stat-description d-none d-md-block">Join thousands of learners advancing their careers with M2M Tech</p>
              </div>
              <div className="col-4 datatalent-stats-ratings">
                <h5 className="datatalent-stat-header">1,000+ Program Graduates</h5>
                {/* <h5 className="datatalent-stat-header d-block d-md-none">12<br></br>Weeks</h5> */}
                <p className="datatalent-stat-description d-none d-md-block">Our graduates are excelling and making an impact in the industry</p>
              </div>
              <div className="col-4 datatalent-stats-schedule">
                <h5 className="datatalent-stat-header d-none d-md-block">300+ Courses Available</h5>
                <h5 className="datatalent-stat-header d-block d-md-none">300+<br></br> Courses Available</h5>
                {/* <h5 className="datatalent-stat-header d-block d-md-none">300+<br></br>Graduates</h5> */}
                <p className="datatalent-stat-description d-none d-md-block">Explore a wide range of courses tailored to today's workforce needs</p>
              </div>
            </div>
            <div className="partners-container w-100">
              <p className="datatalent-partners-header d-block d-md-none mt--40 mb-0"><strong>Funded by</strong>:</p>
              <div className="datatalent-partners d-flex flex-wrap d-md-none align-items-center mt-3 mx-0">
                <div className="col-6">
                  <a className="p-0" href="https://www.canada.ca/en.html"><img src={`${process.env.PUBLIC_URL}/images/logo/govt_of_canada.svg`} alt="logo" loading="lazy" /></a>
                </div>
                <div className="col-12">
                  <a className="p-0" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} alt="logo" loading="lazy" /></a>
                </div>
                {/* <div className="col-lg-4">
                  <a className="p-0" href="https://www.riipen.com/"><img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen_logo.png`} alt="logo" loading="lazy" /></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="video-lightbox-wrapper">
          <FsLightbox
            toggler={ toggler } 
            sources={ [ <iframe src={videoLink} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
            maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
          />
        </div>
      </div>
    </Element>
  )
}

export default JobseekersBanner;