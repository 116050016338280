import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import OurProgramsDescription from '../../components/our-programs/OurProgramsDescription';

const SkillsboostBasic = () => {
  return (
    <>
      <SEO
        title="Skillsboost (Basic)"
        description="We provide a vast variety of programs to help jobseekers improve their profiles."
      />
      <Layout>
        <BreadcrumbOne 
          title="Skillsboost (Basic)"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Skillsboost (Basic)"
        />
        <OurProgramsDescription />
      </Layout>
    </>
  )
}

export default SkillsboostBasic;