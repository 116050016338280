import React from 'react';
import { Element } from 'react-scroll';
import BASectionTitle from './BASectionTitle';

const BAWhoShouldApply = () => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="service-wrapper-1 edu-section-gap home-one-cat"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <BASectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Who Should Apply"
              />
            </div>
            <div className="col-lg-7 order-1 about-feature-list">
              <div className="inner">
                <h5 className="text-left mb-0">Our AI Business Accelerator is perfect for</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Startups looking to explore AI use cases or develop their first AI-driven product</p>
                  </div>
                </div>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">SMEs aiming to scale their AI capabilities and integrate machine learning into core operations.</p>
                  </div>
                </div>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Established businesses seeking to innovate with AI in sectors like FinTech, Healthcare, Retail, Energy, and Sustainability.</p>
                  </div>
                </div>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-award-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Non-tech companies interested in exploring how AI can streamline operations, improve efficiency, or create new business models.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-2">
              <div className="about-image-gallery">
                <img className="image-1" src={`${process.env.PUBLIC_URL}/images/business-accelerator/image3.png`} alt="decoration" loading="lazy" />
              </div>
            </div>
            <div className="col-lg-12 order-3">
              <div className="data-talent-riipen mt--60">
                {/* <div className="data-talent-image-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen+m2m_logo.svg`} alt="riipen logo" />
                </div> */}
                <h4 className="text-center">Accelerate your Business with our AI Solutions!</h4>
                <p>
                  Ready to transform your business with AI? Our Business Accelerator is open for applications now. We're looking for innovative startups and businesses who want to push the boundaries of AI and unlock new growth opportunities.
                </p>
                <div className="data-talent-cta mt--40">
                  <a className="edu-btn" href="https://form.jotform.com/m2mtech/business-accelerator-application">Apply Today</a>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAWhoShouldApply;