import React, { useState } from 'react';
import { Element } from 'react-scroll';
import DiscoverAISectionTitle from './DiscoverAISectionTitle';
import AIForm from '../form/ai-form/AIForm';

const DiscoverAILoremIpsum = () => {
  const [outputData, setOutputData] = useState("Output will be shown here");

  return (
    <>
      <Element
        name="aiform" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row">
            <div className="col-lg-12">
              <DiscoverAISectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Discover AI Form"
              />
            </div>
            <div className="row mt--40">
              {/* <div className="col-lg-6 our-feature mt--20">
                <div className="icon">
                  <i className="icon-checkbox-circle-fill-solid"></i>
                </div>
                <div className="feature-content">
                  <h6 className="feature-title"><em>Lorem Ipsum</em></h6>
                  <p className="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam enim natus id, voluptas eligendi architecto aut consequuntur temporibus sit deserunt, aspernatur magni hic. Ipsa aliquam maxime consectetur ad corrupti corporis.</p>
                </div>
              </div>
              <div className="col-lg-6 our-feature mt--20">
                <div className="icon">
                  <i className="icon-checkbox-circle-fill-solid"></i>
                </div>
                <div className="feature-content">
                  <h6 className="feature-title"><em>Lorem Ipsum</em></h6>
                  <p className="feature-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam enim natus id, voluptas eligendi architecto aut consequuntur temporibus sit deserunt, aspernatur magni hic. Ipsa aliquam maxime consectetur ad corrupti corporis.</p>
                </div>
              </div> */}
              <div className="discover-ai-form-container mt--80">
                <AIForm setOutputData={setOutputData} />
                <div className="form-output mt--40">
                  <div className="pt-0 mb-3">
                    <label className="w-100">Output
                      <textarea width="100%"
                        name="output"
                        rows="5"
                        readOnly
                        value={outputData}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* <button className="edu-btn mt--20" onClick={() => setShowEmployerRegisterForm(true)}>Count Me In</button> */}
              {/* <a className="edu-btn mt--20" href="https://form.jotform.com/m2mtech/employer-register">Count Me In</a> */}
            </div>
            <div>              
              <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
              </div>
            </div>
          </div>
        </div>        
      </Element>
    </>
  )
}

export default DiscoverAILoremIpsum;