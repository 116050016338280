import React from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from './DataTalentSectionTitle';
import { Link } from 'react-router-dom';

const DataTalentCurriculum = ({
  setShowEmployerRegisterForm,
  setShowUserInterestForm,
  setShowDownloadCurriculumForm,
}) => {
  return (
    <>
      <Element
        name="Curriculum"
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Curriculum"
              />
            </div>
          </div>
          <div className="row g-5 align-items-center mt--40">
            <div className="col-lg-12 courses-description-content">
              <p className="employer-description">Our upskilling program focusses on gaining hands-on Emerging Tech skills in Artificial Intelligence that will support developing skills related to Big Data and Algorithm Design which is requested by Employers in Digital Technology sectors. Participants will develop proficiency in utilizing popular Data Science libraries and frameworks including NumPy, Pandas, scikit-learn, TensorFlow and Keras.</p>
            </div>
            <div className="col-lg-7 order-2 order-lg-1">
              <div className="inner">
                <h5 className="text-left mb-0 mt-4">Data Analysis and Manipulation</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-book-mark-fill"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Participants will gain proficiency in working with large datasets, cleaning and preprocessing data, handling missing values and transforming data into suitable formats for analysis.</p>
                  </div>
                </div>
                <h5 className="text-left mb-0 mt-4">Data Visualization</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-book-mark-fill"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Participants will learn how to effectively visualize and communicate data insights using various data visualization libraries and tools. They will gain proficiency in creating visually appealing and informative charts, graphs and dashboards to convey data-driven narratives.</p>
                  </div>
                </div>
                <h5 className="text-left mb-0 mt-4">Machine Learning Algorithms</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-book-mark-fill"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Participants will learn various Machine Learning algorithms, including both supervised and unsupervised techniques. They will understand the concepts behind algorithms such as linear regression, logistic regression, decision trees, random forests and clustering algorithms.</p>
                  </div>
                </div>
                <h5 className="text-left mb-0 mt-4">Deep Learning and Neural Networks</h5>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-book-mark-fill"></i>
                  </div>
                  <div className="feature-content">
                    <p className="feature-description">Participants will delve into the field of deep learning, specifically focusing on neural networks. They will learn about different types of neural networks, such as feedforward neural networks, convolutional neural networks (CNNs) and recurrent neural networks (RNNs). Participants will also gain hands-on experience in building and training neural network models.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2">
                <img className="image-1" src={`${process.env.PUBLIC_URL}/images/data-talent/undraw_engineering_team_a7n2.svg`} alt="decoration" loading="lazy" />
            </div>
          </div>
          <div className="read-more-btn mt--40">
            {/* <button className="edu-btn" onClick={() => setShowDownloadCurriculumForm(true)}>Detailed Curriculum</button> */}
            <a className="edu-btn" href="https://form.jotform.com/m2mtech/download-datatalent-curriculum">Detailed Curriculum</a>
          </div>
          <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentCurriculum;