import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import OurProgramsDescription from '../../components/our-programs/OurProgramsDescription';

const AIReadyProfessional = () => {
  return (
    <>
      <SEO
        title="AI Ready (Professional)"
        description="We provide a vast variety of programs to help jobseekers improve their profiles."
      />
      <Layout>
        <BreadcrumbOne 
          title="AI Ready (Professional)"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="AI Ready (Professional)"
        />
        <OurProgramsDescription />
      </Layout>
    </>
  )
}

export default AIReadyProfessional;