import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import OurProgramsDescription from '../../components/our-programs/OurProgramsDescription';
import OurProgramsPackages from '../../components/our-programs/OurProgramsPackages';
import HomeOneSuccessStory from '../../components/home-one/HomeOneSuccessStory';
import OurProgramsWhatYouWillGet from '../../components/our-programs/OurProgramsWhatYouWillGet';
import OurProgramsHowItWorks from '../../components/our-programs/OurProgramsHowItWorks';

const ProgramsOne = () => {
  return (
    <>
      <SEO
        title="Our Programs"
        description="We provide a vast variety of programs to help jobseekers improve their profiles."
      />
      <Layout>
        <BreadcrumbOne 
          title="Our Programs"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Our Programs"
        />
        <OurProgramsDescription />
        <OurProgramsHowItWorks />
        <OurProgramsWhatYouWillGet />
        <OurProgramsPackages />
        <HomeOneSuccessStory />
      </Layout>
    </>
  )
}

export default ProgramsOne;