import React from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import { Element } from 'react-scroll';

const DataTalentEmployers = () => {
  return (
    <>
      <SEO
				title="DataTalent for Employers"
				description="Unlock 80 hours, fully subsidized, Digital Tech internships for Canadian employers, to build DataTalent pipeline using our Upskilled Talents."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <Element
        name="introduction"
        className="data-talent-description-content service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <h1 className="text-center mt--80">DataTalent for Employers<br></br>Coming Soon...</h1>
      </Element>
      <FooterOne />
    </>
  )
}

export default DataTalentEmployers;