import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

const BABanner = () => {

  useEffect(() => {
    const feature1 = document.getElementById("BannerFeature1");
    const feature2 = document.getElementById("BannerFeature2");
    const feature3 = document.getElementById("BannerFeature3");
    const feature4 = document.getElementById("BannerFeature4");
    const feature5 = document.getElementById("BannerFeature5");
    const feature6 = document.getElementById("BannerFeature6");
    // const feature7 = document.getElementById("BannerFeature7");
    setTimeout(() => {
      // Comment the below line
      feature1.style.width = "200.29px";
      feature1.style.backgroundColor = "var(--color-secondary)";
      // Uncomment following line and comment the above line mentioned to check the exact length in desktop mode. Update it and comment back.
      // feature1.style.width = "fit-content";
      feature1.children[0].style.marginTop = 0;
      feature1.children[0].style.opacity = 1;
      feature1.children[1].style.opacity = 1;
      feature1.children[1].style.width = "fit-content";
    }, 500)
    setTimeout(() => {
      feature2.style.width = "274.03px";
      feature2.style.backgroundColor = "var(--color-secondary)";
      // feature2.style.width = "fit-content";
      feature2.children[0].style.marginTop = 0;
      feature2.children[0].style.opacity = 1;
      feature2.children[1].style.opacity = 1;
      feature2.children[1].style.width = "fit-content";
    }, 600)
    setTimeout(() => {
      feature3.style.width = "268.07px";
      feature3.style.backgroundColor = "var(--color-secondary)";
      // feature3.style.width = "fit-content";
      feature3.children[0].style.marginTop = 0;
      feature3.children[0].style.opacity = 1;
      feature3.children[1].style.opacity = 1;
      feature3.children[1].style.width = "fit-content";
    }, 700)
    setTimeout(() => {
      feature4.style.width = "293.3px";
      feature4.style.backgroundColor = "var(--color-secondary)";
      // feature4.style.width = "fit-content";
      feature4.children[0].style.marginTop = 0;
      feature4.children[0].style.opacity = 1;
      feature4.children[1].style.opacity = 1;
      feature4.children[1].style.width = "fit-content";
    }, 800)
    setTimeout(() => {
      feature5.style.width = "243.32px";
      feature5.style.backgroundColor = "var(--color-secondary)";
      // feature5.style.width = "fit-content";
      feature5.children[0].style.marginTop = 0;
      feature5.children[0].style.opacity = 1;
      feature5.children[1].style.opacity = 1;
      feature5.children[1].style.width = "fit-content";
    }, 900)
    setTimeout(() => {
      feature6.style.width = "267.14px";
      feature6.style.backgroundColor = "var(--color-secondary)";
      // feature6.style.width = "fit-content";
      feature6.children[0].style.marginTop = 0;
      feature6.children[0].style.opacity = 1;
      feature6.children[1].style.opacity = 1;
      feature6.children[1].style.width = "fit-content";
    }, 1000)
    // setTimeout(() => {
    //   feature7.style.width = "215.58px";
    //   feature7.style.backgroundColor = "var(--color-secondary)";
    //   // feature7.style.width = "fit-content";
    //   feature7.children[0].style.marginTop = 0;
    //   feature7.children[0].style.opacity = 1;
    //   feature7.children[1].style.opacity = 1;
    //   feature7.children[1].style.width = "fit-content";
    // }, 1100)
  }, [])

  return (
  <>
    <div className="business-accelerator-banner banner-style-1 bg-image d-flex align-items-center">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 row--40 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-6">
            <div className="banner-left-content">
              <div className="inner">
                <div className="content">
                  <h1 className="title">Business Accelerator</h1>
                  <p className="description">At M2M Tech, we empower startups with tools, expertise, and connections to leverage AI/ML. Our Accelerator provides access to cutting-edge tech, expert mentorship, and support to scale your AI solutions.</p>
                  <div className="read-more-btn banner-btns">
                    <Link className="edu-btn btn-primary" to="contact-us">Contact Us</Link>
                  </div>
                  <p className="datatalent-partners-header d-none d-md-block mt--40 mb-0"><strong>Partnered with</strong>:</p>
                  <div className="datatalent-partners d-none d-md-flex align-items-center mt-0 mx-0">
                    <div className="col-lg-3 mt-4">
                      <a className="p-4 bg-white" href="https://www.scaleai.ca/"><img src={`${process.env.PUBLIC_URL}/images/logo/scale_ai_logo.png`} alt="logo" loading="lazy" /></a>
                    </div>
                    {/* <div className="col-lg-5">
                      <a className="p-0" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} alt="logo" loading="lazy" /></a>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <a className="p-0" href="https://www.riipen.com/"><img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen_logo.png`} alt="logo" loading="lazy" /></a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 order-xl-3"></div>
          <div className="order-2 order-xl-3 col-lg-12 col-xl-4 d-flex flex-column align-self-end justify-content-center banner-right-content">
            <div className="banner-feature mt-4" id="BannerFeature1">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">AI Exploration</p>
              </div>
            </div>
            <div className="banner-feature mt-4" id="BannerFeature2">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">AI Tech Development</p>
              </div>
            </div>
            <div className="banner-feature mt-4" id="BannerFeature3">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">WIL & Talent Pipeline</p>
              </div>
            </div>
            <div className="banner-feature mt-4" id="BannerFeature4">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">Mentorship & Coaching</p>
              </div>
            </div>
            <div className="banner-feature mt-4" id="BannerFeature5">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">Business Coaching</p>
              </div>
            </div>
            <div className="banner-feature mt-4" id="BannerFeature6">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <p className="feature-description">Networking & Events</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="scroll-down-btn">
        <ScrollTo
          className="round-btn"
          to="introduction"
          spy={true}
          smooth={true}
          duration={200}
        >
          <i className="icon-arrow-down-s-line"></i>
        </ScrollTo>
      </div>      
    </div>
  </>
  )
}

export default BABanner;