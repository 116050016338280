import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "PostAI",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Generative AI",
    "location": "PostAI_URL",
    "going": "140+"
  }
]

const PostAI = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="PostAI"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="PostAI"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    Optimize your Digital Marketing strategies through our seamless integration of automated content creation and scheduling for Social Media platforms using PostAI, ensuring consistent and engaging posts tailored to your target audience.
                  </p>
                  <h4>Key Benefits</h4>
                  <ul>
                    <li>
                      <strong>Enhanced Efficiency</strong>: Automated content creation and scheduling streamline the marketing workflow, reducing the time and resources required for manual content development and posting. This efficiency allows marketers to focus on strategic planning and analysis, leading to more effective campaigns.
                    </li>
                    <li>
                      <strong>Consistent Brand Presence</strong>: By scheduling posts in advance and ensuring regular updates across social media platforms, businesses can maintain a consistent brand presence online. Consistency in posting frequency and content quality helps reinforce brand identity and fosters trust and engagement with the audience.
                    </li>
                    <li>
                      <strong>Audience Engagement</strong>: Tailored content generated through automation tools can resonate better with target audiences, leading to increased engagement rates. By delivering relevant and engaging content consistently, businesses can attract and retain followers, encourage interactions, and drive traffic to their digital channels.
                    </li>
                    <li>
                      <strong>Improved Performance Tracking</strong>: Integration with analytics tools enables marketers to track the performance of automated content campaigns in real-time. By analyzing metrics such as reach, engagement, and conversion rates, businesses can gain valuable insights into audience preferences and behavior, allowing for data-driven optimization of future marketing efforts.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default PostAI;