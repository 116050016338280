import React, { useState } from 'react';
import { Element } from 'react-scroll';
import { scrollToViewFromBottom } from '../../utils/scrollToViewFromBottom';

const plans = {
  monthly: [
    {
      id: 1,
      name: 'SKillsboost (BASIC)',
      price: '$29.99',
      priceType: 'Monthly',
      buttonText: 'Enroll Now',
      purchaseLink: '#',
      features: [
        'Online Courses',
        'Industry-Recognized Certification',
        'Webinars & Workshops',
        'Community Access',
        'AI Resume Builder'
      ]
    },
    {
      id: 2,
      name: 'AI Ready (PROFESSIONAL)',
      price: '$39.99',
      priceType: 'Monthly',
      buttonText: 'Enroll Now',
      purchaseLink: '#',
      active: true,
      badgeText: 'POPULAR',
      features: [
        'All of Basic and',
        'Advanced Courses & Specializations',
        'Work Integrated Learning Projects',
        'Mentorship Program',
        'Job Placement Assistance'
      ]
    },
    {
      id: 3,
      name: 'CareerLaunch (PREMIUM)',
      price: '$49.99',
      priceType: 'Monthly',
      buttonText: 'Enroll Now',
      purchaseLink: '#',
      features: [
        'All of Professional and',
        'Guaranteed Internship Placement',
        'Personalized Career Coaching',
        'Premium Networking Opportunities',
        'Extended Access & Updates'
      ]
    }
  ],
  yearly: [
    {
      id: 1,
      name: 'Skillsboost (Basic)',
      price: '$700',
      priceType: ' + applicable tax',
      buttonText: 'Enroll Now',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3085976',
      features: [
        'Online Courses',
        'Industry-Recognized Certification',
        'Webinars & Workshops',
        'Community Access',
        'AI Resume Builder'
      ]
    },
    {
      id: 2,
      name: 'AI Ready (Professional)',
      price: '$1400',
      priceType: ' + applicable tax',
      buttonText: 'Enroll Now',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3086000',
      active: true,
      badgeText: 'POPULAR',
      features: [
        'All of Basic features, and',
        'Advanced Courses & Specializations',
        'Work Integrated Learning Projects',
        'Mentorship Program',
        'Job Placement Assistance'
      ]
    },
    {
      id: 3,
      name: 'CareerLaunch (Premium)',
      price: '$3500',
      priceType: ' + applicable tax',
      buttonText: 'Enroll Now',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3086016',
      features: [
        'All of Professional features, and',
        'Guaranteed Internship Placement',
        'Personalized Career Coaching',
        'Premium Networking Opportunities',
        'Extended Access & Updates'
      ]
    }
  ]
};

const AMPackages = ({
  handleSkillsboostClick,
  handleAIReadyClick,
  handleCareerLaunchClick
}) => {
  const { monthly, yearly } = plans;
  const [state, setState] = useState( {
    active: 'yearly',
    pricingPlan: yearly,
  } );
  const buttonFn = [
    {
      fn: handleSkillsboostClick,
      id: "ProgramOverview"
    },
    {
      fn: handleAIReadyClick,
      id: "ProgramOverview"
    },
    {
      fn: handleCareerLaunchClick,
      id: "ProgramOverview"
    }
  ]

  const handlePricing = ( plan ) => {
    if ( plan === 'yearly' ) {
      setState( {
        ...state,
        active: 'yearly',
        pricingPlan: yearly
      } );
    } else {
      setState( {
        ...state,
        active: 'monthly',
        pricingPlan: monthly
      } );
    }
  }

  const handleSeeMore = (idx) => {
    buttonFn[idx].fn();
    scrollToViewFromBottom(buttonFn[idx].id);
  }

  return (
    <>
      <Element className="datatalent-section-gap style-variation3 blog-main-content home-one-cat" id="MembershipPackages">
        <div className="container eduvibe-animated-shape position-relative">
          <div className="row g-5">
            <div className="col-lg-8 mt-0 p-0">
              <div className="section-title text-start">
                  <span className="pre-title">Pricing Plan</span>
                  <h3 className="title">Choose Your Annual Program Package</h3>
              </div>
            </div>
            <div className="col-lg-4">
              {/* <div className="pricing-billing-duration">
                <ul>
                  <li className="nav-item">
                    <button
                      className={state.active === 'yearly' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Yearly"
                      onClick={() => handlePricing('yearly')}
                    >
                      Yearly
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={state.active === 'monthly' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Monthly"
                      onClick={() => handlePricing('monthly')}
                    >
                      Monthly
                    </button>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="row g-5">
            { state.active === 'monthly' && 
              state.pricingPlan.map( (data, index) => (
                <div className="col-lg-4 col-md-6 col-12" key={ index }>
                  <div className={ `pricing-table ${data.active === true ? ' active' : ''}` }>
                    <div className="pricing-header">
                      {
                        data.active === true && <div className="edu-badge"><span>{ data.badgeText }</span></div>
                      }
                      <h3 className="title">{ data.name }</h3>
                      <div className="price-wrap">
                        <div className="yearly-pricing">
                          <span className="amount">{ data.price }</span>
                          <span className="duration">/{ data.priceType }</span>
                        </div>
                      </div>
                    </div>
                    { data.features && data.features.length > 0 && 
                      <div className="pricing-body">
                        <ul className="list-item">
                          {
                            data.features.map( ( feature, i ) => <li key={i}><i className="icon-checkbox-circle-line"></i>{ feature }</li> )
                          }
                        </ul>
                      </div>
                    }
                    <div className="pricing-btn">
                      <a className={ `edu-btn ${data.active !== true ? ' btn-dark' : ''}` } href={ data.purchaseLink }>
                        { data.buttonText }<i className="icon-arrow-right-line-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) )
            }
            { state.active === 'yearly' && 
              state.pricingPlan.map( (data, index) => (
                <div className="col-lg-4 col-md-6 col-12 p-0 p-md-4" key={ index }>
                  <div className={ `pricing-table ${data.active === true ? ' active' : ''}` }>
                    <div className="pricing-header">
                      {
                        data.active === true && <div className="edu-badge"><span>{ data.badgeText }</span></div>
                      }
                      <h3 className="title">{ data.name }</h3>
                      <div className="price-wrap">
                        <div className="yearly-pricing">
                          <span className="amount">{ data.price }</span>
                          <span className="duration">&nbsp;{ data.priceType }</span>
                        </div>
                      </div>
                    </div>
                    { data.features && data.features.length > 0 && 
                      <div className="pricing-body">
                        <ul className="list-item">
                          {
                            data.features.map( ( feature, i ) => {
                              if(feature.includes("All of")) {
                                return (
                                  <li style={{backgroundColor: "#E4D186", borderRadius: "4px", color: "rgb(0, 0, 0)", padding: "0 1rem"}} key={i}><strong>{ feature }</strong></li>
                                )
                              } else {
                                return (
                                  <li key={i}><img width="21px" src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} alt="check" />&nbsp;&nbsp;{ feature }</li>
                                )}
                              }
                            )
                          }
                        </ul>
                      </div>
                    }
                    <div className="pricing-btn d-flex flex-column align-items-center">
                      <a className={ `edu-btn ${data.active !== true ? ' btn-bg-alt' : ''}` } href={ data.purchaseLink }>
                        { data.buttonText }<i className="icon-arrow-right-line-right"></i>
                      </a>
                      <p className="mt-4 mb-0 cursor-pointer" onClick={() => handleSeeMore(index)}><span className="am-packages-see-more">See more details</span></p>
                    </div>
                  </div>
                </div>
              ) )
            }
          </div>
          <img className="am-sticker d-none d-md-block" src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/desktop_sticker.svg`} alt="illustration" />
          <img className="am-sticker d-block d-md-none" src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/mobile_sticker.svg`} alt="illustration" />
        </div>
      </Element>
    </>
  )
}

export default AMPackages;