import React from 'react';
import SEO from '../../../../common/SEO';
import Layout from '../../../../common/Layout';
import BreadcrumbOne from '../../../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "TestGenius",
    "image": "PostAI-banner.png",
    "website": "https://m2mtechconnect.com",
    "industry": "Education, It & computing",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Natural Language Processing",
    "location": "TestGenius_URL",
    "going": "140+"
  }
]

const TestGenius = () => {
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = EventData.filter(event => event.id === eventId);
  const eventItem = EventData[0];
  return (
    <>
      <SEO
        title={ eventItem.title }
        description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="TestGenius"
          rootUrl="/solutions/applied-ai-solutions"
          parentUrl="Applied AI Solutions"
          currentUrl="TestGenius"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h4>Overview</h4>
                  <p>
                    TestGenius is an innovative solution that leverages advanced natural language processing (NLP) techniques to automate the generation of test scripts directly from natural language requirements. By interpreting and analyzing textual descriptions of software requirements, TestGenius transforms them into executable test scenarios, streamlining the testing process and enhancing efficiency.
                  </p>
                  <h4>Key Features</h4>
                  <ul>
                    <li>
                      <strong>Natural Language Interpretation</strong>: TestGenius employs state-of-the-art NLP algorithms to understand and extract actionable insights from textual software requirements.
                    </li>
                    <li>
                      <strong>Automated Test Script Generation</strong>: Using the interpreted natural language requirements, TestGenius generates comprehensive test scripts automatically, eliminating the need for manual script writing.
                    </li>
                    <li>
                      <strong>Customizable Test Scenarios</strong>: Users can customize and refine generated test scripts to accommodate specific testing scenarios and requirements.
                    </li>
                    <li>
                      <strong>Integration Capabilities</strong>: TestGenius seamlessly integrates with existing software development and testing workflows, supporting popular testing frameworks and tools.
                    </li>
                    <li>
                      <strong>Enhanced Testing Efficiency</strong>: By automating the test script generation process, TestGenius accelerates testing cycles, reduces manual effort, and improves overall testing efficiency.
                    </li>
                    <li>
                      <strong>Scalability and Adaptability</strong>: With its scalable architecture and adaptable NLP models, TestGenius can handle projects of varying sizes and complexities across different domains.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT THE SOLUTION</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li> */}
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        <li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
                      </ul>

                      <div className="read-more-btn mt--45">
                        <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default TestGenius;